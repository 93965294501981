import { createSlice } from "@reduxjs/toolkit";

const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    loading: false,
    error: null,
    data: null,
    updating: false,
    mapping:false,
    mappedContacts: null,
    mappingError: null,
    deleting: false,
    contactsLoading: false,
    contactList: null,
    contactListAll:null,
    contactListerror:null
  },
  reducers: {
    contact: (state) => {
      state.loading = true;
      state.error = null;
    },
    contactSuccess: (state, action) => {
      state.loading = false;
      state.contact = action.payload;
    },
    contactError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getContactSuccess:(state, action)=>{
      state.loading = false;
      state.contactList = action.payload;
    },
    getContactError:(state, action)=>{
      state.loading = false;
      state.error = action.payload;
    },
    update: (state) => {
      state.updating = true;
    },
    updateContactSuccess:(state, action)=>{
      state.updating = false;
      state.updatedContact = action.payload;
    },
    updateContactError:(state, action)=>{
      state.updating = false;
      state.error = action.payload;
    },
    deletingContact:(state)=>{
      state.deleting = true;
      state.deletedContact = null;
    },
    deleteContactSuccess:(state, action)=>{
      state.deleting = false;
      state.deletedContact = action.payload;
    },
    deleteContactError:(state, action)=>{
      state.deleting = false;
      state.error = action.payload;
    },
    contactMap:(state)=>{
      state.mapping = false;
    },
    contactMapping:(state)=>{
      state.mapping = true;
    },
    contactMappingSuccess:(state)=>{
      state.mapping = false;
    },
    contactList: (state) => {
      state.contactsLoading = true;
      state.contactListerror = null;
    },
    contactListSuccess: (state, action) => {
      state.contactsLoading = false;
      state.contactListAll = action.payload;
    },
    contactListError: (state, action) => {
      state.contactsLoading = false;
      state.contactListerror = action.payload;
    },
    inProgress: (state, action)=>{
      state.progress = action.payload;
    },
    progressComplete: (state)=>{
      state.progress = null;
    },
  },
});

export const {
  contact,
  update,
  contactSuccess,
  contactError,
  getContactSuccess,
  getContactError,
  updateContactSuccess,
  updateContactError,
  deleteContactSuccess,
  deleteContactError,
  contactMap,
  contactMapping,
  contactMappingSuccess,
  deletingContact,
  contactList,
  contactListSuccess,
  contactListError,
  inProgress,
  progressComplete
} = contactsSlice.actions;
export default contactsSlice.reducer;
