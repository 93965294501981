import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  startCreatingCampaign,
  createCampaignSuccess,
  createCampaignFailure,
} from "./reducer";
import AxiosInstance from "../../config/axiosInterceptors";

export const createCampaignAsync = createAsyncThunk(
  "campaign/createCampaignAsync",
  async (campaignData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startCreatingCampaign());
      const response = await AxiosInstance.post("v1/campaigns", campaignData);
      dispatch(createCampaignSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(createCampaignFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);
