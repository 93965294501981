import { useState } from 'react';

import i18n from '../i18n';

const useLanguage = () => {
  const lngs = [
    { value: 'en', label: 'English' },
    { value: 'si', label: 'Sinhala' },
    { value: 'ta', label: 'Tamil' },
  ];

  const [language, setLanguage] = useState(
    lngs.find((lng) => lng.value === i18n.language) || lngs[0]
  );

  const handleLanguageChange = (item) => {
    setLanguage(item);
    i18n.changeLanguage(item.value);
  };

  return { language, lngs, handleLanguageChange };
};

export default useLanguage;