import React, { useCallback, useEffect, useState, useContext } from 'react'


import { IconPlus, IconX } from '@tabler/icons-react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import ButtonWithLoader from '@components/ButtonWithLoader';
import UCButton from '@components/theme/UCButton';
import UCFormSelect from '@components/theme/UCFormSelect';

import useApi from '@hooks/useApi';

import { POLLING_STRATEGIES } from '@config/constants';

import { getuserList } from '@modules/users/actions'

import { ThemeContext } from '@context/ThemeContext';

const QueueForm = ({ fromIvr = false, onQueueCreationSuccess, number }) => {

    const { register, formState: { errors }, setValue, watch, control } = useForm();
    const [formattedUserList, setFormattedUserList] = useState([]);
    const { isDarkMode } = useContext(ThemeContext);
    const userList = useSelector((state) => state.users.userList);
    const { loading, postRequest } = useApi();
    const [emailList, setEmailList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getuserList(1))
    }, [])

    useEffect(() => {
        const list = userList?.rows?.reduce((acc, cur) => {
            if (cur.role !== 'owner') {
                acc.push({ value: cur.email, label: cur.email, extra: cur.role });
            }
            return acc;
        }, []);
        setFormattedUserList(list);
    }, [userList]);

    const addEmail = () => {
        const email = watch('email');
        if (email && !errors.email) {
            const emailValue = email?.value ?? email;
            setEmailList(prev => [...prev, emailValue]);
            setFormattedUserList(prevList => prevList.filter(item => item.value !== emailValue));
            setValue('email', '');
        }
    };

    const removeEmail = (index) => {
        const removedEmail = emailList[index];
        setEmailList(emailList.filter((_, i) => i !== index));
        setFormattedUserList(prevList => [...prevList, { value: removedEmail, label: removedEmail }]);
    };

    const createNewEmail = (email) => {
        setEmailList(prev => [...prev, email]);
    }

    const renderUserSelectField = useCallback(() => {
        return <UCFormSelect
            onCreatableFn={createNewEmail}
            options={formattedUserList}
            control={control}
            placeholder="Enter Email"
            isSearchable={true}
            name={`email`}
            {...register(`email`, {
                required: 'Email is required',
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                },
            })}
        />
    }, [formattedUserList]);

    const onSubmit = async () => {
        const name = watch('name');
        const pollingStrategy = watch('pollingStrategy')?.value;
        let agents = [];
        let inviteList = [];


        // process email list
        emailList.forEach(email => {
            const foundUser = userList?.rows?.find(user => user.email === email);
            if (foundUser) {
                agents.push({ Number: String(foundUser.extension), SkillGroup: '1' });
            } else {
                inviteList.push({ email: email, role: "agent" });
            }
        });

        // handle invite list
        if (inviteList.length > 0) {
            try {
                const responseData = await postRequest({
                    url: 'v1/users/invite',
                    payload: { invitations: inviteList, did: false },
                    notifyErrorMessage: 'Failed to invite users'
                });

                console.log("Invite Response Data:", responseData);

                const successfulInvites = responseData?.filter(item => item.status === 'success');
                successfulInvites.forEach(invitedUser => {
                    if (invitedUser.extension) {
                        agents.push({ Number: invitedUser.extension, SkillGroup: '1' });
                    }
                });

            } catch (error) {
                console.error('Error inviting users:', error);
            }
        }

        // final payload
        const payload = { name, pollingStrategy, agents, did: false };

        const queueResData = await postRequest({
            url: 'v1/3cx/queue-create',
            payload: payload,
            notifySuccessMessage: 'Team created successfully!',
            notifyErrorMessage: 'Failed to create team',
            successCallback: () => {
                setEmailList([]);
                setValue('name', '');
                setValue('pollingStrategy', null);
                dispatch(getuserList(1));
            },
        });
        if(queueResData && queueResData.data && queueResData.data.Number ){
            onQueueCreationSuccess(number, queueResData.data.Number);
        }
    };

    return (
        <div>
            {fromIvr ? <h3 className='mb-2 mt-4'>Or Create New Team</h3> : ""}
            <div className="mb-3">
                <label className="form-label">
                    Team Name
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.ivrName && 'is-invalid'}`}
                    {...register('name', { required: true })}
                    placeholder="Enter team name"
                    autoComplete="off"
                />
            </div>
            <h4 className='mb-2'>Invite team members</h4>
            <div className='d-flex align-items-center mb-2'>
                <div className='flex-grow-1'>
                    {renderUserSelectField()}
                    {errors.email && (
                        <div className="invalid-feedback">{errors.email.message}</div>
                    )}
                </div>
                <div className='d-flex flex-wrap ms-3'>
                    <div className={`avatar ${isDarkMode ? 'text-light' : 'text-dark'} bg-muted-lt`}>
                        <UCButton outline icon className="w-100" onClick={addEmail}>
                            <IconPlus size={18} />
                        </UCButton>
                    </div>
                </div>
            </div>
            {emailList.map((email, index) => (
                <div className='d-flex align-items-center mb-2 mt-2' key={index}>
                    <div className="flex-grow-1">
                        <input
                            type="text"
                            className={'form-control'}
                            disabled
                            value={email} />
                    </div>
                    <div className="d-flex flex-wrap ms-3">
                        <UCButton outline icon variant='danger' className="w-100" onClick={() => removeEmail(index)}>
                            <IconX size={18} />
                        </UCButton>
                    </div>
                </div>
            ))}

            <div>
                <h4 className='mb-2 mt-4'>Polling Strategy</h4>
                <div className='row gy-2'>
                    <div className='col-md-9 col-12'>
                        <UCFormSelect
                            name={`pollingStrategy`}
                            placeholder={'Select'}
                            className={errors.pollingStrategy ? 'is-invalid' : ''}
                            options={POLLING_STRATEGIES}
                            control={control}
                            rules={{ required: 'Strategy is required' }}
                        />
                        {errors.pollingStrategy && (
                            <div className="invalid-feedback">{errors.pollingStrategy.message}</div>
                        )}
                    </div>
                    <div className="col-md-3 col-12">
                        <ButtonWithLoader
                            className="w-100"
                            loading={loading}
                            onClick={onSubmit}
                        >
                            {fromIvr ? "Add" : "Submit"}
                        </ButtonWithLoader>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default QueueForm;