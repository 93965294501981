import axios from 'axios';

import { userLogout } from '@modules/auth/actions';
import { loginSuccess } from '@modules/auth/reducer';

import { API_URL } from './config';

import { store } from '../store';

const AxiosInstance = axios.create({
    baseURL: API_URL
});

let isRefreshing = false;
let refreshSubscribers = [];

const processQueue = (error, token = null) => {
  refreshSubscribers.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  refreshSubscribers = [];
};

const refreshTokenAndRetry = async (originalRequest) => {
  try {
    const refreshToken = store.getState().auth.refreshToken;
    const response = await axios.post(`${API_URL}/v1/users/refresh-token`, { refreshToken });
    const { token, refreshToken: newRefreshToken } = response.data;
    
    store.dispatch(loginSuccess({ token, refreshToken: newRefreshToken }));
    
    originalRequest.headers['Authorization'] = `Bearer ${token}`;
    processQueue(null, token);
    return axios(originalRequest);
  } catch (err) {
    processQueue(err, null);
    store.dispatch(userLogout());
    window.location.href = '/auth/login';
    return Promise.reject(err);
  }
};

AxiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const accessToken = store.getState().auth.token;
      const urlsToIgnoreAuth = ['/users/login', '/users/signup', '/users/refresh-token'];

      if (!urlsToIgnoreAuth.some(ignoreUrl => config.url.includes(ignoreUrl))) {
        config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && error.response?.data?.message === 'ERR_INVALID_TOKEN' && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshSubscribers.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axios(originalRequest);
        }).catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return refreshTokenAndRetry(originalRequest).finally(() => {
        isRefreshing = false;
      });
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;