import React, { useEffect } from 'react'

import { IconMail } from '@tabler/icons-react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from '@components/ButtonWithLoader';

const ForgetPasswordForm = ({ handleForgetPassword, loading, formData }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { t } = useTranslation();

    useEffect(() => {
        setValue('email', formData.email);
    }, [formData])

    return (
        <form
            onSubmit={handleSubmit(handleForgetPassword)}
            method="post"
            autoComplete="off"
            noValidate>
            <h2 className="card-title text-center mb-4">{t('form_headers.reset')}</h2>
            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.email.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    {...register('email', { required: true })}
                    placeholder={t("form_fields.email.placeholder")}
                    autoComplete="off"
                />
                {errors.email && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    <IconMail className="me-2" />{" "}
                    {loading ? t('form_actions.sending_otp') : t('form_actions.send_otp')}
                </ButtonWithLoader>
            </div>
        </form >
    )
}

export default ForgetPasswordForm