// ConfirmationModal.js
import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { closeConfirmation } from './reducer';

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const showConfirmation = useSelector((state) => state.modals.showConfirmation);
  const confirmationData = useSelector((state) => state.modals.confirmationData);

  const handleClose = () => {
    dispatch(closeConfirmation());
  };

  const handleConfirm = () => {
    confirmationData.onConfirm(); //confirm action
    handleClose();
  };

  if (!showConfirmation) {
    return null;
  }

  return (
    <Modal show={showConfirmation} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{confirmationData.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmationData.content}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          {confirmationData.confirmText || 'Confirm'}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {confirmationData.closeText || 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
