import React, { useState } from 'react';
import { IconUser } from '@tabler/icons-react'

import moment from 'moment';
import 'moment-duration-format';

import { IconDownload } from '@tabler/icons-react';
import { CSVLink } from 'react-csv';

const AgentPerformanceReport = ({ data }) => {
    const pageSize = 10; // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / pageSize);
    const paginationLimit = 5;

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const calculateTotalTalkTime = (outbound, inbound) => {
        const outboundDuration = moment.duration(outbound);
        const inboundDuration = moment.duration(inbound);
        const totalDuration = outboundDuration.add(inboundDuration);
        return totalDuration.format('HH:mm:ss', { trim: false });
    };
    const calTotalAnswered = (outbound, inbound) => {
        const inboundCount = inbound ? inbound : 0;
        const outboundCOunt = outbound ? outbound : 0;
        const totalAnswered = inboundCount + outboundCOunt;
        return totalAnswered;
    }
    const calTotalUnanswered = (outbound, inbound) => {
        const inboundCount = inbound ? inbound : 0;
        const outboundCOunt = outbound ? outbound : 0;
        const totalUnanswered = inboundCount + outboundCOunt;
        return totalUnanswered;
    }

    const renderData = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, data.length);

        return data.slice(startIndex, endIndex).map((agentData) => (
            <tr key={agentData.Dn}>
                <td>
                    <div className="d-flex py-1 align-items-center">
                        <span className="avatar avatar-sm me-2 rounded-circle">
                            <IconUser/></span>
                        <div className="flex-fill">
                            <div className="font-weight-medium">{agentData.DisplayName}</div>
                            <div className="text-secondary">
                                {agentData.Dn}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            {agentData.InboundAnsweredCount?agentData.InboundAnsweredCount: 0 }
                        </div>
                        <div className="w-100">
                            {agentData.InboundUnansweredCount? agentData.InboundUnansweredCount: 0 }
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            {agentData.OutboundAnsweredCount ? agentData.OutboundAnsweredCount : 0}
                        </div>
                        <div className="w-100">
                            {agentData.OutboundUnansweredCount ? agentData.OutboundUnansweredCount : 0}
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            {calTotalAnswered(agentData.InboundAnsweredCount, agentData.OutboundAnsweredCount)}
                        </div>
                        <div className="w-100">
                            {calTotalUnanswered(agentData.InboundUnansweredCount, agentData.OutboundAnsweredCount)}
                        </div>
                    </div>
                </td>
                <td className="text-center">
                    {calculateTotalTalkTime(agentData.OutboundAnsweredTalkingDur, agentData.OutboundUnansweredCount)}
                </td>
            </tr>
        ));
    };

    const getPaginationItems = () => {
        const pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(paginationLimit / 2));
        let endPage = Math.min(totalPages, startPage + paginationLimit - 1);

        if (endPage - startPage + 1 < paginationLimit) {
            startPage = Math.max(1, endPage - paginationLimit + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    // CSV data
    const csvData = data.map(agentData => ({
        Extension: agentData.Dn,
        Name: agentData.DisplayName,
        InboundAnswered: agentData.InboundAnsweredCount,
        InboundUnanswered: agentData.InboundUnansweredCount,
        OutboundAnswered: agentData.OutboundAnsweredCount,
        OutboundUnanswered: agentData.OutboundUnansweredCount,

        TotalAnswered: agentData.total_answered,
        TotalTalkTime: agentData.total_talk_time,
        TotalUnanswered: agentData.total_unanswered,
    }));

    return (
        <div className="col-12">
            <div className="card">

                {/* <div className="text-end mb-3">
                        <CSVLink data={csvData} filename={"agent_performance_report.csv"} className="btn btn-link" style={{ textDecoration: 'none' }}>
                            <IconDownload /><span style={{ fontWeight: 'bold' }}>Download</span>
                        </CSVLink>
                    </div> */}

                <div className="table-responsive">
                    <table className="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Agent Name & Extention</th>
                                <th className="text-center">
                                    <div>
                                        Inbound
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div>
                                        Outbound
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div>
                                        Total
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Total Talk Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderData()}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="m-3 d-flex align-items-center">
                <p className="m-0 text-muted">
                    Showing <span>{(currentPage - 1) * pageSize + 1}</span> to{' '}
                    <span>{Math.min(currentPage * pageSize, data.length)}</span> of{' '}
                    <span>{data.length}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Prev
                        </button>
                    </li>
                    {getPaginationItems().map((page) => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageClick(page)}>
                                {page}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AgentPerformanceReport;
