import { createSlice } from "@reduxjs/toolkit"

const firstLookSlice = createSlice({
    name: "firstLook",
    initialState: {
        userConfig: null,
        teamConfig: null,
        ivrConfig: null,
    },
    reducers: {
        setUserConfig: (state, payload) => {
            state.userConfig = payload?.userConfig;
        },
        setTeamConfig: (state, payload) => {
            state.teamConfig = payload?.teamConfig;
        },
        setIvrConfig: (state, payload) => {
            state.ivrConfig = payload?.ivrConfig;
        }
    },
});

export const {
    setUserConfig,
    setTeamConfig,
    setIvrConfig,
} = firstLookSlice.actions;
export default firstLookSlice.reducer;