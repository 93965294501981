import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IconBrandWhatsapp,
  IconMessage,
  IconPhoneCall,
  IconUser,
  IconExclamationCircle,
} from "@tabler/icons-react";

const ContactCardItem = ({
  contact,
  customFieldList,
  handleViewContact,
  handlePhoneCall,
  handleMessage,
  handleWhatsapp,
  handleMultiSelect, 
}) => {
  const handleCheckboxClick = (id) => {
    handleMultiSelect((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  
  

  const filteredContact = Object.fromEntries(
    Object.entries(contact).filter(([field]) => {
      const customField = customFieldList.rows.find((cf) => cf.name === field);
      return customField ? customField.profileView : true;
    }).concat(
      // Include name and phone
      [['name', contact.name]],
      [['phone', contact.phone]]
    )
  );

  return (
    <div className="card card-sm">
      <div className="card-body" onClick={() => handleViewContact(contact)}>
        <div className="row align-items-center">
          <div className="col-auto">
            <Link to="." onClick={() => handleViewContact(contact)}>
              <span className="avatar avatar-lg">
                <IconUser />
              </span>
            </Link>
          </div>
          <div className="col">
            <div className="font-weight-medium">
              <Link to="." onClick={() => handleViewContact(contact)}>
                {filteredContact.phone}
                {filteredContact.dnc && (
                  <span className="text-danger" style={{ marginLeft: "5px" }}>
                    <IconExclamationCircle />
                  </span>
                )}
              </Link>
            </div>
            <div className="text-muted">
              <b>{filteredContact.name}</b>
            </div>
          </div>
          <div className="col-auto">
            {!filteredContact.dnc && (
              <Link
                to="."
                onClick={() => handlePhoneCall(filteredContact.phone)}
                className="icon"
              >
                <IconPhoneCall />
              </Link>
            )}
          </div>
          <div className="col-auto">
            {!filteredContact.dnc && (
              <Link
                to="."
                onClick={() => handleMessage(filteredContact.phone)}
                className="icon"
              >
                <IconMessage />
              </Link>
            )}
          </div>
          <div className="col-auto">
            {!filteredContact.dnc && (
              <Link
                to="."
                onClick={() => handleWhatsapp(filteredContact.phone)}
                className="icon"
              >
                <IconBrandWhatsapp />
              </Link>
            )}
          </div>
        </div>
        <div className="row align-items-center mt-2">
          {customFieldList.rows.map(({ name, label }) => {
            return ["id", "name", "phone", "dnc"].indexOf(name) === -1 &&
              filteredContact[name] ? (
              <div key={name}>
                <div>
                  <strong>{label}:</strong> {filteredContact[name]}
                </div>
              </div>
            ) : (
              ""
            );
          })}
          <div className="col-auto ml-auto">
            <label className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={() => handleCheckboxClick(filteredContact._id)}
              />
              <span className="form-check-label"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCardItem;
