import React, { useCallback, useMemo } from 'react'

import { IconUser } from '@tabler/icons-react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { userLogout } from '@modules/auth/actions'

import LanguageSwitch from './LanguageSwitch'
import ThemeToggler from './ThemeToggler'


// TODO: remove the logout button and finalize position after testing
const AccountUtils = ({ classNames, fromDashboard }) => {

    const profile = useSelector((state) => state.profile.profile);
    const token = useSelector((state) => state.auth.token);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(userLogout());
        navigate('/auth/login', { replace: true });
    }

    const handleEditProfile = () => {
        navigate(`${fromDashboard ? '/edit-profile' : '/auth/edit-profile'}`, { state: { fromDashboard: fromDashboard } });
    }
    const handleChangePassword = () => {
        navigate(`${fromDashboard ? '/change-password': '/auth/change-password'}`, { state: { fromDashboard: fromDashboard } });
    }

    const renderProfileSection = () => {
        return (
            <div className="nav-item">
                <Dropdown>
                    <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        className="nav-link d-flex align-items-center lh-1 text-reset p-0"
                    >
                        <span
                            className="bg-primary-lt avatar avatar-sm"
                            style={{ height: "32px", width: "32px" }}
                        >
                            <span className="badge bg-success"></span>
                            <IconUser />
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-arrow mt-2">
                        {renderProfileSectionContent()}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    };

    const renderProfileSectionContent = () => {
        if (profile && profile.isVerified) {
            return (
                <>
                    <Dropdown.Item className="dropdown-item" onClick={handleEditProfile}>Edit Profile</Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" onClick={handleChangePassword}>Change Password</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: 0 }} />
                    <Dropdown.Item className="dropdown-item" onClick={handleLogout}>
                        Logout
                    </Dropdown.Item>
                </>
            )
        } else {
            return (
                <>
                    <Dropdown.Item className="dropdown-item" onClick={handleLogout}>
                        Logout
                    </Dropdown.Item>
                </>
            )

        }
    };


    return (
        <div className={`lang-theme-toggle ${classNames}`}>
            <LanguageSwitch />
            <ThemeToggler />
            {token ? renderProfileSection() : null}
        </div>
    )
}

export default AccountUtils