import { useEffect, useState } from 'react';

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ButtonWithLoader from '@components/ButtonWithLoader';

const LoginForm = ({ handleLogin, handleRememberMeChange, rememberMe, loading, formData }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            email: formData.email,
        },
    });
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState(formData.email);


    useEffect(() => {
        const subscription = watch((value) => {
            setEmail(value.email);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = (data) => {
        handleLogin(data);
    };

    const handleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <form className="my-auto" method="get" autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label className="form-label">{t('form_fields.email.label')}</label>
                <input
                    type="email"
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    placeholder={t('form_fields.email.placeholder')}
                    autoComplete="off"
                />
                {errors.email && <div className="invalid-feedback">
                    {t('form_validations.invalid_email')}
                </div>}
            </div>
            <div className="mb-2">
                <label className="form-label">
                    {t('form_fields.password.label')}
                    <span className="form-label-description">
                        <Link to="/auth/forgot-password" state={{ email: email }}>
                            {t('form_helpers.forgot_password')}
                        </Link>
                    </span>
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.password && 'is-invalid'}`}
                        {...register('password', {
                            required: { value: true, message: t('form_validations.required') },
                        })}
                        placeholder={t('form_fields.password.placeholder')}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                            {showPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                </div>
            </div>
            <div className="mb-2">
                <label className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        {...register('rememberMe')}
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                    />
                    <span className="form-check-label">{t('form_helpers.remember_me')}</span>
                </label>
            </div>
            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    {t('form_actions.login')}
                </ButtonWithLoader>
            </div>
        </form>
    );
};

export default LoginForm;