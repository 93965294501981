import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    error: null,
    resetting: false,
  },
  reducers: {
    requestStarted: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    reInviteSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    deleteSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    sendOTP: (state) => {
      state.loading = true;
      state.errorSendOTP = null;
    },
    sendOTPSuccess: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
    sendOTPError: (state, action) => {
      state.loading = false;
      state.errorSendOTP = action.payload;
    },
    passReset:(state) =>{
      state.loading = false;
      state.resetting = true;
    },
    newPassword: (state) => {
      state.loading = true;
      state.errorNewPassword  = null;
    },
    newPasswordSuccess: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.resetting = false;
    },
    newPasswordError: (state, action) => {
      state.loading = false;
      state.errorNewPassword = action.payload;
    },
    sendInvite: (state) => {
      state.loading = true;
      state.errorInvite = null;
    },
    sendInviteuccess: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
    getUserListSuccess: (state, action) => {
      state.loading = false;
      state.userList = action.payload;
    },
    getUserListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sendInviteError: (state, action) => {
      state.loading = false;
      state.errorInvite = action.payload;
    },
    updatePassword:(state) =>{
      state.loading = true;
      state.errorChangePass = null;
    },
    updatePasswordSuccess:(state, action)=>{
      state.loading = false;
      state.response = action.payload;
    },
    updatePasswordError:(state, action)=>{
      state.loading = false;
      state.errorChangePass = action.payload;
    }
    
  },
});

export const {
  sendOTP,
  sendOTPSuccess,
  sendOTPError,
  newPassword,
  newPasswordSuccess,
  newPasswordError,
  passReset,
  sendInvite,
  sendInviteuccess,
  sendInviteError,
  getUserListError,
  getUserListSuccess,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordError,
  requestStarted,
  requestFailed,
  reInviteSuccess,
  deleteSuccess
  
} = userSlice.actions;
export default userSlice.reducer;
