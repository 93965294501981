import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
  name: "report",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    requestStarted: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    agentReportSuccess: (state, action) => {
      state.agentreportdata = action.payload;
      state.loading = false;
    },
    distributionReportSuccess: (state, action) => {
      state.distributionreportdata = action.payload;
      state.loading = false;
    },
    reportDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    userListSuccess: (state, action) => {
      state.users = action.payload;
      state.loading = false;
    }
  },
});

export const {
  requestStarted,
  requestFailed,
  reportDataSuccess,
  agentReportSuccess,
  distributionReportSuccess,
  userListSuccess

} = reportSlice.actions;
export default reportSlice.reducer;
