import React, { useState } from 'react';

import { IconMail } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '@assets/logo.png';

function ResendVerificationEmail() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
  });

  const [emailError, setEmailError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setEmailError('');
  };

  const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isEmailValid(formData.email)) {
      setEmailError('Please enter a valid email address');
      setFormData((prevData) => ({
        ...prevData,
        email: '',
      }));
      return;
    }
  
    try {
      navigate('/auth/verify-email', { state: { email: formData.email } });
    } catch (error) {
      alert(error.message);
    }
  };
  
  return (
    <div className="page page-center">
      <div className="container container-tight py-4 px-3">
        <div className="text-center mb-4">
          <Link to="." className="navbar-brand navbar-brand-autodark">
            <img src={logo} height="50" alt="" />
          </Link>
        </div>
        <form
          className="card card-md"
          onSubmit={handleSubmit}
          method="get"
          autoComplete="off"
          noValidate
        >
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Resend verification Code</h2>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input
                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>
            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                <IconMail className="me-2" />
                {' '}
                Resend
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResendVerificationEmail;
