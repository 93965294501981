import React from 'react';

import { Link } from 'react-router-dom';

const UCPagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="pagination-container">
      <ul className="pagination justify-content-end">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to="#"
            onClick={() => onPageChange(currentPage - 1)}
            tabIndex="-1"
            aria-disabled={currentPage === 1}
          >
            Prev
          </Link>
        </li>
        {pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </Link>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <Link
            className="page-link"
            to="#"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default UCPagination;
