import React from "react";

const IvrPlaceholder = () => {
    return (

        <div className="card-body">
            <div className="placeholder-glow mb-3">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>
            <div className="placeholder-glow mb-3">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>
            <div className="placeholder col-4 mb-6"></div>

            <div className="placeholder-glow mb-3">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>

            <div className="placeholder-glow mb-3">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>

            <div className="placeholder-glow mb-3">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>

            <div className="placeholder-glow">
                <div className="placeholder col-6 mb-2"></div>
                <div className="placeholder col-12"></div>
            </div>
        </div>

    );
};

export default IvrPlaceholder;
