import React from 'react';

const UCStepper = ({ steps, activeStep, className }) => (
  <ul className={`steps steps-counter ${className ? className : 'my-4'}`}>
    {steps.map((step, index) => (
      <li key={index} className={`step-item ${activeStep === index ? 'active' : ''}`}>
        {step.name}
      </li>
    ))}
  </ul>
);

export default UCStepper;
