import React from "react";

import { Col, Row } from "react-bootstrap";

function PageContent({ content, subContent, showSubContent }) {
  return (
    <Row>
      <Col sm="12" lg={showSubContent ? "8" : "12"} className="page-container-body mb-3">
        {content}
      </Col>
      {showSubContent && (
        <Col sm="12" lg="4" className="mb-3">
          {subContent}
        </Col>
      )}
    </Row>
  );
}

export default PageContent;
