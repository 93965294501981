import React from "react";

import {
    IconBroadcast,
    IconDashboard,
    IconInbox,
    IconReportAnalytics,
    IconSettings,
    IconTemplate,
    IconUser,
    IconLogout
} from "@tabler/icons-react";

import NavItem from "@components/NavItem";

import { ADMIN_ACCESS, OWNER_ACCESS } from "@config/constants";

import AuthorizedSections from "./AuthorizedSections";

const NavigationDrawer = ({ toggleMenu, handleLogout }) => {
    return (
        <aside className="navbar navbar-vertical">
            <div className="container-fluid flex-column align-items-start h-100 py-3">
                <ul className="navbar-nav w-100">
                    <NavItem
                        onClick={() => toggleMenu(false)}
                        to="/"
                        icon={<IconDashboard />}
                        title="Dashboard"
                    />
                    {/* <NavItem
                        icon={<IconTemplate />}
                        onClick={() => toggleMenu(false)}
                        to="/crm"
                        title="Contacts"
                    /> */}
                    {/* <NavItem
                        onClick={() => toggleMenu(false)}
                        to="/campaigns"
                        icon={<IconBroadcast />}
                        title="Campaigns"
                    /> */}
                    {/* <NavItem
                        onClick={() => toggleMenu(false)}
                        to="/inbox"
                        icon={<IconInbox />}
                        title="Inbox"
                    /> */}
                    <AuthorizedSections grantedMinimumLevels={ADMIN_ACCESS}>
                        <NavItem
                            icon={<IconReportAnalytics />}
                            onClick={() => toggleMenu(false)}
                            title="Reports"
                        >
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/reports/call-details"
                                title="Call Details"
                            />
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/reports/agent-performance"
                                title="Agent Performance"
                            />
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/reports/call-distribution"
                                title="Call Distribution"
                            />
                        </NavItem>
                    </AuthorizedSections>
                    {/* <NavItem
                        onClick={() => toggleMenu(false)}
                        to="/reports"
                        icon={<IconReportAnalytics />}
                        title="Reports"
                    /> */}
                    <AuthorizedSections grantedMinimumLevels={ADMIN_ACCESS}>
                        <NavItem
                            onClick={() => toggleMenu(false)}
                            to="/users"
                            icon={<IconUser />}
                            title="Users"
                        />
                    </AuthorizedSections>
                </ul>
                <div className="mt-auto w-100">
                    <ul className="navbar-nav">
                        {/* <NavItem icon={<IconTemplate />} title="Templates">
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/templates/voice"
                                title="Call Flows"
                            />
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/templates/sms"
                                title="SMS Templates"
                            />
                            <NavItem
                                onClick={() => toggleMenu(false)}
                                to="/templates/whatsapp"
                                title="WhatsApp Templates"
                            />
                        </NavItem> */}
                        <AuthorizedSections grantedMinimumLevels={OWNER_ACCESS}>
                            <NavItem
                                icon={<IconSettings />}
                                onClick={() => toggleMenu(false)}
                                title="Configurations">
                                <NavItem
                                    onClick={() => toggleMenu(false)}
                                    to="/routing"
                                    title="Routings"
                                />

                                <NavItem
                                    onClick={() => toggleMenu(false)}
                                    to="/organization/update"
                                    title="Organization"
                                />
                                <NavItem
                                    onClick={() => toggleMenu(false)}
                                    to="/queues"
                                    title="Queues"
                                />
                            </NavItem>
                        </AuthorizedSections>
                        {/* <NavItem
                            icon={<IconLogout />}
                            title="Logout"
                            onClick={handleLogout}
                        /> */}
                    </ul>
                </div>
            </div>
        </aside>
    );
};

export default NavigationDrawer;
