import React, { useEffect, useState } from 'react';
import { IconPhone } from '@tabler/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { endLoader, startLoader } from '@components/reducers/loading';

import { getQueueList } from '../action';
import QueueDetailView from './QueueDetailView';
export default function QueueList({  onEditClick, onDeleteClick, onViewDetailsClick }) {
    const dispatch = useDispatch();
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(1);
    const queuesData = useSelector((state) => state.queues.queueList);
    const [viewMode, setViewMode] = useState(false); // View mode state
    const [selectedQueueId, setSelectedQueueId] = useState(null);

    console.log("tets");
    // Fetch queue list when currentPage changes
    const [loading, setLoading] = useState(true);


    const fetchQueues = () => {
        dispatch(getQueueList());
    };

    useEffect(() => {
        fetchQueues();
    }, []);

    console.log("queuesData", queuesData);

    // const handleViewClick = (queueId) => {
    //     setSelectedQueueId(queueId);
    //     setViewMode(true);  // Activate view mode
    // };

    // const handleRowClick = (queue) => {
    //     handleViewClick(queue.Id); // Trigger view on row click
    // };

    // const handleCloseView = () => {
    //     setSelectedQueueId(null);
    //     setViewMode(false);
    // };

    // if (viewMode && selectedQueueId) {
    //     return <QueueDetailView queueId={selectedQueueId} onClose={handleCloseView} />;
    // }


    const queues = queuesData || [];

    // const handlePageClick = (page) => {
    //     setCurrentPage(page);
    // };

    if (!queuesData) return null;

    return (
        <div className="col-12">
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Polling Strategy</th>
                                {/* <th>Is Registered</th> */}
                                <th>User Count</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {queues.map((queue) => (
                                <tr key={queue.Id} >
                                    <td  onClick={() => onViewDetailsClick(queue)}>
                                        <div className="d-flex py-1 align-items-center">
                                            <span className="avatar me-2"><IconPhone size={20} /></span>
                                            <div className="flex-fill">
                                                <div className="font-weight-medium">
                                                    {queue.Name.split('_').slice(1).join('_')}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{queue.Number}</td>
                                    <td>{queue.PollingStrategy}</td>
                                    {/* <td>{queue.IsRegistered ? 'Yes' : 'No'}</td> */}
                                    <td>
                                        <span >
                                            {queue.Agents.length} 
                                        </span>
                                    </td>

                                    <td>
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle align-text-top" data-bs-boundary="viewport"
                                                data-bs-toggle="dropdown">Actions</button>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <button className="dropdown-item" onClick={() => onEditClick(queue)}>
                                                    Edit
                                                </button>
                                                <button className="dropdown-item" onClick={() =>  onViewDetailsClick(queue)}>
                                                    View
                                                </button>
                                                <button className="dropdown-item text-danger" onClick={() => onDeleteClick(queue)}>
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Pagination */}
            {/* <div className="m-3 d-flex align-items-center">
                <p className="m-0 text-muted">
                    Showing <span>{(currentPage - 1) * queuesData.pageSize + 1}</span> to{' '}
                    <span>{Math.min(currentPage * queuesData.pageSize, queuesData.total)}</span> of{' '}
                    <span>{queuesData.total}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Prev
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageClick(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </div> */}
            {/* End of pagination */}
        </div>
    );
}
