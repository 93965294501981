import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { showToast } from "@components/reducers/toast";
import UCFormCommonError from "@components/theme/UCFormCommonError";

import usePreventBack from "@hooks/usePreventBack";

import { encrypt } from "@config/encryption";

import { resendVerificationCode } from "@modules/users/actions";
import { passReset } from "@modules/users/reducer";

import { verifyOtp } from "../actions";
import VerifyEmailForm from "../forms/VerifyEmailForm";
import { requestFailed } from "../reducer";

function VerifyEmail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { error, loading, email: email1 } = useSelector((state) => state.auth);
  const email2 = useSelector((state) => state.profile.email);
  const { email: email3 } = location.state || 0;
  const email = email1 || email2 || email3;
  const { verifyMode } = location.state || 0;
  usePreventBack();
  
  const verifyEmail = (data) => {
    const otpData = {
      email: email,
      otp: data.verificationCode,
      verifyMode: verifyMode,
    };
    dispatch(verifyOtp(otpData)).then((response) => {
      if (response.payload.token) {
        if (response?.payload?.verifyMode === 0) {
          const temp = {
            token: encrypt(response.payload.token),
            refreshToken: encrypt(response.payload.refreshToken),
          };
          localStorage.setItem("auth", JSON.stringify(temp));
          navigate("/auth/setup-organization", { state: { userId: response?.payload?.userId } });
        } else if (response?.payload?.verifyMode === 1) {
          dispatch(passReset());
          navigate("/auth/reset-password", {
            state:
            {
              userId: response?.payload?.userId,
              token: response?.payload?.token,
            }
          });
        }
      }
    })
  }

  const handleResendVerification = () => {
    console.log("verifyMode new", verifyMode === undefined ? "sign up" : "reset password")
    dispatch(resendVerificationCode({
      actionType: verifyMode === undefined ? "sign up" : "reset password",
      email: email,
    })).then((response) => {
      if (response.payload.code === 200) {
        dispatch(showToast({ message: t("alert_messages.otp_send_success"), type: "success", autoClose: 4000 }));
      }
    }).catch((error) => {
      dispatch(showToast({ message: error.message, type: "error", autoClose: 4000 }))
    }).finally(() => {
      dispatch(requestFailed(null));
    });
  };

  return (
    <AuthPageRootLayout containerClassName="container-tight">
      <div>
        <div className="my-5">
          <h2 className="h1 text-center">{t("form_headers.verify")}</h2>
          <p className="fs-h3 text-muted text-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t("form_helpers.verify_email_description", {
                  email: `<strong>${email}</strong>`,
                }),
              }}
            />
            <p className="mt-3 mb-0">{t("form_helpers.verify_email_instructions")}</p>
          </p>
        </div>

        {error && <UCFormCommonError error={error}
          clearFn={() => dispatch(requestFailed(null))} />}

        <div className="card card-md">
          <div className="card-body">
            <VerifyEmailForm handleOtpSubmit={verifyEmail} loading={loading} />
          </div>
        </div>

        <div className="text-center text-muted mt-3">
          {t("form_helpers.verify_email_resend_prefix")}
          <br />
          <button
            onClick={handleResendVerification}
            type="button"
            style={{
              background: "none",
              border: "none",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("form_helpers.verify_email_resend_suffix")}
          </button>
        </div>
      </div>
    </AuthPageRootLayout>
  );
}

export default VerifyEmail;