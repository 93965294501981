import { createSlice } from "@reduxjs/toolkit";

const dncSlice = createSlice({
  name: "dnc",
  initialState: {
    loading: false,
    error: null,
    resetting: false,
    dncList:null,
  },
  reducers: {
    getDNCListSuccess: (state, action) => {
        state.loading = false;
        state.dncList = action.payload;
      },
    getDNCListError: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
  },
});

export const {
    getDNCListSuccess,
    getDNCListError
} = dncSlice.actions;
export default dncSlice.reducer;
