import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import InviteUser from "./InviteUser";

function InviteIndex() {
    const navigate = useNavigate();


    const handleSkip = () => {
        navigate("/");
    };
    return (
        <div className="page page-center">
            <div className="container container-md py-4">
                <div className="text-center mb-4">
                    <Link to="." className="navbar-brand navbar-brand-autodark">
                        <img src={logo} height="50" alt="" />
                    </Link>
                </div>
                <div  className="card" autoComplete="off">
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">Invite User</h2>
                    <InviteUser />
                </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <button type="button" className="btn btn-link w-100" onClick={handleSkip}>
                        Skip
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InviteIndex;
