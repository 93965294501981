import React from 'react'

import DescriptionItem from '@components/DescriptionItem'
import QRInvite from '@components/QRInvite'

const UserConfigQR = ({
    profile,
    enableMobileLink,
    enableWelcomeMessage
}) => {
    return (
        <div>
            <div className='mt-4'>
                <DescriptionItem
                    itemNumber="1"
                    title="STEP 01"
                    className='mb-2'
                    description="Download 3CX app in App store or Play store." />
                <DescriptionItem
                    itemNumber="2"
                    title="STEP 02"
                    className='mb-2'
                    description="Scan the QR code using the 3CX app QR code scanner." />
            </div>
            {
                !profile?.firstLookStatus || profile?.firstLookStatus === 0 ? <div className='mt-4'>
                    <QRInvite qrLink={profile?.ProvLink}
                        enableWelcomeMessage={enableWelcomeMessage} />
                </div> : null
            }
        </div>
    )
}

export default UserConfigQR