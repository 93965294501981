import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Page from "../../../layouts/Page";

import { showModal } from "@components/reducers/modal";
import { showToast } from "@components/reducers/toast";

import QueueList from "./QueueList";
import CreateForm from "./createForm";
import { isMobileScreen } from "@utils/utils";
import { openConfirmation } from "@modules/modals/reducer";
import QueueDetailView from "./QueueDetailView";
import {  getQueueList, deleteQueue } from '../action'; // Adjust the action imports as needed

export default function Queues() {
    const dispatch = useDispatch();
    const [showCard, setShowCard] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Or whatever your initial page is
    const [currentComponent, setCurrentComponent] = useState(null);
    const mobileScreen = isMobileScreen();

    const handleAddQueueClick = () => {
        if (mobileScreen) {
            dispatch(showModal({ modalContentKey: 'queue-form-modal' }));
        } else {
            setCurrentComponent('QueueForm');
            setShowCard(true);
        }
    };


    
    const fetchQueues = () => {
      dispatch(getQueueList());
  };

    const handleDeleteClick = (queue) => {
        const confirmDeletion = async () => {
            try {
                const queueDeleteResult = await dispatch(deleteQueue({ id: queue.Id }));
                if (queueDeleteResult?.payload?.status === 200) {
                    dispatch(showToast({ message: "Queue deleted successfully!", type: "success" }));
                    dispatch(getQueueList()); // Refresh the queue list after deletion
                } else {
                    dispatch(showToast({ message: "Queue deletion failed!", type: "error" }));
                }
            } catch (error) {
                dispatch(showToast({ message: "Queue deletion failed!", type: "error" }));
            }
        };

        dispatch(
            openConfirmation({
                confirmationData: {
                    title: "Confirmation required",
                    content: "Confirm deletion of Queue?",
                    onConfirm: confirmDeletion,
                },
            })
        );
    };

    const handleCancelClick = () => {
        setSelectedQueue(null);
        setShowCard(false);
        setCurrentComponent(null);
    };

    const handleViewDetailsClick = (queue) => {
        console.log("queue", queue);
        if (mobileScreen) {
            dispatch(showModal({
                modalContentKey: 'queue-details-modal',
                data: { queue }
            }));
        } else {
            setSelectedQueue(queue);
            setCurrentComponent('QueueDetails'); // Set component to view details
            setShowCard(true);
        }
    };

    const handleEditClick = (queue, currentPage) => {
        if (mobileScreen) {
            dispatch(showModal({
                modalContentKey: 'queue-form-modal', data: {
                    queue: queue,                }
            }));
        } else {
            setSelectedQueue(queue);
            setCurrentComponent('QueueForm');
            setShowCard(true);
        }
    };

    const createButton = (
        <Button
            className="btn btn-primary mt-3 mt-md-auto"
            onClick={handleAddQueueClick}
        >
            Add Queue
        </Button>
    );

    return (
        <Page title="Queues" buttons={[createButton]}>
            <div className="row">
                <div className={`${mobileScreen ? "col-12" : showCard ? "col-lg-8" : "col-12"}`}>
                    <QueueList
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick} 
                        onViewDetailsClick={handleViewDetailsClick}                    />
                </div>
                {showCard && !mobileScreen && (
                    <div className="col-lg-4">
                        {currentComponent === 'QueueForm' && (
                            <CreateForm
                                queue={selectedQueue}
                                // onFinish={handleCancelClick}
                                onQueueCreationSuccess={fetchQueues}
                            />
                        )}

                        {currentComponent === 'QueueDetails' && (
                            <QueueDetailView
                                queue={selectedQueue}
                                onEditClick={handleEditClick}
                                onClose={handleCancelClick}
                            />
                        )}
                    </div>
                )}
            </div>
        </Page>
    );
}
