import React, { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconMail, IconPlus } from "@tabler/icons-react";
import { inviteUser } from "../../users/actions";
import { sendInviteError } from "../../users/reducer";
import { Loader } from "../../../components/Loader";
import { toast } from 'react-toastify';

function InviteUser() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.users.loading);
    const [formData, setFormData] = useState([
        { email: "", role: "" } 

    ]);
   // console.log(formData)

    useEffect(() => {
        dispatch(sendInviteError(null));
    }, [dispatch]);

    const errorInvite = useSelector(state => state.users.errorInvite);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...formData];
        list[index][name] = value;
        setFormData(list);
        dispatch(sendInviteError(null));
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSendOTP = async (e) => {
        e.preventDefault();

        if (formData.some(data => !data.email || !data.role)) {
            dispatch(sendInviteError("Please fill all the fields."));
            return;
        }

        const invalidEmails = formData.filter(data => !isEmailValid(data.email));
        if (invalidEmails.length > 0) {
            dispatch(sendInviteError("Please enter valid email addresses."));
            return;
        }

        try {
            //console.log(formData);
            const res = await dispatch(inviteUser(formData));
            const status = res.payload[0].code;
            toast.success(res.payload[0].message);
            if(status === 200){
            navigate("/"); 
            }
        
        } catch (error) {
            console.error("Error inviting user:", error);
        }
    };

    const handleCreateRow = () => {
        setFormData([...formData, { email: "", role: "" }]);
    };

    const handleCancel = () => {
        setFormData([{ email: "", role: "" }]);
    };

    return (
        <div className="page page-center">
            <div className="container py-4">
                <div className="text-center mb-4">
                    {/* <Link to="." className="navbar-brand navbar-brand-autodark">
                        <img src={logo} height="50" alt="" />
                    </Link> */}
                </div>
                {errorInvite ? <div className="text-danger bg-danger text-danger-fg px-3 py-1 border border-danger text-center rounded my-3">{errorInvite}</div> : ""}
                <form onSubmit={handleSendOTP} noValidate autoComplete="off">
                    {/* className="card card-md" */}
                    {/* <div className="card-body"> */}
                    {/* <h2 className="card-title text-center mb-4">Invite User</h2> */}
                    {formData.map((data, index) => (
                        <div className="row mb-3" key={index}>
                            <div className="col-md-6">
                                <label className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    value={data.email}
                                    name="email"
                                    onChange={(e) => handleInputChange(e, index)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Role</label>
                                <select
                                    className="form-select"
                                    value={data.role}
                                    name="role"
                                    onChange={(e) => handleInputChange(e, index)}
                                >
                                    <option value="">Select Role</option>
                                    <option value="agent">Agent</option>
                                    <option value="orgAdmin">Organization Admin</option>
                                </select>
                            </div>
                        </div>
                    ))}
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-outline-secondary float-end" onClick={handleCreateRow} style={{ borderRadius: '15%' }}>
                                <IconPlus></IconPlus>
                                Another User
                            </button>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6 ">
                        </div>
                        <div className="col-md-6 text-end">
                            <button type="submit" className="btn btn-primary w-45 me-2">
                                <IconMail className="me-2" />{" "}
                                {loading ? "Inviting..." : "Invite"}
                                {loading && <Loader />}
                            </button>
                            <button type="button" className="btn btn-outline-danger w-40" onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
}

export default InviteUser;
