import React, { useState, useEffect } from "react";
import Page from "../../../layouts/Page";
import CustomFieldList from "./list";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { IconPlus } from "@tabler/icons-react";
import NewCustomField from "./new";
import { getCustomFieldList } from "../actions";
import { deleteCustomField } from "../actions";
import { toast } from "react-toastify";
import { Loader } from "../../../components/Loader";
import { openConfirmation } from "../../modals/reducer";

export default function CustomFields() {
  const dispatch = useDispatch();
  const [showNewCustomField, setShowNewCustomField] = useState(false);
  const [editCustomField, setEditCustomField] = useState(null);

  useEffect(() => {
    dispatch(getCustomFieldList());
  }, [dispatch]);

  const customFieldList = useSelector(
    (state) => state.customFields.customFieldList
  );

  console.log("customFieldList",customFieldList)
  // const customFieldsCount = customFieldList ? customFieldList.length : null;
  
  if (!customFieldList) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  const createButton = (
    <button
      className="btn btn-primary d-none d-sm-inline-block"
      onClick={() => {
        setShowNewCustomField(true);
        setEditCustomField(null);
      }}
    >
      <IconPlus /> New Custom Field
    </button>
  );

  const onEdit = (customFields) => {
    setShowNewCustomField(true);
    setEditCustomField(customFields);
  };

  const handleDeleteConfirmation = (customFields) => {

    const confirmDeletion = async () => {
      try {
          const id = customFields.id;
          const deleteCustomFieldResult = await dispatch(
            deleteCustomField(id)
          );
  
          if (
            deleteCustomFieldResult.payload &&
            deleteCustomFieldResult.payload.code === 200
          ) {
            toast.success("Custom Field Deleted successfully!");
          } else {
            toast.error(deleteCustomFieldResult.payload);
          }
        
      } catch (error) {
        toast.error(error.message);
      } finally {
  
      }
    };
    // Dispatch action
    dispatch(
      openConfirmation({
        confirmationData: {
          title: "Confirmation required",
          content: "Confirm deletion of custom field?",
          onConfirm: () => confirmDeletion(),
        },
      })
    );
  };

  
  return (
    <Page
      title={"Custom Fields"}
      subtitle={`${customFieldList.total} Custom fields found`}
      buttons={[createButton]}
    >
      <Row>
        <Col xs={12} md={8} className="g-3">
          {customFieldList.total > 0 && (
            <CustomFieldList
              customFieldList={customFieldList}
              onEdit={onEdit}
              handleDeleteConfirmation={handleDeleteConfirmation}
              setShowNewCustomField={setShowNewCustomField}
            />
          )}
        </Col>
        <Col className="g-3">
          {showNewCustomField && (
            <NewCustomField
              onCancel={() => {
                setShowNewCustomField(false);
                setEditCustomField(null);
              }}
              onSave={() => {
                setShowNewCustomField(false);
                setEditCustomField(null);
              }}
              editCustomField={editCustomField}
            />
          )}
        </Col>
      </Row>
    </Page>
  );
}
