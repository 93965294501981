import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast, Slide } from 'react-toastify'

import { hideToast } from '../reducers/toast';

const UCToastMessage = () => {
    const dispatch = useDispatch();
    const {
        message,
        autoClose = false,
        type,
    } = useSelector(state => state?.toast);

    const getToastConfig = () => {
        switch (type) {
            case 'success':
                const bgColor = { backgroundColor: 'var(--tblr-success)' }
                return {
                    toastStyle: { ...bgColor },
                    style: { ...bgColor },
                }
            case 'error':
                const errorColor = { backgroundColor: 'var(--tblr-danger)' }
                return {
                    toastStyle: { ...errorColor },
                    style: { ...errorColor },
                }
            case 'info':
                const infoColor = { backgroundColor: 'var(--tblr-primary)' }
                return {
                    toastStyle: { ...infoColor },
                    style: { ...infoColor },
                }
            case 'warning':
                const warningColor = { backgroundColor: 'var(--tblr-warning)' }
                return {
                    toastStyle: { ...warningColor },
                    style: { ...warningColor },
                }
            default:
                return {}
        }
    }

    useEffect(() => {
        if (message) {
            const toastId = toast?.[type](message, {
                position: 'top-center',
                autoClose: autoClose,
                newestOnTop: true,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                theme: 'colored',
                transition: Slide,
                hideProgressBar: true,
                stacked: true,
                onClose: () => handleToastDismissed(toastId),
                ...getToastConfig()
            });
        }
    }, [type, message]);

    const handleToastDismissed = (toastId) => {
        dispatch(hideToast());
        toast.dismiss(toastId);
    }

    return (
        <ToastContainer />
    )
}

export default UCToastMessage