import React from 'react';

const NumberSelectionCard = ({ number, onCheckboxChange, checked, ...field }) => {

  return (
    <div className="card">
      <div className="d-flex align-items-center p-3">
        <div className="me-3">
          <input
            type="radio"
            className="form-check-input"
            {...field}
            checked={checked}
            onChange={(e) => onCheckboxChange(e.target.checked)}
          />
        </div>
        <div className="group-label-content d-flex align-items-center">
          <div className="font-weight-medium">{number}</div>
        </div>
      </div>
    </div>
  );
};

export default NumberSelectionCard;
