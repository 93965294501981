import React from 'react'

import OrganizationSetup from '@modules/auth/views/OrganizationSetup'

const OrganizationUpdate = () => {
    return (
        <OrganizationSetup fromDashboard={true} />
    )
}

export default OrganizationUpdate