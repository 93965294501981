import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import useApi from "@hooks/useApi";

import UpdateUserForm from "../forms/UpdateUserForm";

const UpdateUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const location = useLocation();
  const { data: responseData, getRequest, putRequest } = useApi();
  const { verifyingData } = location.state;
  const [userId, setUserId] = useState(null);

  const { loading } = useSelector(state => state.auth);

  useEffect(() => {
    if (verifyingData) {
      const headers = {
        Authorization: `Bearer ${verifyingData?.token}`
      };
      getRequest({
        url: `v1/users/me`,
        headers: headers
      })
    }
  }, [verifyingData]);

  useEffect(() => {
    if (responseData) {
      setUserId(responseData?.id);
    }
  }, [responseData]);

  const handleUpdate = useCallback((data) => {
    const headers = {
      Authorization: `Bearer ${verifyingData?.token}`
    };

    putRequest({
      url: `v1/users/update/${userId}`,
      payload: data,
      headers: headers,
      notifySuccessMessage: t('alert_messages.update_success'),
      notifyErrorMessage: t('alert_messages.update_failed'),
      completeCallback: () => {
        navigate("/auth/login", { replace: true });
      }
    });
  }, [putRequest, userId, verifyingData]);

  return (
    <AuthPageRootLayout containerClassName="container-tight">
      <div className="card card-md">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">
            {t("form_headers.update_user")}
          </h2>
          {/* {error && <UCFormCommonError error={error}
            clearFn={() => dispatch(requestFailed(null))} />} */}
          <UpdateUserForm
            handleUpdate={handleUpdate}
            loading={loading}
          />
        </div>
      </div>
    </AuthPageRootLayout>
  );
}
export default UpdateUser;
