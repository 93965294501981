import React, { useEffect, useState } from "react";
import { getCustomFieldList } from '../../customFields/actions';
import { Loader } from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';
import { QueryBuilder as ReactQueryBuilder, formatQuery } from 'react-querybuilder';

function QueryBuilderComponent({ onQueryFormat }) {
    const [query, setQuery] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomFieldList());
    }, [dispatch]);

    const customFieldList = useSelector(
        (state) => state.customFields.customFieldList
    );
    
    useEffect(() => {
        if (!customFieldList || !customFieldList.rows) return; // Exit early if customFieldList is not ready

        const fields = customFieldList.rows.map(field => ({
            name: field.name,
            label: field.label
        }));

        if( query.rules && query.rules.length > 0 ){
        const formattedQuery = formatQuery(query, 'mongodb');
        onQueryFormat(formattedQuery);
        }
    }, [query, customFieldList, onQueryFormat]);

    if (!customFieldList || !customFieldList.rows) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    const fields = customFieldList.rows.map(field => ({
        name: field.name,
        label: field.label
    }));

    return (
        <div>
            <QueryBuilderBootstrap>
                <ReactQueryBuilder fields={fields} onQueryChange={setQuery} />
            </QueryBuilderBootstrap>
            {query.rules && query.rules.length > 0 && (
                <div>
                    {/* <h4>Query {JSON.stringify(query)}</h4> */}
                    {query ? <pre>
                        <code>{formatQuery(query, 'mongodb')}</code>
                    </pre> : ""}
                </div>
            )}
        </div>
    );
}

export default QueryBuilderComponent;
