export default function UserSelect() {
    return <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
        <label className="form-selectgroup-item flex-fill">
            <input type="checkbox" name="form-project-manager[]" value="1" className="form-selectgroup-input" />
            <div className="form-selectgroup-label d-flex align-items-center p-3">
                <div className="me-3">
                    <span className="form-selectgroup-check"></span>
                </div>
                <div className="form-selectgroup-label-content d-flex align-items-center">
                    <span className="avatar me-3">PK</span>
                    <div>
                        <div className="font-weight-medium">Paweł Kuna</div>
                        <div className="text-secondary">UI Designer</div>
                    </div>
                </div>
            </div>
        </label>
      
    </div>
}