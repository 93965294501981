import React, { useContext } from 'react';

import { IconSun, IconMoon } from '@tabler/icons-react';

import { ThemeContext } from '@context/ThemeContext';

const ThemeToggler = ({ classNames }) => {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <div onClick={toggleTheme} className={`${classNames} px-3`}>
            {isDarkMode ?
                <IconSun size={20} stroke={3} color='var(--tblr-body-color)' />
                : <IconMoon size={20} stroke={3} color='var(--tblr-body-color)' />}
        </div>
    );
};

export default ThemeToggler;