import React from 'react'

import { IconMail } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from '@components/ButtonWithLoader';

const VerifyEmailForm = ({ handleOtpSubmit, loading }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        handleOtpSubmit(data);
    };

    return (
        <form
            method="post"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="mb-3">
                    <label className="form-label">
                        {t("form_fields.verification_code.label")}
                    </label>
                    <input
                        type="text"
                        className={`form-control ${errors.verificationCode && 'is-invalid'}`}
                        {...register('verificationCode', { required: true })}
                        placeholder={t("form_fields.verification_code.placeholder")}
                        autoComplete="off"
                    />
                    {errors.verificationCode && <div className="invalid-feedback">
                        {t("form_validations.required")}
                    </div>}
                </div>
                <div className="form-footer">
                    <ButtonWithLoader
                        className="btn btn-primary w-100"
                        loading={loading}
                        type="submit"
                    >
                        <IconMail className="me-2" />{" "}
                        {loading ? t("form_actions.verify_loading") : t("form_actions.verify")}
                    </ButtonWithLoader>
                </div>
            </div>
        </form>
    )
}

export default VerifyEmailForm