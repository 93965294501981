import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const usePreventBack = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const preventBack = () => {
            navigate(location.pathname, { replace: true });
        };

        window.history.pushState(null, '', location.pathname);
        window.addEventListener('popstate', preventBack);

        return () => window.removeEventListener('popstate', preventBack);
    }, [navigate, location]);
}

export default usePreventBack;