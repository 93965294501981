// HourlyGraph.js

import React from "react";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';

import styles from "../../../../styles/widget.module.css"; // Import your module.css file

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


export default function HourlyGraph({ data }) {
    // console.log("data", data);
    // const data = getData();

    const labels = Array.from({ length: 24 }, (_, i) => {
        const hour = i % 12 || 12;
        const period = i < 12 ? 'AM' : 'PM';
        return `${hour} ${period}`;
    });

    const populateDataArray = (key) => {
        const arr = new Array(24).fill(0);
        data?.forEach(item => {
            const hour = moment(item.start_time).utc().get('hour');
            arr[hour] = item[key] === null ? 0 : item[key];
        });
        return arr;
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Outbound Answered',
                stack: "Outbound",
                data: populateDataArray('outbound_answered'),
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
            },
            {
                label: 'Outbound Unanswered',
                stack: "Outbound",
                data: populateDataArray('outbound_unanswered'),
                backgroundColor: 'rgba(255, 99, 132, 0.3)',
            },
            {
                label: 'Inbound Answered',
                stack: "Inbound",
                data: populateDataArray('inbound_answered'),
                backgroundColor: 'rgba(53, 162, 235, 0.8)',
            },
            {
                label: 'Inbound Unanswered',
                stack: "Inbound",
                data: populateDataArray('inbound_unanswered'),
                backgroundColor: 'rgba(53, 162, 235, 0.3)',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom'
            },
            title: {
                display: false,
                text: 'Hourly Call Data',
            },
        },
        scales: {
            x: {
                type: 'category',
                labels: labels,
                title: {
                    display: true,
                    text: 'Hour'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Count'
                }
            }
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <h3>Call Distribution</h3>
                <div className={styles["chart-wrapper"]}>
                    <Bar options={options} data={chartData} />
                </div>
                <div id="html-dist"></div>
            </div>
        </div>
    );
}
