// confirmationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const confirmationSlice = createSlice({
  name: 'modals',
  initialState: {
    showConfirmation: false,
    confirmationData: {},
  },
  reducers: {
    openConfirmation: (state, action) => {
      state.showConfirmation = true;
      state.confirmationData = action.payload.confirmationData;
    },
    closeConfirmation: (state) => {
      state.showConfirmation = false;
      state.confirmationData = {};
    },
  },
});

export const { openConfirmation, closeConfirmation } = confirmationSlice.actions;
export default confirmationSlice.reducer;
