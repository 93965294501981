import { API_URL } from "../../config/config";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ivrList,  getIVRNumber, getPrompts, ivrLoading } from "./reducer";
import AxiosInstance from "../../config/axiosInterceptors";
import { toast } from "react-toastify";

export const getIVRList = createAsyncThunk('voice/getIVRList', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get('v1/3cx/ivr',);
        dispatch(ivrList(response.data));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getIVRById = createAsyncThunk('voice/getIVRById', async (id, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get(`v1/3cx/ivrById?id=${id}`,);
        // dispatch(ivrEditData(response.data));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteIVRList = createAsyncThunk('voice/deleteIVRList', async (id, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.delete(`v1/3cx/ivr?id=${id}`);
        toast.success(response.data.message);
        await dispatch(getIVRList());
        return response.data;
    } catch (error) {
        toast.error("Failed to delete IVR!")
        return rejectWithValue(error.message);
    }
});

export const addIvr = createAsyncThunk('voice/addIVR', async (iVRData, { dispatch, rejectWithValue }) => {
    try {
        dispatch(ivrLoading())
        const response = await AxiosInstance.post(`v1/3cx/ivr`, iVRData);
        toast.success(response.data.message);
        await dispatch(getIVRList());
        return response.data;
    } catch (error) {
        toast.error("Failed to create IVR!");
        return rejectWithValue(error.message);
    }
});

export const updateIvr = createAsyncThunk('voice/updateIvr', async (iVRData, { dispatch, rejectWithValue }) => {
    try {
        dispatch(ivrLoading())
        const response = await AxiosInstance.put(`v1/3cx/ivr`, iVRData);
        toast.success(response.data.message);
        await dispatch(getIVRList());
        return response.data;
    } catch (error) {
        toast.error("Failed to update IVR!");
        return rejectWithValue(error.message);
    }
});

export const getFirstAvailableIVRNumber = createAsyncThunk('voice/getFirstAvailableIVRNumber', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get(`v1/3cx/firstAvailableIVRNumber`);
        dispatch(getIVRNumber(response.data));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getPromptList = createAsyncThunk('voice/getPromptList', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get(`v1/3cx/prompts`);
        dispatch(getPrompts(response.data));
        return response.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const promptUpload = createAsyncThunk('voice/uploadPrompt', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const response = await fetch(API_URL + `v1/prompts`, {
            method: 'POST',
            body: formData
        });
        toast.success("Prompt uploaded successfully");
        dispatch(getPromptList())
        return response.data;
    } catch (error) {
        toast.error("Failed to upload prompt!");
        return rejectWithValue(error.message);
    }
});

export const deletePrompt = createAsyncThunk('voice/deletePromptList', async (prompt, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.delete(`v1/3cx/prompts?prompt=${prompt}`);
        toast.success(response.data.message);
        dispatch(getPromptList())
        return response.data;
    } catch (error) {
        toast.error("Failed to delete prompt!");
        dispatch(getPrompts(error.message));
        return rejectWithValue(error.message);
    }
});
