import storage from 'redux-persist/lib/storage'; // Default to localStorage

import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';


const importAllReducers = () => {
  const context = require.context('./modules', true, /reducer\.js$/);
  const slices = {};

  context.keys().forEach((key) => {
    const slice = context(key).default;
    const sliceName = key.replace(/\.\/|\/reducer\.js/g, '');
    slices[sliceName] = slice;
  });

  const componentContext = require.context('./components/reducers', true, /\.js$/);
  componentContext.keys().forEach((key) => {
    const slice = componentContext(key).default;
    const sliceName = key.match(/([^/]+)\.js$/)[1];
    slices[sliceName] = slice;
  });

  return slices;
};

const rootReducer = combineReducers(importAllReducers());

//persist config
// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['toastr', 'sms', 'auth', 'profile'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
// });

export const store = configureStore({
  reducer: rootReducer
});

// const persistor = persistStore(store);

// export { store, persistor };
export default store;