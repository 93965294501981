
import Dropdown from "react-bootstrap/Dropdown";

import {
    IconLogout,
    IconUser,
} from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";

import logo from "@assets/logo.png";

import LangThemeToggler from "./LangThemeToggler";

function NavigationHeader({
    fromEntryPage = false,
    toggleMenu,
    handleLogout,
    isMenuVisible,
    fromDashboard,
    userProfile }) {

    return (
        <header className={`${fromEntryPage && 'navbar-entry'} navbar navbar-expand-md navbar-light d-print-none`}>
            <div className={`${fromEntryPage && 'd-flex justify-content-end'} container-fluid`}>
                {
                    !fromEntryPage && <>
                        <button
                            onClick={() => toggleMenu(!isMenuVisible)}
                            className="navbar-toggler"
                            type="button"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                            <Link to="#">
                                <img
                                    src={logo}
                                    width="110"
                                    height="32"
                                    alt="Tabler"
                                    className="navbar-brand-image"
                                />
                            </Link>
                        </h1>
                    </>
                }
                <div className="navbar-nav flex-row order-md-last">
                    <div className="nav-item">
                        <LangThemeToggler fromDashboard={fromDashboard} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default NavigationHeader