import React from "react";
import Page from "../../../layouts/Page";
import { Row, Col } from "react-bootstrap";

import DncList from "./dncList";

export default function Dnc() {

  return (
    <Page
      title={"DNC List"}
      onSearch={() => { }}
    >
      <Row>
        <Col xs={12} md={8} className="g-3">

          <DncList />

        </Col>
      </Row>
    </Page>
  );
}
