import React from 'react'

const DescriptionItem = ({
  itemNumber,
  title,
  description,
  className = ''
}) => {
  return (
    <div className={`${className} d-flex align-items-center`}>
      <div className='dic-point text-muted-fg me-2'>
        <span className='p-2'>
          {itemNumber}
        </span>
      </div>
      <div className='ms-2'>
        <h4 className='mb-0'>{title}</h4>
        <p className="text-secondary mb-0">{description}</p>
      </div>
    </div>
  )
}

export default DescriptionItem