import React, { useCallback, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useApi from '@hooks/useApi';
import { IconX } from '@tabler/icons-react';

const UserList = forwardRef((props, ref, editable) => {
  console.log("Component is rendering");

  const { onUpdateAvailableExtensions, onRemoveUserExtension } = props;
  const { data: filteredUsers, loading: loadingFilteredUsers, getRequest: getFilteredUsers } = useApi();
  const { data: queueData, loading: loadingQueue, getRequest: getQueueById } = useApi();
  const [refetch, setRefetch] = useState(0);
  const [removedItems, setRemovedItems] = useState([]);
  const { t } = useTranslation();
  const profile = useSelector(state => state.profile);
  const organization = useSelector(state => state.auth.organization);

  useEffect(() => {
    getFilteredUsers({ url: 'v1/users/filtered-users' });
  }, [refetch]);

  // useEffect(() => {
  //   if (organization?.defaultQueueId) {
  //     getQueueById({ url: `v1/3cx/default-queue` });
  //   }
  // }, [organization?.defaultQueueId]);

  useEffect(() => {
        getQueueById({ url: `v1/3cx/default-queue` });
}, []);


  useImperativeHandle(ref, () => ({
    triggerRefetch: () => setRefetch(prev => prev + 1)
  }));

  const getStatus = useCallback((status) => status ?
    t('form_headers.invitation_accepted') :
    t('form_headers.invitation_rejected'), [t]);

  const handleRemove = (email, extension) => {
    setRemovedItems(prev => [...prev, email]);
    if (onRemoveUserExtension) {
      console.log("Passing removed extension to parent:", extension); 
      onRemoveUserExtension(extension); 
    }
  };

  const renderInvitedList = useMemo(() => {
    if (filteredUsers && queueData) {
        const queueAgents = queueData.Agents.map(agent => agent.Number?.toString()); 

        const availableUsers = filteredUsers.filter(user => 
            !removedItems.includes(user?.email) &&
            profile?.profile?.email !== user?.email &&
            queueAgents.includes(user.extension?.toString()) 
        );

        const availableExtensions = availableUsers.map(user => user.extension);
        if (onUpdateAvailableExtensions) {
            onUpdateAvailableExtensions(availableExtensions);
        }

        return availableUsers.map((item, index) => {
            const inviteAccepted = item?.inviteAccepted;

            return (
                <div key={index} className='mb-2 d-flex align-items-center'>
                    <div className="avatar avatar-rounded placeholder me-2 avatar-placeholder"></div>
                    <div className='d-flex flex-grow-1'>
                        <div className="flex-grow-1">
                            <span className='text-reset d-block text-truncate'>
                                {item.inviteAccepted ? `${item.firstName} ${item.lastName}` : item.email}
                            </span>
                            <div className='d-flex align-items-center'>
                                <div className={`text-secondary text-truncate mt-n1 me-2 ${inviteAccepted ? 'text-lime' : 'text-yellow'}`}>
                                    {getStatus(inviteAccepted)}
                                </div>
                                <div>{item.extension}</div>
                            </div>
                        </div>
                        {/* <button
                            type="button"
                            className="btn btn-icon btn-outline-danger"
                            onClick={() => handleRemove(item?.email, item?.extension?.toString())}  // Pass email and extension
                        >
                            <IconX />
                        </button> */}
                    </div>
                </div>
            );
        });
    }
    return null;
  }, [filteredUsers, queueData, removedItems, onUpdateAvailableExtensions, getStatus, profile]);


  return (
    !loadingFilteredUsers && !loadingQueue && filteredUsers?.length > 0 ? (
      <div>
        <h4 className='mb-2'>{t('form_headers.users_list')}</h4>
        {renderInvitedList}
      </div>
    ) : null
  );
});

export default UserList;
