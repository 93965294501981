// CsvWorker.js
import Papa from "papaparse";

const workerFunction = (file) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const filteredData = result.data.filter((row) =>
          Object.values(row).some((value) => value !== "")
        );
        resolve(filteredData);
      },
    });
  });
};

export default workerFunction;
