import React from 'react';

import InviteAgentFormComponent from '@components/forms/InviteAgentFormComponent';

function InviteAgent({ onFinish }) {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Invite Agent</h5>

                <InviteAgentFormComponent onFinish={onFinish} />
            </div>
        </div>
    );
}

export default InviteAgent;
