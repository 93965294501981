import React, { useState, useEffect} from "react";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import Page from "../../../layouts/Page";
import { toast } from "react-toastify";
import {
  IconBrandWhatsapp,
  IconMessage,
  IconPhoneCall,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import UserSelect from "../../users/views/UserSelect";
import { createCampaignAsync } from "../actions";
import { Trans, useTranslation } from "react-i18next";
import LanguageSwitch from "../../../components/LanguageSwitch";
import ImportDropzone from "../../contacts/widgets/ImportDropzone";
import QueryBuilderComponent from "./queryBuilder";


function NewCampaign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formattedQuery, setFormattedQuery] = useState('');

  console.log("formattedQuery1",formattedQuery);
    const handleQueryFormat = (formattedQuery) => {
        setFormattedQuery(formattedQuery);
        console.log("formattedQuery2",formattedQuery);
    };

  const [campaignData, setCampaignData] = useState({
    name: "",
    channel: "",
    startDate: "",
    audience:"",
    query:""
  });

  
 
  // Event Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "channels") {
      handleChannelsChange(value);
    } else {
      setCampaignData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };



  
  const handleChannelsChange = (selectedChannel) => {
    setCampaignData((prevData) => ({
      ...prevData,
      channel: selectedChannel,
    }));
  };

  const handleAudienceChange = (selectedAudience) =>{
    setCampaignData((prevData) => ({
      ...prevData,
      audience: selectedAudience,
    }));
  }

  const handleStartDateAndTimeChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setCampaignData((prevData) => ({
        ...prevData,
        startDate: value,
      }));
    }
  };

  const handleCreateCampaign = async (e) => {
    e.preventDefault();

    try {
      const createCampaignResult = await dispatch(createCampaignAsync(campaignData));
      if (createCampaignResult.payload && createCampaignResult.payload.data._id) {
        toast.success("Campaign Creation Success.");
        navigate("/campaigns");
      } else {
        toast.error(createCampaignResult.payload);
      }
    } catch (error) {
      toast.error("Campaign Creation failed:", error.message);
    }
  };

  const handleCancel = () => {
    navigate("/campaigns");
  };
  return (
    <Page title={"New Campaign"}>
      <Row>
        <Col sm="12" lg="8" className="page-container-body-withscroll">
          <Card className="mb-3">
            <CardBody>
              <h3 className="card-title">{t('createCampaign.campaign_details_header')}</h3>
              <div className="mb-3">
                <label className="form-label">{t('createCampaign.campaign_name_label')}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('createCampaign.campaign_name_placeholder')}
                  name="name"
                  value={campaignData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t('createCampaign.select_channel_label')}</label>
                <div className="form-selectgroup">
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="channels"
                      value="voice"
                      className="form-selectgroup-input"
                      checked={campaignData.channel === "voice"}
                      onChange={() => handleChannelsChange("voice")}
                    />
                    <span className="form-selectgroup-label">
                      <IconPhoneCall />
                      {t('createCampaign.phone_channel_label')}
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="channels"
                      value="sms"
                      className="form-selectgroup-input"
                      checked={campaignData.channel === "sms"}
                      onChange={() => handleChannelsChange("sms")}
                    />
                    <span className="form-selectgroup-label">
                      <IconMessage />
                      {t('createCampaign.sms_channel_label')}
                    </span>
                  </label>
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="channels"
                      value="whatsapp"
                      className="form-selectgroup-input"
                      checked={campaignData.channel === "whatsapp"}
                      onChange={() => handleChannelsChange("whatsapp")}
                    />
                    <span className="form-selectgroup-label">
                      <IconBrandWhatsapp />
                      {t('createCampaign.whatsapp_channel_label')}
                    </span>
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('createCampaign.start_date_time_label')}</label>
                <Row className="g-2">
                  <Col xs="6" lg="4">
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="startDate"
                      value={campaignData.startDate}
                      onChange={handleStartDateAndTimeChange}
                    />
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <Card className={"mb-3"}>
            <CardBody>
              <h3 className="card-title">{t('createCampaign.select_audience_label')}</h3>
              <Row className="form-selectgroup-boxes mb-3 g-2">
                <Col xs="12" lg="4">
                  <label className="form-selectgroup-item">
                    <input
                      type="radio"
                      name="audience"
                      value="group_contact"
                      className="form-selectgroup-input"
                      checked={campaignData.audience === "group_contact"}
                      onChange={() => handleAudienceChange("group_contact")}
                    />
                    <span className="form-selectgroup-label d-flex align-items-center p-3">
                      <span className="me-3">
                        <span className="form-selectgroup-check"></span>
                      </span>
                      <span className="form-selectgroup-label-content">
                        <span className="form-selectgroup-title strong mb-1">
                          Contact Groups
                        </span>
                        <span className="d-block text-muted">
                          Select audience from contact group.
                        </span>
                      </span>
                    </span>
                  </label>
                </Col>
                <Col xs="12" lg="4">
                  <label className="form-selectgroup-item">
                  <input
                      type="radio"
                      name="audience"
                      value="search_contact"
                      className="form-selectgroup-input"
                      checked={campaignData.audience === "search_contact"}
                      onChange={() => handleAudienceChange("search_contact")}
                    />
                    <span className="form-selectgroup-label d-flex align-items-center p-3">
                      <span className="me-3">
                        <span className="form-selectgroup-check"></span>
                      </span>
                      <span className="form-selectgroup-label-content">
                        <span className="form-selectgroup-title strong mb-1">
                          Search Contacts
                        </span>
                        <span className="d-block text-muted">
                          Search audience from your contacts.
                        </span>
                      </span>
                    </span>
                  </label>
                </Col>
           
                <Col xs="12" lg="4">
                  <label className="form-selectgroup-item">
                  <input
                      type="radio"
                      name="audience"
                      value="upload_csv"
                      className="form-selectgroup-input"
                      checked={campaignData.audience === "upload_csv"}
                      onChange={() => handleAudienceChange("upload_csv")}
                    />
                    <span className="form-selectgroup-label d-flex align-items-center p-3">
                      <span className="me-3">
                        <span className="form-selectgroup-check"></span>
                      </span>
                      <span className="form-selectgroup-label-content">
                        <span className="form-selectgroup-title strong mb-1">
                          Upload CSV
                        </span>
                        <span className="d-block text-muted">
                          Upload contacts with a CSV file.
                        </span>
                      </span>
                    </span>
                  </label>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {campaignData.audience ==="upload_csv"&&(
            <Card className={"mb-3"}>
              <CardBody>
                <ImportDropzone/>
              </CardBody>
            </Card>
          )}
               {campaignData.audience ==="search_contact"&&(
            <Card className={"mb-3"}>
              <CardBody>
                <QueryBuilderComponent  onQueryFormat={handleQueryFormat}/>
              </CardBody>
            </Card>
          )}
          {campaignData.channel === "voice" && (
            <Card className={"mb-3"}>
              <CardBody>
                <h3 className="card-title">Configure Voice Campaign</h3>
                <Row className="form-selectgroup-boxes mb-3 g-2">
                  <Col xs="12" lg="6">
                    <label className="form-selectgroup-item">
                      <input
                        type="radio"
                        name="report-type"
                        value="1"
                        className="form-selectgroup-input"
                        checked
                      />
                      <span className="form-selectgroup-label d-flex align-items-center p-3">
                        <span className="me-3">
                          <span className="form-selectgroup-check"></span>
                        </span>
                        <span className="form-selectgroup-label-content">
                          <span className="form-selectgroup-title strong mb-1">
                            Manual Dialing
                          </span>
                          <span className="d-block text-muted">
                            Assign the campaign to agents, and do dial.
                          </span>
                        </span>
                      </span>
                    </label>
                  </Col>
                  <Col xs="12" lg="6">
                    <label className="form-selectgroup-item">
                      <input
                        type="radio"
                        name="report-type"
                        value="1"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label d-flex align-items-center p-3">
                        <span className="me-3">
                          <span className="form-selectgroup-check"></span>
                        </span>
                        <span className="form-selectgroup-label-content">
                          <span className="form-selectgroup-title strong mb-1">
                            Automated Dialing
                          </span>
                          <span className="d-block text-muted">
                            Run the campaign using an automated flow.
                          </span>
                        </span>
                      </span>
                    </label>
                  </Col>
                </Row>
                <div className="mb-3">
                  <label className="form-label">Select Agents</label>
                  <UserSelect />
                </div>
              </CardBody>
            </Card>
          )}
          {campaignData.channel === "sms" && (
            <Card className={"mb-3"}>
              <CardBody>
                <h3 className="card-title">Configure SMS Campaign</h3>
                <div className="mb-3">
                  <label className="form-label">Number Mask</label>
                  <div className="form-selectgroup">
                    <label className="form-selectgroup-item">
                      <input
                        type="checkbox"
                        name="name"
                        value="HTML"
                        className="form-selectgroup-input"
                        checked=""
                      />
                      <span className="form-selectgroup-label">Uniconnect</span>
                    </label>
                    <label className="form-selectgroup-item">
                      <input
                        type="checkbox"
                        name="name"
                        value="CSS"
                        className="form-selectgroup-input"
                      />
                      <span className="form-selectgroup-label">
                        0117 687 587
                      </span>
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-label">Message Template</div>
                  <select className="form-select">
                    <option value="1">Create new message</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Content{" "}
                    <span className="form-label-description">
                      56/160 (1 message)
                    </span>
                  </label>
                  <textarea
                    className="form-control"
                    name="example-textarea-input"
                    rows="4"
                    placeholder="Content.."
                  >
                    Oh! Come and see the violence inherent in the system! Help,
                    help, I'm being repressed! We shall say 'Ni' again to you,
                    if you do not appease us. I'm not a witch. I'm not a witch.
                    Camelot!
                  </textarea>
                </div>
                <div className="badges-list">
                  <span className="badge badge-outline text-primary">Name</span>
                  <span className="badge badge-outline text-primary">
                    Next Appointment Date
                  </span>
                  <span className="badge badge-outline text-primary">
                    Appointment Status
                  </span>
                  <span className="badge badge-outline text-primary">
                    Service
                  </span>
                  <span className="badge badge-outline text-primary">
                    Estimated Amount
                  </span>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col sm="12" lg="4">
          <div className="d-none d-lg-block card mb-3">
            <div className="card-body">
              <h3 className="card-title">Campaign Setup</h3>
              <ul className="steps steps-vertical">
                <li className="step-item">
                  <div className="h4 m-0">Campaign Details</div>
                  <div className="text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Accusamus culpa cum expedita ipsam laborum nam ratione
                    reprehenderit sed sint tenetur!
                  </div>
                </li>
                <li className="step-item">
                  <div className="h4 m-0">Select Audience</div>
                  <div className="text-secondary">
                    Lorem ipsum dolor sit amet.
                  </div>
                </li>
                <li className="step-item active">
                  <div className="h4 m-0">Configure Voice Campaign</div>
                  <div className="text-secondary">
                    Lorem ipsum dolor sit amet.
                  </div>
                </li>
                <li className="step-item">
                  <div className="h4 m-0">Configure SMS Campaign</div>
                  <div className="text-secondary">
                    Lorem ipsum dolor sit amet.
                  </div>
                </li>
                <li className="step-item">
                  <div className="h4 m-0">Configure WhatsApp Campaign</div>
                  <div className="text-secondary">
                    Lorem ipsum dolor sit amet.
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex g-2 mb-3 justify-content-end">
            <button className="btn btn-outline-danger" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-outline-primary ms-2"
              disabled={
                !campaignData.name ||
                !campaignData.channel ||
                !campaignData.startDate
              }
            >
              Save as Draft
            </button>
            <button
              className="btn btn-primary ms-2"
              onClick={handleCreateCampaign}
              disabled={
                !campaignData.name ||
                !campaignData.channel ||
                !campaignData.startDate
              }
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Page>
  );
}

export default NewCampaign;
