import { Alert, Button, Card, CardBody, CardTitle, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import Page from "../../../layouts/Page";
import { IconBrandWhatsapp, IconMessage, IconPhoneCall } from "@tabler/icons-react";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getNumberList, reserveNumber } from "../actions";

function PhoneConfig() {
    const dispatch = useDispatch();
    const numbers = useSelector(state => state.config.numbers);
    const profile = useSelector(state => state.auth.profile);
    const [selectedNumber, setSelectedNumber] = useState(null);

    const [selectedChannels, setSelectedChannels] = useState({
        voice: true,
        sms: false,
        whatsapp: false
    });
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            await dispatch(getNumberList());
            // console.log(data)
        } catch (error) {
            console.log(error)
        }
    }


    const handleNumberSelect = (data) => {
        setSelectedNumber(data);
    };

    const handleChannelSelect = (channel) => {
        setSelectedChannels(prevState => ({
            ...prevState,
            [channel]: !prevState[channel]
        }));
    };
    const handleReserveClick = () => {
        if (selectedNumber) {
            setShowWarning(false);
        }
        if (selectedNumber === null) {
            setShowWarning(true);
        } else {
            try {
                console.log(selectedNumber)
                dispatch(reserveNumber({ id: selectedNumber.id }));
            } catch (error) {
                console.log(error)
            }
        }
    };

    return <Page title={"Select Channels"}>
        <div className="form-selectgroup form-selectgroup-sm mb-3">
            <label className="form-selectgroup-item flex-fill">
                <input type="checkbox" name="form-channels" value="voice" className="form-selectgroup-input" defaultChecked={selectedChannels.voice} onChange={() => handleChannelSelect('voice')} />
                <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                        <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                        <strong>Voice <IconPhoneCall /></strong>
                    </div>
                </div>
            </label>
            <label className="form-selectgroup-item flex-fill">
                <input type="checkbox" name="form-channels" value="sms" className="form-selectgroup-input" defaultChecked={selectedChannels.sms} onChange={() => handleChannelSelect('sms')} />
                <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                        <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                        <strong>SMS <IconMessage /></strong>
                    </div>
                </div>
            </label>

            <label className="form-selectgroup-item flex-fill">
                <input type="checkbox" name="form-channels" value="whatsapp" className="form-selectgroup-input" defaultChecked={selectedChannels.whatsapp} onChange={() => handleChannelSelect('whatsapp')} />
                <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                        <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                        <strong>WhatsApp <IconBrandWhatsapp /></strong>
                    </div>
                </div>
            </label>
        </div>

        <Card className="mb-3" style={{ backgroundColor: selectedChannels.voice ? 'inherit' : '#f0f0f0' }}>
            <CardBody>
                <CardTitle><h3>Select a Number for your Business</h3></CardTitle>
                <p>Select a phone number for your business from the list below. Please note that the chosen number will be reserved for your use, but final allocation is subject to approval. The approval process ensures proper assignment and compliance. Thank you for your understanding.</p>

                {profile && profile.organization.did ? <>
                    <Row className="g-3">
                        <DropdownButton size="lg" variant="primary-lt" title={`${profile.organization.did.number}`} disabled={true}>
                            {Array.isArray(numbers) && numbers
                                .filter(data => data.isReserved === false) // Filter out reserved numbers
                                .map((data, index) => (
                                    <Dropdown.Item key={index} onClick={() => handleNumberSelect(data)}>
                                        {data.number}
                                    </Dropdown.Item>
                                ))}
                        </DropdownButton>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Alert variant="info">
                                <Alert.Heading>Number Reserved!</Alert.Heading>
                                <p>
                                    Your number reservation is pending approval. We'll notify you once it's approved.
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                </> : <>
                    <Row className="g-3">
                        <DropdownButton size="lg" variant="primary-lt" title={selectedNumber ? `${selectedNumber.number}` : "Select a number"} disabled={!selectedChannels.voice}>
                            {Array.isArray(numbers) && numbers.map((data, index) => (
                                <Dropdown.Item key={index} onClick={() => handleNumberSelect(data)}>
                                    {data.number}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Button disabled={!selectedChannels.voice} onClick={handleReserveClick}>Reserve</Button>
                        </Col>
                    </Row>
                    {showWarning && (
                        <Row className="mt-3">
                            <Col>
                                <Alert variant="warning" dismissible>
                                    <Alert.Heading>Please Select a Number!</Alert.Heading>
                                    <p>
                                        Before proceeding, please select a number from the dropdown menu.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </>}


            </CardBody>
        </Card>

        <Card className="mb-3 " style={{ backgroundColor: selectedChannels.sms ? 'inherit' : '#f0f0f0' }}>
            <CardBody>
                <CardTitle>
                    <label className="form-check">
                        <h3 className="form-check-label">Enable SMS</h3>
                    </label>

                </CardTitle>
                <p>Choose a number mask for your SMS communications. A number mask serves as a unique identifier for your messages. Please note that your selection is subject to availability and compliance with relevant regulations. The chosen number mask will be reserved for your use, pending approval.</p>
                <Row>
                    <Col lg="3">
                        <Form.Control size="lg" placeholder="ex: Uniconnect" disabled={!selectedChannels.sms} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button disabled={!selectedChannels.sms}>Reserve</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        <Card className="mb-3" style={{ backgroundColor: selectedChannels.whatsapp ? 'inherit' : '#f0f0f0' }}>
            <CardBody>
                <CardTitle>
                    <label className="form-check">
                        <h3 className="form-check-label">Enable WhatsApp</h3>
                    </label>

                </CardTitle>
                <p>Choose a number mask for your SMS communications. A number mask serves as a unique identifier for your messages. Please note that your selection is subject to availability and compliance with relevant regulations. The chosen number mask will be reserved for your use, pending approval.</p>
                <Row>
                    <Col lg="3">
                        <Form.Control size="lg" placeholder="ex: ABC Company" disabled={!selectedChannels.whatsapp} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button disabled={!selectedChannels.whatsapp}>Reserve</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Page>
}

export default PhoneConfig;