import React, { useContext, useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import UCSelect from '@components/theme/UCSelect';

import AxiosInstance from '@config/axiosInterceptors';

import { ThemeContext } from '@context/ThemeContext';

import QueueForm from '../forms/QueueForm';

const MobileIVRMenu = ({ ivrData, editable }) => {
  
  const { setValue } = useFormContext();
  const { isDarkMode } = useContext(ThemeContext);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedExtensions, setLoadedExtensions] = useState(false);
  const [loadedQueues, setLoadedQueues] = useState(false);
  const [timeout, setTimeout] = useState('');
  const [timeoutForwardType, setTimeoutForwardType] = useState('');
  const [timeoutForwardDN, setTimeoutForwardDN] = useState('');
  const [invalidKeyForwardDN, setInvalidKeyForwardDN] = useState('');
  const combinedOptions = [...extensions, ...queues];

  const FORWARD_TYPES = [
    { value: '1', label: 'End Call', requiresDN: false, forwardType: 'EndCall' },
    { value: '2', label: 'Transfer To User', requiresDN: true, forwardType: 'Extension' },
    { value: '3', label: 'Transfer to Team', requiresDN: true, forwardType: 'Queue' },
    { value: '4', label: 'Voice Mail', requiresDN: true, forwardType: 'VoiceMail' },
    { value: '5', label: 'Repeat Prompt', requiresDN: false, forwardType: 'RepeatPrompt' },
  ];

  useEffect(() => {
    if (ivrData) {
      setSelectedNumbers(ivrData?.Forwards?.map(forward => forward.Input));
      setDestinations(ivrData.Forwards);
      setTimeout(ivrData.Timeout);
      setTimeoutForwardType(ivrData.TimeoutForwardType);
      setTimeoutForwardDN(ivrData.TimeoutForwardDN);
      setInvalidKeyForwardDN(ivrData.InvalidKeyForwardDN);
    }
  }, [ivrData, setValue]);

  const fetchExtensions = async () => {
    setLoading(true);
    try {
      const response = await AxiosInstance.get(`/v1/users/all`);
      const extensions = response.data.map(user => ({
        value: user.extension.toString(),
        label: `(${user.extension}) ${user?.firstName} ${user?.lastName}`
      }));
      setExtensions(extensions);
      setLoadedExtensions(true);
    } catch (error) {
      console.error('Error fetching extensions:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchQueues = async ( forwardNumber = null , forwardDN = null,) => {
    setLoading(true);
    try {
      const response = await AxiosInstance.get(`/v1/3cx/queue`);
      const queues = response?.data?.map(queue => ({
        value: queue.Number.toString(),
        label: `(${queue.Number}) ${queue.Name.split('_').slice(1).join('_')}`
      }));

      setQueues(queues);
      setLoadedQueues(true);
    } catch (error) {
      console.error('Error fetching queues:', error);
    } finally {
      setLoading(false);
      if(forwardNumber && forwardDN){
        handleForwardDNChange(forwardNumber,forwardDN);
      }
    }
  };

  useEffect(() => {
    if (!loadedExtensions) fetchExtensions();
    if (!loadedQueues) fetchQueues();
  }, [loadedExtensions, loadedQueues]);

  const handleDialPress = (value) => {
    if (selectedNumbers?.includes(value)) {
      setSelectedNumbers(selectedNumbers.filter((num) => num !== value));
      setDestinations(destinations.filter(dest => dest.Input !== value.toString()));
    } else {
      setSelectedNumbers([...selectedNumbers, value]);
    }
  };

  const handleActionSelectorChange = (number, selectedOption) => {
    const { forwardType, requiresDN } = selectedOption;
    const destinationIndex = destinations.findIndex(dest => dest.Input === number.toString());
    const newDestination = {
      Input: number.toString(),
      ForwardType: forwardType,
      ...(requiresDN && { ForwardDN: '' })
    };

    if (destinationIndex >= 0) {
      const updatedDestinations = [...destinations];
      updatedDestinations[destinationIndex] = newDestination;
      setDestinations(updatedDestinations);
    } else {
      setDestinations([...destinations, newDestination]);
    }

    if (requiresDN && (forwardType === 'Extension' || forwardType === 'VoiceMail') && !loadedExtensions) {
      fetchExtensions();
    }
    if (requiresDN && forwardType === 'Queue' && !loadedQueues) {
      fetchQueues();
    }
  };

  const handleActionChange = (selectedOption) => {
    const { forwardType, requiresDN } = selectedOption;
    setTimeoutForwardType(forwardType);

    if (requiresDN && (forwardType === 'Extension' || forwardType === 'VoiceMail') && !loadedExtensions) {
      fetchExtensions();
    }
    if (requiresDN && forwardType === 'Queue' && !loadedQueues) {
      fetchQueues();
    }
  };

  const handleForwardDNChange = (number, value) => {
    const destinationIndex = destinations.findIndex(dest => dest.Input === number.toString());
    const updatedDestination = {
      ...destinations[destinationIndex],
      ForwardDN: value
    };

    const updatedDestinations = [...destinations];
    updatedDestinations[destinationIndex] = updatedDestination;
    setDestinations(updatedDestinations);
  };

  const renderMobileNumberPadLayout = () => (
    <div className='row g-2'>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((number) => {
        const isSelected = selectedNumbers?.includes(number.toString());
        return (
          <div className='col-4' key={number}>
            <div
              onClick={editable ? () => handleDialPress(number.toString()) : null}
              className={`avatar ${isDarkMode ? 'text-light' : 'text-dark'} 
              ${isSelected ? 'bg-green' : 'bg-muted-lt'} w-100 pointer`}
            >
              {number}
            </div>
          </div>
        );
      })}
    </div>
  );

  const ActionSelectorWithNumber = () => selectedNumbers.map((number) => {
    const selectedForwardType = FORWARD_TYPES.find(
      option => option.forwardType === destinations.find(dest => dest.Input === number.toString())?.ForwardType
    );
    let forwardDNOptions = [];

    if (selectedForwardType?.forwardType === 'Extension' || selectedForwardType?.forwardType === 'VoiceMail') {
      forwardDNOptions = extensions;
    } else if (selectedForwardType?.forwardType === 'Queue') {
      forwardDNOptions = queues;
    }

    return (
      <div className='mb-4' key={number}>
        <div className='d-flex align-items-center mb-4'>
          <div className='d-flex flex-wrap me-3'>
            <div className={`avatar ${isDarkMode ? 'text-light' : 'text-dark'} bg-muted-lt`}>
              {number}
            </div>
          </div>
          <div className='flex-grow-1'>
            <UCSelect
              options={FORWARD_TYPES}
              value={selectedForwardType}
              onChangeFn={editable ? (selectedOption) => handleActionSelectorChange(number, selectedOption) : null}
              disabled={!editable}
            />
          </div>
        </div>

        {selectedForwardType?.requiresDN && (
          <div className='d-flex align-items-center'>
            <div className='me-3 ' style={{ width: '2.5rem' }}></div>
            <div className='flex-grow-1'>
              {selectedForwardType?.forwardType === 'Queue' && editable ? <h3 className='mb-2'>Select Team</h3> : ""}
              <UCSelect
                options={loading ? [{ value: '', label: 'Loading...' }] : forwardDNOptions}
                value={forwardDNOptions.find(
                  option => option.value === destinations.find(dest => dest.Input === number.toString())?.ForwardDN
                )}
                onChangeFn={editable ? (selectedOption) => handleForwardDNChange(number, selectedOption.value) : null}
                disabled={!editable}
              />
            </div>
          </div>
        )}
        {selectedForwardType?.forwardType === 'Queue' && editable && (
          <div className='d-flex align-items-center'>
            <div className='me-3 ' style={{ width: '2.5rem' }}></div>
            <div className='flex-grow-1'>
              <QueueForm
                fromIvr={true}
                onQueueCreationSuccess={fetchQueues}
                number={number}
                />
            </div>
          </div>)}

      </div>
    );
  });

  const ActionSelector = () => {
    const selectedForwardType = FORWARD_TYPES.find(
      option => option.forwardType === timeoutForwardType
    );

    let forwardTimeoutDNOptions = [];

    if (selectedForwardType?.forwardType === 'Extension' || selectedForwardType?.forwardType === 'VoiceMail') {
      forwardTimeoutDNOptions = extensions;
    } else if (selectedForwardType?.forwardType === 'Queue') {
      forwardTimeoutDNOptions = queues;
    }

    const handleTimeoutForwardDNChange = (selectedOption) => {
      setTimeoutForwardDN(selectedOption.value);
    };

    return (
      <>
        <div className='mb-3'>
          <UCSelect
            label='If input is invalid then'
            options={FORWARD_TYPES}
            value={selectedForwardType}
            onChangeFn={editable ? handleActionChange : null}
            disabled={!editable}
          />
        </div>

        {selectedForwardType?.requiresDN && (
          <div className='mt-3'>
            <UCSelect
              label='Invalid Key Forward DN'
              options={loading ? [{ value: '', label: 'Loading...' }] : forwardTimeoutDNOptions}
              value={forwardTimeoutDNOptions.find(option => option.value === timeoutForwardDN)}
              onChangeFn={editable ? { handleTimeoutForwardDNChange } : null}
              disabled={!editable}
            />
          </div>
        )}
      </>
    );
  };


  useEffect(() => {
    const validatedMenuData = {
      destinations,
      timeout: String(timeout),
      timeoutForwardType,
      timeoutForwardDN: String(timeoutForwardDN),
      invalidKeyForwardDN: String(invalidKeyForwardDN)
    };
    setValue("menuData", validatedMenuData);
  }, [destinations, timeout, timeoutForwardType, timeoutForwardDN, invalidKeyForwardDN, setValue]);


  return (
    <div className='row g-6'>
      <div className='col-12 col-md-6 d-flex flex-column align-items-center'>
        <div className='w-100-xs'>
          <div className='card'>
            <div className='card-body'>
              {renderMobileNumberPadLayout()}
            </div>
          </div>
        </div>
        <div className='w-100'>
          <div className='my-3'>
            <h3>Destination for invalid or no DTMF input</h3>
          </div>
          <div className='mt-3'>
            <label className='form-label'>If no input within seconds:</label>
            <input
              type='text'
              className='form-control'
              name={`noInputWithinSeconds`}
              value={timeout}
              onChange={editable ? (e) => setTimeout(e.target.value) : null}
              disabled={!editable}
            />
          </div>
          <div className='mt-3'>
            <ActionSelector />
          </div>

          <div className='mt-3'>
            <label className='form-label'>If input is invalid then:</label>
            <UCSelect
              options={loading ? [{ value: '', label: 'Loading...' }] : combinedOptions}
              value={combinedOptions.find(option => option.value === invalidKeyForwardDN)}
              onChangeFn={editable ? (selectedOption) => setInvalidKeyForwardDN(selectedOption.value) : null}
              disabled={!editable}
            />
          </div>
        </div>
      </div>
      {selectedNumbers.length ? <div className='col-12 col-md-6'>
        <div className='card mt-3 mt-md-0'>
          <div className='card-body'>
            {ActionSelectorWithNumber()}
          </div>
        </div>
      </div> : ""}

    </div>
  );
};

export default MobileIVRMenu;
