import React, { useState, useEffect } from "react";
import { IconPlus, IconFileImport } from "@tabler/icons-react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Loader } from "../../../components/Loader";
import { getCustomFieldList } from "../../customFields/actions";
import { getContactList, deleteContact, deleteSelectedContacts } from "../actions";
import Page from "../../../layouts/Page";
import ContactCardItem from "../widgets/ContactCard";
import NewContact from "./new";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { openConfirmation } from "../../modals/reducer";
import PageContent from "../../../layouts/PageContent";

function Contacts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedContact, setSelectedContact] = useState(null);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    dispatch(getCustomFieldList());
    dispatch(getContactList({ currentPage, pageSize }));
  }, [currentPage, pageSize, dispatch]);

  const customFieldList = useSelector(
    (state) => state.customFields.customFieldList
  );
  const contactList = useSelector((state) => state.contacts.contactList);

  const handleDeleteContactConfirmation = (contactId) => {
    const confirmDeletion = async () => {
      try {
        const contactDeleteResult = await dispatch(
          deleteContact({ contactId, currentPage, pageSize })
        );
        if (contactDeleteResult.payload && contactDeleteResult.payload.code === 200) {
          toast.success("Contact Deleted successfully!");
        } else {
          toast.error(contactDeleteResult.payload);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setShowNewContactForm(false);
      }
    };
    // Dispatch action
    dispatch(
      openConfirmation({
        confirmationData: {
          title: "Confirmation required",
          content: "Confirm deletion of contact?",
          onConfirm: confirmDeletion,
        },
      })
    );
  };

  const handleBulkDeleteConfirmation = () => {
    setShowConfirmationModal(true);
  };

  const handleBulkDelete = async () => {
    try {
      const contactDeleteResult = await dispatch(
        deleteSelectedContacts({ ids: selectedContactIds, currentPage, pageSize })
      );
      if (contactDeleteResult.payload && contactDeleteResult.payload.code === 200) {
        toast.success("Contacts Deleted successfully!");
      } else {
        toast.error(contactDeleteResult.payload);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowConfirmationModal(false);
      setShowNewContactForm(false);
    }
  };

  const handleSearch = (query) => {
    // dispatch(getContactList(query));
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    const newPage = Math.floor(event.first / event.rows) + 1;
    setCurrentPage(newPage);
    dispatch(getContactList({ currentPage: newPage, pageSize }));
  };

  if (!customFieldList || !contactList) {
    return <Loader />;
  }

  const createButton = (
    <Button
      className="btn btn-primary mt-3 mt-md-auto me-md-3"
      onClick={() => {
        setShowNewContactForm(true);
        setSelectedContact(null);
      }}
    >
      <IconPlus /> New Contact
    </Button>
  );
  const importButton = (
    <Button
      className="btn btn-outline-primary"
      onClick={() => {
        navigate("import", { state: { currentPage, pageSize } });
      }}
    >
      <IconFileImport /> Import Contact
    </Button>
  );

  const handleWhatsapp = (Phone) => {
    console.log(`WhatsApp action ${Phone}`);
  };

  const handleMessage = (Phone) => {
    console.log(`Message action ${Phone}`);
  };

  const handlePhoneCall = (Phone) => {
    console.log(`Phone Call action ${Phone}`);
  };

  const handleViewContact = (contact) => {
    setSelectedContact(contact);
    setShowNewContactForm(true);
  };

  const handleMultiSelect = (selectedIds) => {
    setSelectedContactIds(selectedIds);
  };

  return (
    <Page
      title={"Contacts"}
      subtitle={contactList.total + " Contacts found"}
      buttons={[createButton, importButton,
        <Button
          key="deleteSelected"
          variant="danger"
          disabled={selectedContactIds.length === 0}
          onClick={handleBulkDeleteConfirmation}
        >
          Delete Selected
        </Button>
      ]}
      onSearch={handleSearch}
    >
      <PageContent
        content={
          <div>
            <Row>
              {contactList.rows.map((contact, index) => (
                <Col xs={12} md={6} lg={6} key={index}>
                  <ContactCardItem
                    contact={contact}
                    customFieldList={customFieldList}
                    handleViewContact={handleViewContact}
                    handlePhoneCall={handlePhoneCall}
                    handleMessage={handleMessage}
                    handleWhatsapp={handleWhatsapp}
                    handleMultiSelect={handleMultiSelect} 
                    selectedContactIds={selectedContactIds}
                  />
                  <br/>
                </Col>
              ))}
            </Row>
            {contactList.total > 0 && (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={contactList.total}
                onPageChange={onPageChange}
              />
            )}
          </div>
        }
        subContent={
          <div>
            {showNewContactForm && (
                       <NewContact
                       onCancel={() => setShowNewContactForm(false)}
                       onSave={() => setShowNewContactForm(false)}
                       customFieldList={customFieldList}
                       isViewContact={selectedContact !== null}
                       contactData={selectedContact}
                       onDeleteConfirmation={handleDeleteContactConfirmation}
                       currentPage={currentPage}
                       pageSize={pageSize}
                     />
                   )}
                 </div>
               }
               showSubContent={showNewContactForm}
             ></PageContent>
       
             {/* Modal for confirmation */}
             <Modal
               show={showConfirmationModal}
               onHide={() => setShowConfirmationModal(false)}
               centered
             >
               <Modal.Header closeButton>
                 <Modal.Title>Confirmation required</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                 <p>Confirm deletion of {selectedContactIds.length} contact(s)?</p>
               </Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                   Cancel
                 </Button>
                 <Button variant="danger" onClick={handleBulkDelete}>
                   Delete
                 </Button>
               </Modal.Footer>
             </Modal>
           </Page>
         );
       }
       
       export default Contacts;
       