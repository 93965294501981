import React, { useMemo } from 'react'

import { IconPencilMinus } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next';

const ReviewAndRequestOrgForms = ({ formValues, navigateViaIndex, documentId }) => {
    const { t } = useTranslation();

    const approval = useMemo(() => {
        let approval = { status: '', reason: '' };
        switch (formValues?.approvalStatus) {
            case 'rejected':
                approval.status = t('form_headers.org_rejected');
                approval.reason = formValues?.reason;
                break;
            default:
                approval.status = t('form_headers.org_pending');
                approval.reason = t('form_helpers.org_pending');
        }
        return approval;
    }, [formValues]);

    return (
        <div>
            {formValues?.approvalStatus || documentId ? <>
                <div className="datagrid-title">{t('form_headers.org_approval')}</div>
                <div className='mb-4'>
                    <div>
                        {approval.status &&
                            <h2 className={`${formValues?.approvalStatus === 'rejected' ? 'approval-error' : ''} font-weight-bold mb-1`}>
                                {approval.status}
                            </h2>}
                    </div>
                    <div>
                        {approval.reason &&
                            <div className={`${formValues?.approvalStatus === 'rejected' ? 'approval-error' : ''}`}>
                                {approval.reason}
                            </div>}
                    </div>
                </div>
            </> : null}
            <div className='d-flex flex-row separator'>
                <h4 className="mb-3">{t('form_headers.org_details')}</h4>
                <span className='px-2 pointing' onClick={() => navigateViaIndex(0)}>
                    <IconPencilMinus size={16} />
                </span>
            </div>
            <div className='row py-4'>
                <div className='col-lg-3 mb-3'>
                    <div className="datagrid-title">{t('form_fields.organization_name.label')}</div>
                    <div className="datagrid-content">{formValues?.organizationName}</div>
                </div>
                <div className='col-lg-3 mb-3'>
                    <div className="datagrid-title">{t('form_fields.organization_type.label')}</div>
                    <div className="datagrid-content">{formValues?.type?.label ?? formValues?.type}</div>
                </div>
                <div className='col-lg-3 mb-3'>
                    <div className="datagrid-title">{t('form_fields.business_reg_number.label')}</div>
                    <div className="datagrid-content">{formValues?.brNumber}</div>
                </div>
                <div className='col-lg-3'>
                    <div className="datagrid-title">{t('form_fields.org_address.label')}</div>
                    <div className="datagrid-content">
                        {`${formValues?.address}, ${formValues?.city}, ${formValues?.country?.label ?? formValues?.country}, ${formValues?.postalCode}`}
                    </div>
                </div>
            </div>

            <div className='d-flex flex-row separator'>
                <h4 className="mb-3">{t('form_headers.org_number_and_mask')}</h4>
                <span className='px-2 pointing' onClick={() => navigateViaIndex(1)}>
                    <IconPencilMinus size={16} />
                </span>
            </div>
            <div className='row py-4'>
                <div className='col-lg-3 mb-3'>
                    <div className="datagrid-title">{t('form_headers.selected_number')}</div>
                    <div className="datagrid-content">{formValues?.number}</div>
                </div>
                {formValues?.smsEnable ? <div className='col-lg-3'>
                    <div className="datagrid-title">{t('form_fields.sms_mask.label')}</div>
                    <div className="datagrid-content">{formValues?.smsMask}</div>
                </div> : null}
            </div>
        </div>
    )
}

export default ReviewAndRequestOrgForms