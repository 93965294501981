import React from "react";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { Loader } from "../../../components/Loader";
import { Link } from "react-router-dom";

const dataTypes = [
  { value: "string", label: "Text" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "Yes/No" },
  { value: "date", label: "Date" },
];

function findLabelForDataType(dataType) {
  const dataTypeObject = dataTypes.find((dt) => dt.value === dataType);
  return dataTypeObject ? dataTypeObject.label : dataType;
}

function renderIconLink(onClickHandler, icon, className, disabled = false) {
  const iconElement = disabled ? (
    <div className={`icon disabled`} style={{ opacity: 0.5 }}>
      {icon}
    </div>
  ) : (
    <Link onClick={onClickHandler} className={`icon ${className}`}>
      {icon}
    </Link>
  );

  return <div className="col-auto mx-1">{iconElement}</div>;
}

function CustomFieldList({
  customFieldList,
  onEdit,
  handleDeleteConfirmation,
  setShowNewCustomField,
}) {
  if (!customFieldList) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="page page-center">
      <div className="col-12">
        <div className="card">
          <div className="table-responsive">
            <table className="table table-vcenter card-table table-striped">
              <thead>
                <tr>
                  <th>Display Name</th>
                  <th>Field Name</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customFieldList? customFieldList.rows.map((customFields) => (
                      <tr key={customFields.id}>
                        <td>{customFields.label}</td>
                        <td>{customFields.name}</td>
                        <td>{findLabelForDataType(customFields.dataType)}</td>
                        <td className="w-1">
                          <div className="d-flex align-items-center">
                            {renderIconLink(
                              () => onEdit(customFields),
                              <IconEdit />,
                              "",
                              !customFields.isEditable
                            )}
                            {renderIconLink(
                              () => {
                                handleDeleteConfirmation(customFields);
                                setShowNewCustomField(false);
                              },
                              <IconTrash />,
                              "text-danger",
                              !customFields.isEditable
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomFieldList;
