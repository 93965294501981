import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Page from "../../../layouts/Page";
import SMSList from "../views/list";
import SMSForm from "../views/create";
import { IconPlus } from "@tabler/icons-react";

export default function SMSTemplate() {
    const [showNewTemplate, setShowNewTemplate] = useState(false);
    const [editTemplate, setEditTemplate] = useState(null);

    const createButton = (
        <button
            className="btn btn-primary d-none d-sm-inline-block"
            onClick={() => {
                setShowNewTemplate(true);
                setEditTemplate(null);
            }}
        >
            <IconPlus /> New Template
        </button>
    );

    const handleEditTemplate = (template) => {
        console.log("template",template);
        setShowNewTemplate(true);
        setEditTemplate(template);
    };

    const handleFormSubmit = () => {
        setShowNewTemplate(false); 
        setEditTemplate(null);
    };

    const handleFormCancel = () => {
        setShowNewTemplate(false);
        setEditTemplate(null);
    };

    return (
        <Page title={"SMS Templates"} buttons={[createButton]}>
            <Row>
                <Col lg={8}>
                    <SMSList onEdit={handleEditTemplate} />
                </Col>
                <Col lg={4}>
                    {showNewTemplate && (
                        <SMSForm template={editTemplate} onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
                    )}
                </Col>
            </Row>
        </Page>
    );
}
