import { Route, Routes } from "react-router-dom";

import Campaigns from "@modules/campaigns/views";
import NewCampaign from "@modules/campaigns/views/new";
import Configurations from "@modules/config/views";
import OrganizationUpdate from "@modules/config/views/OrganizationUpdate";
import Contacts from "@modules/contacts/views";
import ImportContact from "@modules/contacts/views/import";
import CustomFields from "@modules/customFields/views/customFields";
import Dnc from "@modules/dnc/views/dnc";
import FirstLook from "@modules/firstlook/views";
import Inbox from "@modules/inbox/views";
// import ChangePassword from "@modules/profile/views/changePassword";
import UserProfile from "@modules/profile/views/userProfile";
import AgentPerformanceReport from "@modules/reports/views/agentPerformance";
import CallDetailReport from "@modules/reports/views/callDetails";
import CallDistributionReport from "@modules/reports/views/callDistribution";
import ChangePassword from "@modules/shared/views/ChangePassword";
import ProfileView from "@modules/shared/views/ProfileView";
import SMSTemplates from "@modules/sms/views/templates";
import Users from "@modules/users/views";
import Profile from "@modules/users/views/Profile";
import UpdateDetails from "@modules/users/views/UpdateDetails";
import UserList from "@modules/users/views/UserList";
import VoiceView from "@modules/voice/views/index";
import AddVoice from "@modules/voice/views/templates";
import WhatsappTemplates from "@modules/whatsapp/views/templates";
import Queues from "@modules/queues/views/index"

import { AppPaths, SharedPaths } from "./paths";


const AppRoutes = () => {
    return (
        <Routes>
            <Route path={AppPaths.CAMPAIGN_NEW} element={<NewCampaign />} />
            <Route path={AppPaths.CAMPAIGNS} element={<Campaigns />} />
            <Route path={AppPaths.INBOX} element={<Inbox />} />
            <Route path={AppPaths.CONFIG_CHANNELS} element={<Configurations />} />
            <Route path={AppPaths.CONFIG_CUSTOM_FIELDS} element={<CustomFields />} />
            <Route path={AppPaths.TEMPLATES_VOICE} element={<VoiceView />} />
            <Route path={AppPaths.TEMPLATES_ADD_VOICE} element={<AddVoice />} />
            <Route path={AppPaths.TEMPLATES_WHATSAPP} element={<WhatsappTemplates />} />
            <Route path={AppPaths.TEMPLATES_SMS} element={<SMSTemplates />} />
            <Route path={AppPaths.CALL_DETAIL_REPORT} element={<CallDetailReport />} />
            <Route path={AppPaths.AGENT_PERFORMANCE_REPORT} element={<AgentPerformanceReport />} />
            <Route path={AppPaths.CALL_DISTRIBUTION_REPORT} element={<CallDistributionReport />} />
            <Route path={AppPaths.USER_LIST} element={<UserList />} />
            <Route path={AppPaths.USER_PROFILE} element={<Profile />} />
            <Route path={AppPaths.UPDATE_USER_PROFILE} element={<UpdateDetails />} />
            <Route path={AppPaths.USERS} element={<Users />} />
            {/* <Route path={AppPaths.CHANGE_PASSWORD} element={<ChangePassword />} /> */}
            <Route path={AppPaths.CONFIG_DNC} element={<Dnc />} />
            <Route path={AppPaths.PROFILE} element={<UserProfile />} />
            <Route path={AppPaths.CRM_IMPORT} element={<ImportContact />} />
            <Route path={AppPaths.CRM} element={<Contacts />} />
            <Route path={AppPaths.ROUTING_OPTIONS} element={<FirstLook fromDashboard={true} />} />
            <Route path={AppPaths.ORGANIZATION_UPDATE} element={<OrganizationUpdate fromDashboard={true} />} />
            <Route path={SharedPaths.CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={SharedPaths.PROFILE} element={<ProfileView />} />
            <Route path={AppPaths.QUEUE_MANAGEMENT} element={<Queues/>}/>
        </Routes>
    );
};

export default AppRoutes;