import { IconCaretDown, IconCaretRight } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavItem = ({ to, icon, title, children, onClick }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();
    const toggleDropdown = () => {
        if (children) {
            setIsDropdownOpen(!isDropdownOpen);
        } else if (typeof onClick === "function") {
            onClick();
        }
    };

    return (
        <li className={`nav-item`}>
            <Link
                className={`nav-link ${location.pathname === to ? 'bg-primary-lt' : ''}`}
                to={to}
                onClick={toggleDropdown}
            >
                <span className={`nav-link-icon d-md-none d-lg-inline-block ${location.pathname === to ? 'text-primary' : ''}`}>
                    {icon}
                </span>
                <span className="nav-link-title">
                    {title}
                </span>
                {
                    children ? <span className='text-muted ms-auto'>{isDropdownOpen ? <IconCaretDown /> : <IconCaretRight />}</span> : ""
                }
            </Link>
            {children && isDropdownOpen ? <div className={`a-dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                {children}
            </div> : ''}
        </li>
    );
};

export default NavItem