import React, { useMemo } from 'react'

import { IconUser, IconUsersGroup, IconSubtask, IconPhoneCall, IconCheck, IconX } from '@tabler/icons-react'

import UCPlaceholder from './theme/UCPlaceholder'

const ConnectionState = ({
    phoneNumber,
    connectionStatus,
    connectionStatusMessage,
    title,
    description,
    uiType = 'user' | 'team' | 'ivr'
}) => {
    const getIcon = useMemo(() => {
        switch (uiType) {
            case 'user':
                return <IconUser size={28} />
            case 'team':
                return <IconUsersGroup size={28} />
            case 'ivr':
                return <IconSubtask size={28} />
            default:
                return null
        }
    }, [uiType])

    const renderPhoneNumber = () => {
        return (
            <div className='col-12 col-md-4 d-flex align-items-center justify-content-md-center'>
                {
                    phoneNumber ? (
                        <h3 className='mb-0'>
                            {phoneNumber}
                        </h3>
                    ) :
                        (
                            <UCPlaceholder width={200} height={40} />
                        )
                }
            </div>
        )
    }

    const renderConnectionStatus = () => {
        return (
            <div className='col-12 col-md-4'>
                <div className='cs-status-container'>
                    <IconPhoneCall size={28} />
                    <div className='cs-status-line' />
                    <div className={`cs-status-dot ${connectionStatus ? 'completed' : ''}`}>
                        {connectionStatus ?
                            <IconCheck color="#fff" size={16} stroke={3} /> :
                            <IconX color="#fff" size={16} stroke={3} />}
                    </div>
                    {getIcon}
                </div>
            </div >
        )
    }

    const renderRightSideElement = () => {
        return (
            <div className='col-12 col-md-4 d-flex align-items-center'>
                <div className='ms-md-2'>
                    <h4 className='mb-0'>{title}</h4>
                    <p className="text-secondary mb-0">{description}</p>
                </div>
            </div>
        )
    }

    const getUserUI = () => {
        return (
            <div className='row g-4 mh-status'>
                {renderPhoneNumber()}
                {renderConnectionStatus()}
                {renderRightSideElement()}
            </div>
        )
    }

    const getTeamUI = () => {
        return (
            <div className='row g-4 mh-status'>
                {renderPhoneNumber()}
                {renderConnectionStatus()}
                {renderRightSideElement()}
            </div>
        )
    }

    const getIVRUI = () => {
        return (
            <div>
                {renderConnectionStatus()}
            </div>
        )
    }

    const getUIByType = () => {
        switch (uiType) {
            case 'user':
                return getUserUI()
            case 'team':
                return getTeamUI()
            case 'ivr':
                return getIVRUI()
            default:
                return null
        }
    }

    return (
        <div className='mt-md-0 mt-4'>
            {getUIByType()}
        </div>
    )
}

export default ConnectionState