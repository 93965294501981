import { createAsyncThunk } from "@reduxjs/toolkit";

import { startLoader, endLoader } from "@components/reducers/loading";
import { showToast } from "@components/reducers/toast";

import AxiosInstance from "@config/axiosInterceptors";
import { encrypt } from "@config/encryption";

import {
  requestStarted, requestFailed, loginSuccess, clearLogin, signupSuccess,
  organizationSetupSuccess, verifyUserSuccess, updateUserSuccess
} from "./reducer";

import { getUserProfile } from "../profile/actions";
import { clearProfile } from "../profile/reducer";

export const userLogin = createAsyncThunk(
  "auth/login",
  async (credentials, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.post("v1/users/login", credentials);
      dispatch(loginSuccess(response.data));
      const temp = {
        token: encrypt(response.data.token),
        refreshToken: encrypt(response.data.refreshToken),
      };
      localStorage.setItem("auth", JSON.stringify(temp));
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(clearProfile());
    dispatch(clearLogin());
    localStorage.removeItem("auth");
  }
);

export const userSignup = createAsyncThunk(
  "auth/userSignup",
  async (userData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.post("v1/users/signup", userData);
      dispatch(signupSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const setupOrganization = createAsyncThunk(
  "auth/setupOrganization",
  async (organizationData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    dispatch(startLoader());
    try {
      const response = await AxiosInstance.post("v1/organizations/create", organizationData);
      dispatch(organizationSetupSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    } finally {
      dispatch(endLoader());
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "auth/updateOrganization",
  async (organizationData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    dispatch(startLoader());
    try {
      const response = await AxiosInstance.put("v1/organizations/update", organizationData);
      dispatch(organizationSetupSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    } finally {
      dispatch(endLoader());
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (otpData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.post("v1/users/verify-otp", otpData);
      if (otpData.verifyMode !== 1) {
        dispatch(verifyUserSuccess(response.data));
      } else {
        dispatch(requestFailed(null));
      }
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "auth/fileUpload",
  async (file, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    dispatch(startLoader());
    try {
      const response = await AxiosInstance.post("v1/upload", file);
      dispatch(requestStarted(response.data));
      return response.data;
    } catch (error) {
      dispatch(requestFailed(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    } finally {
      dispatch(endLoader());
    }
  }
);

export const acceptInvite = createAsyncThunk(
  "auth/acceptInvite",
  async ({ verificationCode }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoader())
      const response = await AxiosInstance.post("v1/users/accept", { verificationCode });
      return response.data;
    } catch (error) {
      dispatch(showToast({
        message: error.response.data.message,
        autoClose: 4000,
        type: 'error'
      }))
      return rejectWithValue(error.response.data.message);
    } finally {
      dispatch(endLoader());
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (userData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.put(`v1/users/update/${userData.userId}`, userData);
      dispatch(updateUserSuccess(response.data));
      return { success: true, result: response.data };
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const getOrgProfile = createAsyncThunk(
  "auth/getOrgProfile",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`v1/organizations/org-profile`);
      dispatch(organizationSetupSuccess(response.data));
      dispatch(endLoader());
      return response.data;
    } catch (error) {
      // dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);