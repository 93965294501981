import React, { useRef, useEffect } from 'react';

const Loader = ({ maxLoaderSize = 25, borderWidth = 5, color = '#3498db' }) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    const parentSize = loaderRef.current.parentNode.clientWidth;
    const loaderSize =  0.9 * Math.min(parentSize, maxLoaderSize);

    const spinnerStyle = {
      border: `${borderWidth}px solid #f3f3f3`,
      borderTop: `${borderWidth}px solid ${color}`,
      borderRadius: '50%',
      width: `${loaderSize}px`,
      height: `${loaderSize}px`,
      animation: 'spin 1.5s linear infinite',
    };

    const keyframesStyle = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;

    if (loaderRef.current) {
      loaderRef.current.innerHTML = ''; // Clear existing content

      // Create style element for keyframes
      const styleElement = document.createElement('style');
      styleElement.appendChild(document.createTextNode(keyframesStyle));
      document.head.appendChild(styleElement);

      // Create spinner div
      const spinnerDiv = document.createElement('div');
      Object.assign(spinnerDiv.style, spinnerStyle);
      loaderRef.current.appendChild(spinnerDiv);
    }
  }, [maxLoaderSize, borderWidth, color]);

  return (
    <div ref={loaderRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '0 10px' }}></div>
  );
};

export { Loader };
