import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import Page from "../../../layouts/Page";

import { showModal } from "@components/reducers/modal";
import { showToast } from "@components/reducers/toast";

import { isMobileScreen } from "@utils/utils";

import { openConfirmation } from "@modules/modals/reducer";

import InviteAgent from './InviteAgent';
import UpdateAgent from './UpdateAgent';
import UserList from "./UserList";

import { reInvite, deleteUser } from '../actions';


export default function Users() {
    const dispatch = useDispatch();
    const [showCard, setShowCard] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [currentComponent, setCurrentComponent] = useState(null);
    const mobileScreen = isMobileScreen();

    const handleInviteAgentClick = () => {
        if (mobileScreen) {
            dispatch(showModal({ modalContentKey: 'invite-user-modal' }))
        } else {
            setCurrentComponent('InviteAgent');
            setShowCard(true);
        }
    };

    const handleDeleteClick = (user, currentPage) => {
        const confirmDeletion = async () => {
            try {
                const userDeleteResult = await dispatch(
                    deleteUser({ id: user.id, page: currentPage })
                );
                if (userDeleteResult?.payload?.status === 200) {
                    dispatch(showToast({ message: "User deleted successfully!", type: "success" }));
                } else {
                    dispatch(showToast({ message: "User deletion failed!", type: "error" }));
                }
            } catch (error) {
                dispatch(showToast({ message: "User deletion failed!", type: "error" }));
            }
        };
        // Dispatch action
        dispatch(
            openConfirmation({
                confirmationData: {
                    title: "Confirmation required",
                    content: "Confirm deletion of User?",
                    onConfirm: confirmDeletion,
                },
            })
        );
    };


    const handleReinviteClick = async (user) => {
        try {
            const reInviteData = { "email": user.email, "role": user.role };
            const reInviteResult = await dispatch(reInvite(reInviteData));

            if (reInviteResult.payload?.result?.code === 200) {
                dispatch(showToast({ message: "Invite Send Successfully!", type: "success" }));
            } else {
                dispatch(showToast({ message: "Invite Send Failed!", type: "error" }));
            }
        } catch (error) {
            dispatch(showToast({ message: "Invite Send Failed!", type: "error" }));
        }
    };

    const handleCancelClick = () => {
        setSelectedUser(null);
        setShowCard(false);
        setCurrentComponent(null);
    };

    const handleEditClick = (user, currentPage) => {
        if (mobileScreen) {
            dispatch(showModal({
                modalContentKey: 'update-user-modal', data: {
                    user: user,
                    currentPage: currentPage,
                }
            }))
        } else {
            setSelectedUser(user);
            setCurrentPage(currentPage);
            setCurrentComponent('UpdateAgent');
            setShowCard(true);
        }
    };

    const createButton = (
        <Button
            className="btn btn-primary mt-3 mt-md-auto"
            onClick={handleInviteAgentClick}
        >
            Invite Agent
        </Button>
    );

    return (
        <Page title="Users" buttons={[createButton]}>
            <div className="row">
                <div className={`${mobileScreen ? "col-12" : showCard ? "col-lg-8" : "col-12"}`}>
                    <UserList
                        onEditClick={handleEditClick}
                        onReinviteClick={handleReinviteClick}
                        onDeleteClick={handleDeleteClick}
                    />
                </div>
                {showCard && !mobileScreen && (
                    <div className="col-lg-4">
                        {currentComponent === 'InviteAgent' && <InviteAgent onFinish={handleCancelClick} />}
                        {currentComponent === 'UpdateAgent' && <UpdateAgent user={selectedUser} currentPage={currentPage} onFinish={handleCancelClick} />}
                    </div>
                )}
            </div>
        </Page>
    );
}
