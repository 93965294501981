import { createSlice } from "@reduxjs/toolkit";

const numberSlice = createSlice({
    name: "number",
    initialState: {
        numbers: null,
        loading: false,
        error: null,
    },
    reducers: {
        numberList: (state, action) => {
            state.loading = false;
            state.numbers = action.payload;
        },
        reserve: (state, action) => {
            state.loading = true;
        },
    }
});

export const {
    numberList,
    reserve
} = numberSlice.actions;
export default numberSlice.reducer;