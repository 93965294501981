import { createAsyncThunk } from '@reduxjs/toolkit';

import {  getProfile, getProfileSuccess, getProfileError, updateProfile, updateProfileSuccess, updateProfileError } from './reducer';

import AxiosInstance from '../../config/axiosInterceptors';

export const getUserProfile = createAsyncThunk('profile/getUserProfile', async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(getProfile());
      const response = await AxiosInstance.get('v1/users/me');
      dispatch(getProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getProfileError(error.message));
      return rejectWithValue(error.message);
    }
  });
export const updateUserProfile = createAsyncThunk('profile/updateUserProfile', async ({ id, ...updatedUser}, { dispatch, rejectWithValue }) => {
    try {
      dispatch(updateProfile());
      const response = await AxiosInstance.put(`v1/users/update-details/${id}`, updatedUser);
      dispatch(updateProfileSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(updateProfileError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });