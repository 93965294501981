import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');

    const toggleTheme = () => {
        const newTheme = isDarkMode ? 'light' : 'dark';
        document.body.setAttribute('data-bs-theme', newTheme);
        setIsDarkMode(!isDarkMode);
    };

    useEffect(() => {
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme') || 'light';
        document.body.setAttribute('data-bs-theme', storedTheme);
    }, []);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};