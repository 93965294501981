import React, { useEffect, useState } from 'react';

import { IconUser } from '@tabler/icons-react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

import AuthorizedSections from '@components/AuthorizedSections';
import { endLoader, startLoader } from '@components/reducers/loading';

import { OWNER_ACCESS } from '@config/constants';

import { getuserList } from '../actions';

export default function UserList({ onEditClick, onDeleteClick, onReinviteClick }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const usersData = useSelector((state) => state.users.userList);
    // currentPage changes
    useEffect(() => {
        dispatch(startLoader());
        dispatch(getuserList(currentPage))
            .finally(() => {
                dispatch(endLoader());
            });
    }, [dispatch, currentPage]);

    // update totalPages
    useEffect(() => {
        if (usersData && usersData.totalPages) {
            setTotalPages(usersData.totalPages);
        }
    }, [usersData]);

    const users = usersData ? usersData.rows || [] : [];

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    if (!usersData) return null;

    return (
        <div className="col-12">
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-vcenter table-mobile-md card-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Extension</th>
                                <th>Mobile Number</th>
                                <th>Status</th>
                                <th>Role</th>
                                <th className="w-1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>
                                        <div className="d-flex py-1 align-items-center">
                                            <span className="avatar me-2"><IconUser size={20} /></span>
                                            <div className="flex-fill">
                                                <div className="font-weight-medium">{user.firstName} {user.lastName}</div>
                                                <div className="text-muted">{user.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{user.extension}</td>
                                    <td className="text-muted">{user.phone}</td>
                                    <td>{user.isVerified ? 'Verified' : 'Unverified'}</td>
                                    <td>{t(`user_roles.${user.role}`)}</td>
                                    <td>
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle align-text-top" data-bs-boundary="viewport"
                                                data-bs-toggle="dropdown">Actions</button>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <button className="dropdown-item" onClick={() => onEditClick(user, currentPage)}>
                                                    Edit
                                                </button>
                                                {user.role !== 'owner' && !user.inviteAccepted && (
                                                    <button className="dropdown-item" onClick={() => onReinviteClick(user)}>
                                                        Re-Invite
                                                    </button>
                                                )}
                                                <AuthorizedSections grantedMinimumLevels={OWNER_ACCESS}>
                                                    {user.role !== 'owner' ? <button className="dropdown-item text-danger" onClick={() => onDeleteClick(user, currentPage)}>
                                                        Delete
                                                    </button> : null}
                                                </AuthorizedSections>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Pagination */}
            <div className="m-3 d-flex align-items-center">
                <p className="m-0 text-muted">
                    Showing <span>{(currentPage - 1) * usersData.pageSize + 1}</span> to{' '}
                    <span>{Math.min(currentPage * usersData.pageSize, usersData.total)}</span> of{' '}
                    <span>{usersData.total}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Prev
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageClick(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </div>
            {/* End of pagination */}
        </div>
    );
}
