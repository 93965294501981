import React from 'react';

import { Modal } from 'react-bootstrap';

import useLanguage from '@hooks/useLanguage';


const LanguageModal = () => {
    const { language, lngs, handleLanguageChange } = useLanguage();

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select the Language</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <div className="btn-group">
                                {lngs.map((lng) => (
                                    <button
                                        key={lng.value}
                                        type="button"
                                        className={`btn btn-outline-primary ${language?.value === lng.value ? 'active' : ''}`}
                                        onClick={() => handleLanguageChange(lng)}
                                    >
                                        {lng.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
};

export default LanguageModal;
