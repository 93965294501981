import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Page from "../../../layouts/Page";

import ButtonWithLoader from "@components/ButtonWithLoader";
import UCSelect from "@components/theme/UCSelect";

import CallDistributionReport from "./callDistributionReport";

import moment from "moment";

import { getCallDIstributionData } from "../actions";

const CALL_INTERVALS = [
    { value: "h", label: "Hourly" },
    { value: "d", label: "Daily" },
    { value: "m", label: "Monthly" }
];

export default function Reports() {
    const [intervalType, setIntervalType] = useState(CALL_INTERVALS[0].value);
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.reports.loading);

    const handleGetCallDistributionReport = async () => {
        const formattedDate = moment(date).format("MMMM DD, YYYY");
        try {
            const result = await dispatch(getCallDIstributionData({ intervalType, date: formattedDate }));
            setData(result?.payload?.data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleChangeCallInterval = (selectedInterval) => {
        setIntervalType(selectedInterval.value);
    };

    const getLabel = (intervalType) => {
        switch (intervalType) {
            case "daily": return "Month";
            case "monthly": return "year";
            default: return "Date";
        }
    }

    return (
        <Page title="Call Distribution Report">
            <div className="row">
                <div className={"col-3"}>
                    <div className="card">
                        <div className="card-body">
                            {/* generate report section */}
                            <div className="mb-3">
                                <label className="form-label">Interval</label>
                                <UCSelect
                                    options={CALL_INTERVALS}
                                    value={CALL_INTERVALS.find(option => option.value === intervalType)}
                                    onChangeFn={handleChangeCallInterval}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">{getLabel(intervalType)}</label>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    maxDate={new Date()}
                                    dateFormat={
                                        intervalType === "h"
                                            ? "yyyy-MM-dd"
                                            : intervalType === "d"
                                                ? "yyyy-MM"
                                                : "yyyy"
                                    }
                                    className="form-control"
                                    showYearPicker={intervalType === "m"}
                                    showMonthYearPicker={intervalType === "d"}
                                />
                            </div>
                            <div className="text-end">
                                <ButtonWithLoader
                                    className="btn btn-primary"
                                    loading={loading}
                                    onClick={() => handleGetCallDistributionReport()}
                                >
                                    Generate Report
                                </ButtonWithLoader>
                            </div>
                        </div>
                    </div>
                </div>
                {data && data.data && data.data.length > 0 && (
                    <div className="col-9">
                        <CallDistributionReport data={data.data} intervalType={data.intervalType}/>
                    </div>
                )}
            </div>
        </Page>
    );
}
