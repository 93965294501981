import React, {  } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { showToast } from "@components/reducers/toast";

import { setNewPassword } from "@modules/users/actions";

import ResetPasswordForm from "../forms/ResetPasswordForm";

function ResetPassword() {
    const loading = useSelector(state => state.users.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const location = useLocation();
    const { token } = location.state

    const handlePasswordReset = (data) => {
        const pass = {
            password: data?.password,
            token: token,
        };

        dispatch(setNewPassword(pass)).then((response) => {
            if (response.payload && response.payload.code === 200) {
                dispatch(showToast({
                    message: response.payload.message,
                    type: "success",
                    autoClose: 4000
                }));
                navigate("/auth/login", { replace: true });
            } else {
                dispatch(showToast({
                    message: response.payload.message,
                    type: "error",
                    autoClose: 4000
                }));
            }
        })
            .catch((error) => {
                dispatch(showToast({
                    message: 'Password reset failed',
                    type: "error",
                    autoClose: 4000
                }));
            });

    }

    return (
        <AuthPageRootLayout containerClassName="container-tight">
            <div className="card card-md">
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">
                        {t("form_headers.reset_password")}
                    </h2>
                    <ResetPasswordForm
                        handleResetPassword={handlePasswordReset}
                        loading={loading}
                    />
                </div>
            </div>
        </AuthPageRootLayout>
    );
}
export default ResetPassword;
