import React, { useState } from 'react';

import moment from 'moment';
import 'moment-duration-format';


import { IconDownload } from '@tabler/icons-react';
import { CSVLink } from 'react-csv';

const CallDetailReport = ({ data, baseUrl }) => {

    const pageSize = 10; // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / pageSize);
    const paginationLimit = 5;

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const renderStatusIcon = (answered) => {
        if (answered) {
            return <span style={{ color: 'green' }}>✓</span>; // Green checkmark
        } else {
            return <span style={{ color: 'red' }}>✗</span>; // Red close mark
        }
    };

    const tableCellStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    };

    const formatDuration = (duration) => {
        const momentDuration = moment.duration(duration);
        return momentDuration.format("HH:mm:ss", { trim: false });
    };


    const renderData = () => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, data.length);

        return data.slice(startIndex, endIndex).map((call) => (
            <tr key={call.CallId}>
                <td style={tableCellStyle}>{renderStatusIcon(call.Answered)} {call.CallId}</td>
                <td style={tableCellStyle}>{moment(call.StartTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td style={tableCellStyle}>{call.callType}</td>
                <td style={tableCellStyle}>{call.SourceDn}</td>
                <td style={tableCellStyle}>{call.DestinationDn}</td>
                {/* <td tyle={tableCellStyle}>
                    <div className="d-flex py-1 align-items-center">
                        <div className="flex-fill">
                            <div className="font-weight-medium">{call.agentName}</div>
                            <div className="text-muted">{call.agentExtension}</div>
                        </div>
                    </div>
                </td> */}
                {/* <td style={tableCellStyle} className="text-muted">{call.agentNameExtension}</td> */}
                {/* <td style={tableCellStyle}>{call.group}</td> */}
                <td style={tableCellStyle}>{formatDuration(call.TalkingDuration)}</td>
                <td style={tableCellStyle}>{call.Reason}</td>
                <td style={tableCellStyle}>
                    {call.RecordingUrl ? (
                        <audio controls>
                            <source src={`${baseUrl}${encodeURI(call.RecordingUrl)}`} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    ) : (
                        'Recording not found'
                    )}
                </td>
                {/* <td style={tableCellStyle}>{`${baseUrl}${call.RecordingUrl}`}</td> */}
            </tr>
        ));
    };

    const getPaginationItems = () => {
        const pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(paginationLimit / 2));
        let endPage = Math.min(totalPages, startPage + paginationLimit - 1);

        if (endPage - startPage + 1 < paginationLimit) {
            startPage = Math.max(1, endPage - paginationLimit + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages;
    };

    // CSV data
    const csvData = data.map(call => ({
        CallId: call.CallId,
        CallTime: call.CallTime,
        CallType: call.callType,
        CustomerNumber: call.customerNumber,
        AgentName: call.agentName,
        AgentExtension: call.agentExtension,
        TalkingTime: call.talkingTime,
        Reason: call.Reason,
        RecordingUrl: call.RecordingUrl
    }));

    return (
        <div className="col-12">
            <div className="card">

                {/* <div className="text-end mb-3">
                        <CSVLink 
                        data={csvData} 
                        filename={"call_detail_report.csv"} 
                        className="btn btn-link" style={{ textDecoration: 'none' }}>
                            <IconDownload /><span style={{ fontWeight: 'bold' }}>Download</span>
                        </CSVLink>
                    </div> */}

                <div className="table-responsive">
                    <table className="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Call ID</th>
                                <th>Start Time</th>
                                <th>Call Type</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Talking Duration</th>
                                <th>Reason</th>
                                <th>Call Record</th>
                                {/* <th>URL</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {renderData()}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="m-3 d-flex align-items-center">
                <p className="m-0 text-muted">
                    Showing <span>{(currentPage - 1) * pageSize + 1}</span> to{' '}
                    <span>{Math.min(currentPage * pageSize, data.length)}</span> of{' '}
                    <span>{data.length}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Prev
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageClick(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </div> */}
            <div className="m-3 d-flex align-items-center">
                <p className="m-0 text-muted">
                    Showing <span>{(currentPage - 1) * pageSize + 1}</span> to{' '}
                    <span>{Math.min(currentPage * pageSize, data.length)}</span> of{' '}
                    <span>{data.length}</span> entries
                </p>
                <ul className="pagination m-0 ms-auto">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                            Prev
                        </button>
                    </li>
                    {getPaginationItems().map((page) => (
                        <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageClick(page)}>
                                {page}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                            Next
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CallDetailReport;
