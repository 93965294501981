import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {toast} from 'react-toastify';

import { requestFailed } from "@modules/auth/reducer";

import { updateDetails } from "../actions";


function UpdateDetails({user, onCancel}) {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const errorUpdateUser = useSelector(state => state.auth.errorUpdateUser);
    
    const userId = user?.user?.id || user?.id;
    const [formData, setFormData] = useState({
        userId: userId,
        firstName: "",
        lastName: "",
        phone: "",
        enabled:true
    });

    useEffect(() => {
        if (user) {
            setFormData({
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.phone?user.phone: null ,
                email: user.email,
                enabled: user.enabled,
            });
        }
    }, [user]);


    const handleCancel = () => {
        onCancel();
      };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;

        if ((name === "firstName" || name === "lastName") && !/^[a-zA-Z\s]*$/.test(value)) {
            dispatch(requestFailed("Special characters and numbers are not allowed for name"));
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "enabled" ? checked : value,
        }));
        dispatch(requestFailed(null));
    };


    const handleSignUpClick = async (e) => {

        e.preventDefault();
        if (!formData.firstName || !formData.lastName || !formData.phone) {
            dispatch(requestFailed("Please fill all fields."));
            return;
        }

        try {
            const res = await dispatch(updateDetails(formData));
            if (res.payload.status ===  200) {
                toast.success("User Details Updated!")
            } else {
                toast.error("User Details Update Failed!")
                console.error("Update failed:", res.error);
            }
        } catch (error) {
            console.error("Update failed:", error.message);
        }
    };

    return (
        // <div className="page page-center">
            // <div className="container container-tight py-4 px-3">
            <div>
                <div className="text-center ">
                </div>
                <form
                    className="card card-md"
                    onSubmit={handleSignUpClick}
                    method="get"
                    autoComplete="off"
                    noValidate
                >
                    <div className="card-body">
                        <h2 className="card-title text-center mb-4">User Details</h2>
                        {errorUpdateUser ? <div className="text-danger bg-danger text-danger-fg px-3 py-1 border border-danger text-center rounded my-3">{errorUpdateUser}</div> : ""}
                        <div className="mb-3">
                            <label className="form-label">{t('signup.first_name.label')}</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('signup.first_name.placeholder')}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t('signup.last_name.label')}</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t('signup.last_name.placeholder')}
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t('signup.phone.label')}</label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder={t('signup.phone.placeholder')}
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                            />

                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t('auth.email.label')}</label>
                            <input
                                className="form-control"
                                type="email"
                                placeholder={t('auth.email.placeholder')}
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div lassName="mb-3">
                            <span class="col">Enable User</span>
                                <label class="form-check form-check-single form-switch">
                                    <input class="form-check-input" type="checkbox"     checked={formData.enabled}
                                onChange={handleInputChange} name="enabled"/>
                                </label>
                        </div>

                        <div className="form-footer">
                            <button type="submit" className="btn btn-primary w-100">
                               Save
                            </button>
                        </div>
                        <div className="form-footer">
                        <button  className="btn btn-outline-danger w-100"  onClick={handleCancel}>
                            Cancel
                        </button>
                        </div>
                    </div>
                </form>
            </div>
        // </div>
    );
}
export default UpdateDetails;
