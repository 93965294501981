import { Button, Card, CardBody, CardTitle, Form } from "react-bootstrap";
import Page from "../../../layouts/Page";
import VoicePrompt from "./callflow/prompt";
import IVRAction from "./callflow/action";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addIvr, getFirstAvailableIVRNumber, getPromptList, updateIvr } from "../actions";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonWithLoader from "../../../components/ButtonWithLoader";

export default function VoiceTemplate() {
    const location = useLocation();
    const loading = useSelector((state) => state.voice.loading);
    const editData = location.state?.editData;
    const [extension, setExtension] = useState();
    const [ivrName, setIvrName] = useState(editData ? editData.Name : null);
    const IVRNumber = useSelector(state => state.voice.IvrNumber);
    const promptList = useSelector(state => state.voice.PromptData);
    const [selectedPrompt, setSelectedPrompt] = useState(editData ? editData.PromptFilename : null);
    const [selectedPrompts, setSelectedPrompts] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        fetchData();
    }, []);

    React.useEffect(() => {
        if (IVRNumber && IVRNumber.Number) {
            setExtension(IVRNumber.Number);
        }
    }, [IVRNumber]);

    const fetchData = (e) => {
        try {
            dispatch(getFirstAvailableIVRNumber());
            dispatch(getPromptList());
        } catch (error) {
            console.error("failed:", error.message);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true)
        } else {
            setIsSaving(true);
            try {
                if (!editData) {
                    await dispatch(addIvr({
                        "name": ivrName,
                        "number": extension,
                        "prompt": selectedPrompt,
                        "destinations": selectedPrompts,
                    }));
                } else {
                    await dispatch(updateIvr({
                        "id": String(editData.Id),
                        "name": ivrName,
                        "prompt": selectedPrompt,
                        "destinations": selectedPrompts,
                    }));
                }
                setIsSaving(false);
                navigate("/templates/voice")
            } catch (error) {
                console.error("failed:", error.message);
            }
        }

    };

    const handleSelectPrompt = (promptData, index) => {
        if (promptData) {
            if (index >= 0) {
                setSelectedPrompts(prevState => {
                    const updatedState = [...prevState];
                    const newItem = {};
                    if (typeof promptData === 'object') {
                        if (promptData.Number) {
                            newItem.ForwardDN = promptData.Number;
                        }
                        newItem.ForwardType = promptData.Type;
                    } else {
                        newItem.CustomData = promptData;
                        newItem.ForwardType = "CustomInput";
                    }
                    newItem.Input = String(index);

                    if (newItem.ForwardType !== "Nothing") {
                        updatedState.push(newItem);
                    } else {
                        const itemIndex = updatedState.findIndex(item => item.Input === String(index));
                        if (itemIndex !== -1) {
                            updatedState.splice(itemIndex, 1);
                        }
                    }
                    return updatedState;
                });
            } else if (promptData) {
                setSelectedPrompt(promptData);
            }
        }

    };


    return <Page title={"Voice Configurations"}>

        <Card>
            <CardBody>
                <CardTitle><h3>Call Flow Configurations</h3></CardTitle>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Virtual Extension Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Extension"
                            required
                            disabled={editData}
                            value={editData ? editData.Number : extension}
                            onChange={(e) => setExtension(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid extension.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="validationCustom02">
                        <Form.Label>Digital Receptionist Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            required
                            value={ivrName}
                            onChange={(e) => setIvrName(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Receptionist Name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Welcome Prompt</Form.Label>
                        <VoicePrompt
                            data={promptList}
                            onSelectPrompt={handleSelectPrompt}
                            promptFilename={editData ? editData.PromptFilename : ""} />
                        <Form.Text muted>Format WAV (PCM, 8 kHz, 16 bit, Mono, Max size: 100MB)</Form.Text>
                    </Form.Group>

                    <Card>
                        <CardBody>
                            <CardTitle><h3>Actions</h3></CardTitle>
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }}>Key</th>
                                            <th style={{ width: "40%" }}>Action</th>
                                            <th>Destination</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: 10 }, (_, index) => (
                                            <IVRAction key={index} index={index} data={promptList} onSelectPrompt={handleSelectPrompt}
                                                editData={editData && editData.Forwards && editData.Forwards.length !== 0 ?
                                                    editData.Forwards.find(option => Number(option.Input) === index) :
                                                    null}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>

                    <div className="mt-3 d-flex gap-2 justify-content-end">
                        <ButtonWithLoader
                            className="btn btn-primary ms-2"
                            loading={loading}
                            disabled={isSaving}
                            type="submit"
                        >
                            Save
                        </ButtonWithLoader>
                        <Button variant="outline-danger" onClick={() => navigate("/templates/voice")} >Cancel</Button>
                    </div>
                </Form>
            </CardBody>
        </Card>
    </Page>
}