import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "@config/axiosInterceptors";

export const getIvrConfig = createAsyncThunk(
  "configurations/firstLook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`v1/3cx/default-ivr`);
      console.log("action response",response.data)
      return response.data;
    } catch (error) {
      console.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

