import React, { useState, useEffect } from "react";
import { Card, CardBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createCustomField, updateCustomField } from "../actions";
import { toast } from "react-toastify";
import ButtonWithLoader from "../../../components/ButtonWithLoader";

function NewCustomField({ onCancel, onSave, editCustomField }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.customFields.loading);

  const dataTypes = [
    { value: "string", label: "Text" },
    { value: "number", label: "Number" },
    { value: "boolean", label: "Yes/No" },
    { value: "date", label: "Date" },
  ];

  const [formData, setFormData] = useState({
    label: "",
    dataType: "",
    profileView: false,
    contactFormView: false,
  });

  const [CustomFieldDataTypes] = useState(dataTypes);

  useEffect(() => {
    if (editCustomField) {
      setFormData({
        label: editCustomField.label,
        dataType: editCustomField.dataType,
        profileView: editCustomField.profileView || false,
        contactFormView: editCustomField.contactFormView || false,
      });
    } else {
      setFormData({
        label: "",
        dataType: "",
        profileView: false,
        contactFormView: false,
      });
    }
  }, [editCustomField]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDataTypesChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      dataType: selectedOption.value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const isSaveButtonDisabled = () => {
    const { label, dataType } = formData;
    return [label, dataType].some(
      (value) => typeof value === "string" && value.trim() === ""
    );
  };

  const handleSaveCustomField = async () => {
    try {
      const trimmedFormData = {
        ...formData,
        label: formData.label.trim(),
      };
  
      if (editCustomField) {
        // Update existing custom field
        const updatedFormData = { ...trimmedFormData, id: editCustomField.id };
        const updateCustomFieldResult = await dispatch(
          updateCustomField({
            id: editCustomField.id,
            editCustomFieldData: updatedFormData,
          })
        );
        if (
          updateCustomFieldResult.payload &&
          updateCustomFieldResult.payload.id
        ) {
          onSave();
          toast.success("Custom Field updated successfully!");
        } else {
          toast.error(updateCustomFieldResult.payload);
        }
      } else {
        // Create new custom field
        const createCustomFieldResult = await dispatch(
          createCustomField(trimmedFormData)
        );
        if (
          createCustomFieldResult.payload &&
          createCustomFieldResult.payload.id
        ) {
          onSave();
          toast.success("Custom Field created successfully!");
        } else {
          toast.error(createCustomFieldResult.payload);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <h3 className="card-title">
          {editCustomField ? "Edit Custom Field" : "New Custom Field"}
        </h3>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="label"
            placeholder="Custom field name"
            value={formData.label}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Type</label>
          <Select
            options={CustomFieldDataTypes}
            value={CustomFieldDataTypes.find(
              (option) => option.value === formData.dataType
            )}
            onChange={handleDataTypesChange}
            isDisabled={editCustomField ? true : false}
          />
        </div>
        <div className="mb-2">
          <label className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="profileView"
              checked={formData.profileView}
              onChange={handleCheckboxChange}
            />
            <span className="form-check-label">Show In Contact View</span>
          </label>
        </div>
        <div className="mb-2">
          <label className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="contactFormView"
              checked={formData.contactFormView}
              onChange={handleCheckboxChange}
            />
            <span className="form-check-label">
              Show In Contact Create View
            </span>
          </label>
        </div>
        <div className="d-flex g-2 mb-3 justify-content-end">
          <button className="btn btn-outline-danger" onClick={handleCancel}>
            Cancel
          </button>
          <ButtonWithLoader
            className="btn btn-primary ms-2"
            loading={loading}
            disabled={isSaveButtonDisabled()}
            type="submit"
            onClick={handleSaveCustomField}
          >
            Save
          </ButtonWithLoader>
        </div>
      </CardBody>
    </Card>
  );
}

export default NewCustomField;
