import { createSlice } from "@reduxjs/toolkit";

const queueSlice = createSlice({
  name: "queue",
  initialState: {
    loading: false,
    error: null,
    resetting: false,
  },
  reducers: {

    getQueueListSuccess: (state, action) => {
      state.loading = false;
      state.queueList = action.payload;
    },
    getQueueListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteSuccess: (state, action) => {
        state.data = action.payload;
        state.loading = false;
      },

  },
});

export const {
    getQueueListSuccess,
    getQueueListError,
    deleteSuccess

} = queueSlice.actions;
export default queueSlice.reducer;
