export default function Avatar({ name, status }) {

    const getStatusBadge = (status) => {
        switch (status) {
            case "available":
                return "bg-success";
            case "away":
                return "bg-warning";
            case "oof":
                return "bg-danger";
            case "custom1":
                return "bg-info";
            case "custom2":
                return "bg-primary";
            default:
                return "bg-x";
        }

    }

    const parts = name.split(" ")
    const initials = name[0] + (parts.length > 1 ? parts[parts.length - 1][0] : "");
    return <span className="avatar">
        <span className={`badge ${getStatusBadge(status)}`}></span>{initials}</span>
}