import React from 'react'

import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { showToast } from '@components/reducers/toast';
import UCFormCommonError from "@components/theme/UCFormCommonError";

import { userLogout } from '@modules/auth/actions';
import { requestFailed } from '@modules/auth/reducer';
import { changePassword } from '@modules/users/actions';

import ChangePasswordForm from '../forms/ChangePasswordForm';


const ChangePassword = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    //check this user / auth
    const { error, loading } = useSelector(state => state.auth);
    const location = useLocation();

    const fromDashboard = location.state?.fromDashboard ?? true;

    const handleChangePw = (data) => {
        const { newPassword, oldPassword } = data;
        dispatch(changePassword({
            password: oldPassword,
            newPassword: newPassword
        }))
            .then((response) => {
                if (response.payload.code === 200) {
                    dispatch(showToast({
                        message: "Password changed successfully",
                        type: "success"
                    }))
                    dispatch(userLogout());
                } else {
                    dispatch(showToast({
                        message: "Password change failed",
                        type: "error"
                    }))
                }
            })
            .catch((error) => {
                dispatch(showToast({
                    message: "Password change failed",
                    type: "error"
                }))
            });
    };

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <AuthPageRootLayout containerClassName="container-tight" fromDashboard={fromDashboard} title="Change Password">
            <div className="card card-md">
                <div className="card-body">
                    <h2 className="card-title mb-4">
                        {t("form_headers.change_password")}
                        {
                            !fromDashboard ? <IconX
                                className="float-end pointing"
                                onClick={handleClose} /> : null
                        }
                    </h2>
                    {error && <UCFormCommonError error={error}
                        clearFn={() => dispatch(requestFailed(null))} />}
                    <ChangePasswordForm
                        handleChangePw={handleChangePw}
                        loading={loading}
                    />
                </div>
            </div>
        </AuthPageRootLayout>
    )
}

export default ChangePassword