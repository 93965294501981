import React, { useState, useEffect } from "react";
import { Card, CardBody } from "react-bootstrap";
import { newContact, updateContact } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonWithLoader from "../../../components/ButtonWithLoader";

function NewContact({
  onCancel,
  onSave,
  customFieldList,
  isViewContact,
  contactData,
  onDeleteConfirmation,
  currentPage,
  pageSize,
}) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const loading = useSelector((state) => state.contacts.loading);
  const updating = useSelector((state) => state.contacts.updating);

  useEffect(() => {
    if (isViewContact && contactData) {
      setFormData(contactData);
      setIsEditMode(false);
    } else {
      const f = {};

      customFieldList.rows.forEach((field) => {
        if (field.contactFormView === true) {
          f[field.name] = "";
        }
      });
      f["name"] = "";
      f["phone"] = "";
      setFormData(f);
      setIsEditMode(false);
    }
  }, [isViewContact, contactData, customFieldList.rows]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "phone") {
      newValue = value.replace(/[^0-9+]/g, "");
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const isSaveButtonDisabled = () => {
    const { name, phone } = formData;
    return [name, phone].some(
      (value) => typeof value === "string" && value.trim() === ""
    );
  };

  const handleDelete = () => {
    onDeleteConfirmation(contactData);
  };

  const handleUpdateContact = async () => {
    try {
      const contactUpdateResult = await dispatch(
        updateContact({ formData, currentPage, pageSize })
      );
      console.log(contactUpdateResult)
      if (contactUpdateResult.payload && contactUpdateResult.payload._id) {
        onSave();
        toast.success("Contact Updated successfully!");
      } else {
        toast.error(contactUpdateResult.payload);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSaveContact = async () => {
    try {
      if (isEditMode) {
        handleUpdateContact();
      } else {
        const contactCreateResult = await dispatch(
          newContact({ formData, currentPage, pageSize })
        );
        if (contactCreateResult.payload && contactCreateResult.payload._id) {
          onSave();
          toast.success("Contact Created successfully!");
        } else {
          toast.error(contactCreateResult.payload);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    onCancel();
    setIsEditMode(false);
  };

  return (
    <Card className="mb-3">
      <CardBody>
        <h3 className="card-title">
          {isEditMode
            ? "Edit Contact"
            : isViewContact
            ? "View Contact"
            : "New Contact"}
        </h3>
        {/* Mandatory fields */}
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
            disabled={isViewContact && !isEditMode}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Phone</label>
          <input
            type="tel"
            className="form-control"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleInputChange}
            disabled={isViewContact && !isEditMode}
          />
        </div>
        {/* Generate form fields */}
        {customFieldList.rows.map(
          (field) =>
            field.contactFormView && (
              <div key={field.id} className="mb-3">
                <label className="form-label">{field.label}</label>
                <input
                  type={"text"}
                  className="form-control"
                  name={field.name}
                  placeholder={field.label}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  disabled={isViewContact && !isEditMode}
                />
              </div>
            )
        )}

        <div className="mb-2">
          <label className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="dnc"
              checked={formData.dnc}
              onChange={handleCheckboxChange}
              disabled={isViewContact && !isEditMode}
            />
            <span className="form-check-label">Add to DNC</span>
          </label>
        </div>

        <div className="d-flex g-2 mb-3 justify-content-end">
          {isEditMode ? (
            <>
              <button className="btn btn-outline-danger" onClick={handleCancel}>
                Cancel
              </button>
              <ButtonWithLoader
                className="btn btn-primary ms-2"
                loading={updating}
                type="submit"
                disabled={isSaveButtonDisabled()}
                onClick={handleUpdateContact}
              >
                Save
              </ButtonWithLoader>
            </>
          ) : isViewContact ? (
            <>
              <button
                className="btn btn-outline-primary"
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </button>
              <button
                className="btn btn-outline-danger ms-2"
                onClick={handleDelete}
              >
                Delete
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-outline-danger" onClick={handleCancel}>
                Cancel
              </button>
              <ButtonWithLoader
                className="btn btn-primary ms-2"
                loading={loading}
                type="submit"
                disabled={isSaveButtonDisabled()}
                onClick={handleSaveContact}
              >
                Save
              </ButtonWithLoader>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default NewContact;
