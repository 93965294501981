import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AuthorizedSections from '@components/AuthorizedSections';
import ButtonWithLoader from '@components/ButtonWithLoader';
import { showToast } from '@components/reducers/toast';
import UCButton from '@components/theme/UCButton';
import UCFormSelect from '@components/theme/UCFormSelect';

import { OWNER_ACCESS, SYSTEM_ROLES } from '@config/constants';

import { updateDetails } from '@modules/users/actions';

const EditUserForm = ({ onTriggerFn, onFinish, loading, initState, page }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: initState
    });
    console.log(initState);

    const onUpdate = (data) => {
        let formattedData = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            role: data.role?.value ?? initState?.role
        };
        if (!data?.userId3CX && !data?.PrimaryGroupId && !data?.extension) {
            formattedData['userId3cx'] = data.userId3CX;
            formattedData['groupId'] = data.PrimaryGroupId;
            formattedData['extension'] = data.extension;
        }

        dispatch(updateDetails({ data: formattedData, page: page ?? 1 }))
            .then((res) => {
                if (res.payload && res.payload.status === 200) {
                    dispatch(showToast({ message: "User updated successfully", type: "success" }));
                } else {
                    dispatch(showToast({ message: "Failed to update user", type: "error" }));
                }
            }).catch((error) => {
                dispatch(showToast({ message: "Failed to update user", type: "error" }));
            }).finally(() => {
                onTriggerFn?.();
            });
    };

    return (
        <form method="post"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onUpdate)}>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.first_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.firstName && 'is-invalid'}`}
                    {...register('firstName', { required: true })}
                    placeholder={t("form_fields.first_name.placeholder")}
                    autoComplete="off"
                />
                {errors.firstName && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.last_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.lastName && 'is-invalid'}`}
                    {...register('lastName', { required: true })}
                    placeholder={t("form_fields.last_name.placeholder")}
                    autoComplete="off"
                />
                {errors.lastName && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.email.label")}
                </label>
                <input
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    {...register('email')}
                    placeholder={t("form_fields.email.placeholder")}
                    autoComplete="off"
                    disabled
                />
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.phone.label")}
                </label>
                <input
                    type="tel"
                    className={`form-control ${errors.phone && 'is-invalid'}`}
                    {...register('phone', { required: false })}
                    placeholder={t("form_fields.phone.placeholder")}
                    autoComplete="off"
                />
                {errors.phone && <div className="invalid-feedback">
                    {t("form_validations.invalid_phone")}
                </div>}
            </div>

            <AuthorizedSections grantedMinimumLevels={OWNER_ACCESS}>
                <div className="mb-3">
                    <label className="form-label">{t('form_fields.role.label')}</label>
                    <UCFormSelect
                        disabled={initState.role === 'owner'}
                        name={'role'}
                        placeholder={t('form_fields.role.placeholder')}
                        options={SYSTEM_ROLES}
                        isSearchable={true}
                        forcedValue={SYSTEM_ROLES.find(role => role.value === initState.role)}
                        control={control}
                    />
                </div>
            </AuthorizedSections>

            <div className="form-footer">
                <div className='row'>
                    {onFinish ? <div className="col-6">
                        <UCButton variant='secondary' outline className="w-100" onClick={onFinish}>
                            Cancel
                        </UCButton>
                    </div> : null}
                    <div className={onFinish ? "col-6" : "col-12"}>
                        <ButtonWithLoader
                            className="btn btn-primary w-100"
                            loading={loading}
                            type="submit"
                        >
                            {loading ? t('form_actions.update_loading') : t('form_actions.update')}
                        </ButtonWithLoader>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditUserForm;