import { createSlice } from "@reduxjs/toolkit";

const voiceSlice = createSlice({
  name: "voice",
  initialState: {
    IvrData: null,
    IvrNumber: null,
    PromptData: null,
    loading: false,
    error: null,
  },
  reducers: {
    ivrLoading: (state, action) => {
      state.loading = true;
    },
    ivrList: (state, action) => {
      state.loading = false;
      state.IvrData = action.payload;
    },
    getIVRNumber: (state, action) => {
      state.loading = false;
      state.IvrNumber = action.payload;
    },
    getPrompts: (state, action) => {
      state.loading = false;
      state.PromptData = action.payload;
    },

  }
});

export const {
  ivrLoading,
  ivrList,
  getIVRNumber,
  getPrompts,
} = voiceSlice.actions;
export default voiceSlice.reducer;