import React, { useMemo } from 'react'

import NavigationHeader from '@components/NavigationHeader';

import logo from "@assets/logo.png";

import Page from './Page';

const AuthPageRootLayout = ({
    hasUniConnectCenterLogo = true,
    hasCommonWrappingLayout = true,
    hasLangThemeToggler = true,
    containerClassName = "",
    fromDashboard,
    children,
    title
}) => {
    const renderWithCommonWrappingLayout = useMemo(() => {
        return (
            <>
                <NavigationHeader
                    fromEntryPage={true}
                    userProfile={null}
                    fromDashboard={false}
                />
                <div className="page-container-body-withscroll py-4 px-3">
                    <div className="page page-center">
                        <div className={`container ${containerClassName}`}>
                            {hasUniConnectCenterLogo && <div className="text-center mb-4 mt-6">
                                <div className="navbar-brand navbar-brand-autodark">
                                    <img src={logo} height="50" alt="" className="navbar-brand-image form-leading-image" />
                                </div>
                            </div>}
                            {children}
                        </div>
                    </div>
                </div>
            </>
        )
    }, [children, hasLangThemeToggler, hasUniConnectCenterLogo, containerClassName])

    if (fromDashboard) {
        return (
            <Page title={title}>
                <div className={`container ${containerClassName}`}>
                    {children}
                </div>
            </Page>
        )
    }

    return (
        <>
            {hasCommonWrappingLayout ? renderWithCommonWrappingLayout : <>
                {
                    hasLangThemeToggler && <NavigationHeader
                        fromEntryPage={true}
                        userProfile={null}
                    />
                }
                {children}
            </>}
        </>
    )
}

export default AuthPageRootLayout