import React, { useEffect, useMemo, useState } from 'react'

import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UCFormSelect from '@components/theme/UCFormSelect';

import { getCountryCodeByCountry, getCountryList, getPhoneNumberLengthByCountryCode } from '@utils/utils';

import { ORGANIZATION_TYPES } from '@config/constants';

const SetupOrganizationForm = ({
    fromDashboard
}) => {
    const { t } = useTranslation();
    const { register, formState: { errors }, setValue, control, getValues } = useFormContext();
    const initialValues = getValues();
    const countryOptions = useMemo(() => getCountryList(), []);
    const [areaCode, setAreaCode] = useState(getCountryCodeByCountry(countryOptions?.[0]?.value));
    const [phoneLength, setPhoneLength] = useState(getPhoneNumberLengthByCountryCode(areaCode));


    useEffect(() => {
        if (initialValues?.country) {
            onChangeCountryAdditionalFn(initialValues?.country);
        }
    }, [initialValues?.country]);


    const onChangeCountryAdditionalFn = (selectedOption) => {
        const countryCode = getCountryCodeByCountry(selectedOption?.value ?? selectedOption);
        setAreaCode(countryCode);
        setPhoneLength(getPhoneNumberLengthByCountryCode(countryCode)); // Update phoneLength here
        setValue('areaCode', countryCode);
    }


    const handlePhoneChange = (e) => {
        let value = e.target.value;

        value = value.replace(/[^0-9]/g, '');
        const phoneLength = getPhoneNumberLengthByCountryCode(areaCode);
        if (value.length > phoneLength) {
            value = value.slice(0, phoneLength);
        }
        setValue('phone', value);
    };

    return (
        <>
            <div className="row">
                {/* 2col */}
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.organization_name.label')}</label>
                    <input
                        type="text"
                        className={`form-control ${errors.organizationName && 'is-invalid'}`}
                        placeholder={t('form_fields.organization_name.placeholder')}
                        name="organizationName"
                        {...register('organizationName', { required: true })}
                        disabled={fromDashboard}
                    />
                    {errors.organizationName && <div className="invalid-feedback">
                        {t('form_validations.required')}
                    </div>}
                </div>
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.organization_type.label')}</label>
                    <UCFormSelect
                        name={'type'}
                        placeholder={t('form_fields.organization_type.placeholder')}
                        className={errors.type && 'is-invalid'}
                        options={ORGANIZATION_TYPES}
                        isSearchable={true}
                        control={control}
                        rules={{ required: true }}
                        disabled={fromDashboard}
                    />
                    {errors.type && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.business_reg_number.label')}</label>
                    <input
                        type="text"
                        className={`form-control ${errors.brNumber && 'is-invalid'}`}
                        placeholder={t('form_fields.business_reg_number.placeholder')}
                        name="brNumber"
                        {...register('brNumber', { required: true })}
                        disabled={fromDashboard}
                    />
                    {errors.brNumber && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.org_upload_br_documents.label')}</label>
                    <Controller
                        // key={Date.now()}
                        name="brDoc"
                        control={control}
                        rules={{ required: initialValues?.url ? false : true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                                <input
                                    type="file"
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    onChange={(e) => onChange(e.target.files)}
                                    multiple={false}
                                    disabled={fromDashboard}
                                />
                                {value && value.length > 0 ? (
                                    <div>
                                        {Array.from(value).map((file, index) => (
                                            <div key={index} className='file-feedback'>{file.name}</div>
                                        ))}
                                    </div>
                                ) : <div className='file-feedback'>{initialValues?.url}</div>}
                                {error && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 mb-3">
                    <label className="form-label">{t('form_fields.org_address.label')}</label>
                    <input
                        type="text"
                        className={`form-control ${errors.address && 'is-invalid'}`}
                        placeholder={t('form_fields.org_address.placeholder')}
                        name="address"
                        {...register('address', { required: true })}
                    />
                    {errors.address && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
            </div>
            <div className='row'>
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.org_country.label')}</label>
                    <UCFormSelect
                        name={'country'}
                        placeholder={t('form_fields.org_country.placeholder')}
                        className={errors.country && 'is-invalid'}
                        options={countryOptions}
                        isSearchable={true}
                        control={control}
                        rules={{ required: true }}
                        onChangeAdditionalFn={onChangeCountryAdditionalFn}
                    />
                    {errors.country && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.org_contact_number.label')}</label>
                    <div className="input-group">
                        <span className="input-group-text">
                            {`+${areaCode}`}
                        </span>
                        <input
                            type="tel"
                            className={`form-control ${errors.phone && 'is-invalid'}`}
                            placeholder={t('form_fields.org_contact_number.placeholder')}
                            name="phone"
                            {...register('phone', { required: true })}
                            onChange={handlePhoneChange}
                        />
                    </div>
                    {errors.phone && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                    {phoneLength && <div className='file-feedback'>{t("form_helpers.phone_length_description", {
                            phoneLength: `${phoneLength}`,
                        })}</div>}
                </div>

            </div>
            <div className="row">
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.org_city.label')}</label>
                    <input
                        type="text"
                        className={`form-control ${errors.city && 'is-invalid'}`}
                        placeholder={t('form_fields.org_city.placeholder')}
                        name="city"
                        {...register('city', { required: true })}
                    />
                    {errors.city && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
                <div className="col-xl-6 mb-3">
                    <label className="form-label">{t('form_fields.org_postal_code.label')}</label>
                    <input
                        type="text"
                        className={`form-control ${errors.postalCode && 'is-invalid'}`}
                        placeholder={t('form_fields.org_postal_code.placeholder')}
                        name="postalCode"
                        {...register('postalCode', { required: true })}
                    />
                    {errors.postalCode && <div className="invalid-feedback">{t('form_validations.required')}</div>}
                </div>
            </div>
            <div className="row">
                <div className="mb-3">
                    <label className="form-check">
                        <input
                            key={Date.now()}
                            type="checkbox"
                            className={`form-check-input ${errors.agreeTerms && 'is-invalid'}`}
                            {...register('agreeTerms', { required: true })}
                        />
                        <span className="form-check-label">
                            {t('form_helpers.review_statement')}
                        </span>
                    </label>
                </div>
            </div>
        </>
    )
}

export default SetupOrganizationForm