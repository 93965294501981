import { Button } from 'bootstrap';
import React from 'react';
import { useTranslation } from "react-i18next";

export default function QueueDetailView({ queue, onClose , onEditClick}) {
    const { t } = useTranslation();

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('Queue Details')}</h4>
                <button 
                    className="btn-close ms-auto" 
                    onClick={onClose} 
                    aria-label={t('Close')}
                ></button>
            </div>
            <div className="card-body">
                <div className="queue-info">
                    <p><strong>{t('Queue Name')}:</strong> {queue.Name}</p>
                    <p><strong>{t('Polling Strategy')}:</strong> {queue.PollingStrategy}</p>
                    <p><strong>{t('Agents')} </strong></p>
                    <ul className="list-group">
                        {queue.Agents.map((agent) => (
                            <li className="list-group-item" key={agent.Number}>
                                <span className='text-reset d-block text-truncate'>
                                    {agent.Name} {agent.Number}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                <br/>

                <div className="form-footer">
                <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => onEditClick(queue)}
                >
                    Edit
                </button>
                </div>
            </div>
        </div>
    );
}
