import { createAsyncThunk } from '@reduxjs/toolkit';
import { contact, update, contactSuccess, contactError, getContactSuccess, getContactError,updateContactSuccess, updateContactError, deleteContactSuccess , deleteContactError, deletingContact, contactList, contactListSuccess, contactListError } from './reducer';
import AxiosInstance from '../../config/axiosInterceptors';

export const newContact = createAsyncThunk('contacts/newContact', async ({formData, currentPage, pageSize}, { dispatch, rejectWithValue }) => {
    try {
      dispatch(contact());
      const response = await AxiosInstance.post('v1/contacts', formData);
      await dispatch(getContactList({currentPage, pageSize}));
      dispatch(contactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(contactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });
  export const getContactList = createAsyncThunk('contacts/getContactList', async ({currentPage,pageSize}, { dispatch, rejectWithValue }) => {
    try {
      dispatch(contact());
      const response = await AxiosInstance.get(`v1/contacts?page=${currentPage}&pageSize=${pageSize}`);
      dispatch(getContactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getContactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });
  export const updateContact = createAsyncThunk('contacts/updateContact', async ({formData, currentPage, pageSize}, { dispatch, rejectWithValue }) => {
    try {
      const updatedFormData = { ...formData, id: formData._id };
    delete updatedFormData._id;
      dispatch(update());
      const response = await AxiosInstance.put(`v1/contacts/${formData._id}`, updatedFormData);
      dispatch(updateContactSuccess(response.data));
      dispatch(getContactList({currentPage, pageSize}));
      return response.data;
    } catch (error) {
      dispatch(updateContactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

  export const updateDNC = createAsyncThunk('contacts/updateDNC', async ({formData}, { dispatch, rejectWithValue }) => {
    try {
      dispatch(update());
      const response = await AxiosInstance.put(`v1/contacts/update-dnc/${formData._id}`, formData);
      console.log(response);
      dispatch(updateContactSuccess(response.data));
      // dispatch(getContactList({currentPage, pageSize}));
      return response.data;
    } catch (error) {
      dispatch(updateContactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });
  export const deleteContact = createAsyncThunk('contacts/deleteContact', async ({ contactId, currentPage, pageSize }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(deletingContact());
      const response = await AxiosInstance.delete(`v1/contacts/${contactId}`);
      await dispatch(getAllContatcs());
      await dispatch(getContactList({currentPage, pageSize}));
      dispatch(deleteContactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(deleteContactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

  export const deleteSelectedContacts = createAsyncThunk(
    'contacts/deleteSelectedContacts',
    async ({ ids, currentPage, pageSize }, { dispatch, rejectWithValue }) => {
      try {
        dispatch(deletingContact());
  
        const response = await AxiosInstance.delete('v1/contacts/bulk-delete', {
          data: { ids } 
        });
        await dispatch(getAllContatcs()); 
        await dispatch(getContactList({ currentPage, pageSize })); 
        dispatch(deleteContactSuccess(response.data)); 
      } catch (error) {
        dispatch(deleteContactError(error.message)); 
        return rejectWithValue(error.message);
      }
    }
  );

  export const getAllContatcs = createAsyncThunk('contacts/getAllContatcs', async(_,{ dispatch, rejectWithValue })=>{
    try {
      dispatch(contactList());
      const response = await AxiosInstance.get(`v1/contacts/all`);
      dispatch(contactListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(contactListError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });
  export const bulkUpload = createAsyncThunk('contacts/bulkUpload', async ({ contacts, currentPage, pageSize }, { dispatch, rejectWithValue }) => {
    console.log("contacts",contacts)
    try {
      const response = await AxiosInstance.post('v1/contacts/bulk-upload', {contacts});
      await dispatch(getContactList({currentPage, pageSize}));
      dispatch(contactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(contactError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });