import React, { useState, useMemo, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import OrgSetupFormWizard from "@components/OrgSetupFormWizard";
import { endLoader } from "@components/reducers/loading";
import { showToast } from "@components/reducers/toast";

import usePreventBack from "@hooks/usePreventBack";

import { SERVER_PREFIX } from "@config/config";

import { getOrgProfile, setupOrganization, updateOrganization, uploadFile } from "../actions";
import ChannelSelectionForm from "../forms/ChannelSelectionForm";
import ReviewAndRequestOrgForms from "../forms/ReviewAndRequestOrgForms";
import SetupOrganizationForm from "../forms/SetupOrganizationForm";

function OrganizationSetup({ fromDashboard = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.profile);
  const [activeStep, setActiveStep] = useState(undefined);
  const [onPendingApproval, setOnPendingApproval] = useState(false);
  const [initFormValues, setInitFormValues] = useState(undefined);
  const [documentId, setDocumentId] = useState(undefined);
  usePreventBack();

  useEffect(() => {
    if ((profile && profile.approvalStatus !== "approved") || fromDashboard) {
      dispatch(getOrgProfile()).then((data) => {
        if (data?.payload?.name) {
          setInitFormValues(data?.payload);
          if (!fromDashboard) {
            setActiveStep(3);
          }
          if (data?.payload?.approvalStatus === "pending") {
            setOnPendingApproval(true);
          }
        }
      })
        .finally(() => {
          dispatch(endLoader());
        });
    }
  }, [fromDashboard]);

  const handleSubmit = useCallback(async (unprocessedData) => {
    // validate mobile number and format it
    const phoneNumber = `+${unprocessedData.areaCode}${unprocessedData.phone}`;

    // format data
    const formattedPayload = {
      phone: phoneNumber,
      country: unprocessedData.country.value,
      type: unprocessedData.type.value,
      isSMSEnabled: unprocessedData.smsEnable,
      postalCode: unprocessedData.postalCode,
      name: unprocessedData.organizationName,
      brNumber: unprocessedData.brNumber,
      address: unprocessedData.address,
      city: unprocessedData.city,
      number: unprocessedData.number.toString(),
      trunkId: unprocessedData.trunkId.toString(),
    };

    if (unprocessedData.smsEnable) {
      formattedPayload['mask'] = unprocessedData.smsMask;
    }

    // file upload
    if (unprocessedData.brDoc) {
      const uploadResponse = await dispatch(uploadFile(unprocessedData.brDoc));
      const fileUrl = uploadResponse.payload.url;
      const urlWithoutPrefix = await fileUrl.replace(SERVER_PREFIX, "");
      formattedPayload['url'] = urlWithoutPrefix;
    } else {
      const urlWithoutPrefix = await initFormValues?.url?.replace(SERVER_PREFIX, "");
      formattedPayload['url'] = urlWithoutPrefix;
    }

    if (unprocessedData?.id || documentId) {
      formattedPayload['id'] = initFormValues?.id ?? documentId;
    }

    let organizationSetup;

    if (fromDashboard) {
      organizationSetup = await dispatch(updateOrganization(formattedPayload));
      dispatch(showToast({ message: "Successfully updated the organization.", type: "success", autoClose: 4000 }));
    } else if (initFormValues || activeStep === 3) {
      organizationSetup = await dispatch(updateOrganization(formattedPayload));
    } else {
      organizationSetup = await dispatch(setupOrganization(formattedPayload));
    }

    if (organizationSetup.payload && organizationSetup.payload.id && !fromDashboard) {
      if (organizationSetup?.payload?.id) {
        setDocumentId(organizationSetup?.payload?.id);
      }

      // update onPendingApproval immediate
      if (organizationSetup.payload.approvalStatus === "pending") {
        setOnPendingApproval(true);
      }

      dispatch(getOrgProfile()).then((data) => {
        if (data?.payload?.name) {
          setInitFormValues(data?.payload);
          if (!fromDashboard) {
            setActiveStep(3);
          }
        }
      });

      setActiveStep(3);
    }
  }, [documentId, activeStep, initFormValues]);


  const forms = useMemo(() => [
    <SetupOrganizationForm />,
    <ChannelSelectionForm />,
    <ReviewAndRequestOrgForms />,
    <ReviewAndRequestOrgForms />
  ], []);

  return (
    <AuthPageRootLayout
      containerClassName={`${!fromDashboard ? "mw-80 py-4" : "container-without-padding"}`}
      title="Organization Update"
      fromDashboard={fromDashboard}>
      <OrgSetupFormWizard
        hideStepperIndex={3}
        approvalIndex={3}
        activeStep={activeStep}
        documentId={documentId}
        forms={forms}
        initFormValues={initFormValues}
        onSubmit={handleSubmit}
        fromDashboard={fromDashboard}
        onPendingApproval={onPendingApproval}
        onCancel={() => navigate("/auth/login")} />
    </AuthPageRootLayout>
  );
}
export default OrganizationSetup;
