export const IVR_OPTIONS = [
    { value: "1", label: "Nothing", name: "" },
    { value: "2", label: "End Call", name: "EndCall" },
    { value: "3", label: "Connect to Extension", name: "Extension" },
    { value: "4", label: "Connect to Ring group", name: "RingGroup" },
    { value: "5", label: "Digital Receptionist", name: "IVR" },
    { value: "6", label: "Transfer to Voicemail", name: "VoiceMail" },
    { value: "7", label: "Call by Name", name: "CallByName" },
    { value: "8", label: "Play Prompt and exit", name: "CustomInput" },
    { value: "9", label: "Repeat Prompt", name: "RepeatPrompt" }
];

export const ORGANIZATION_TYPES = [
    { value: "Type1", label: "Restaurant" },
    { value: "Type2", label: "Saloon" },
    { value: "Type3", label: "Hotel" },
    { value: "Type4", label: "Finanace Institution" },
    { value: "Type5", label: "Travel Agency" },
    { value: "Type6", label: "Service Centre" },
    { value: "Type7", label: "Others" },


];

export const ORGANIZATION_SETUP_STEPS = [
    { name: 'Organization Creation' },
    { name: 'Channel Selection' },
    { name: 'Request Submission' }
];

export const FIRST_LOOK_TABS = [
    { title: 'User Config', description: 'Assign to User' },
    { title: 'Team Config', description: 'Assign to Team' },
    { title: 'IVR Config', description: 'Continue with IVR' }
]

export const POLLING_STRATEGIES = [
    { value: "Hunt", label: "Ring calls according to list" },
    { value: "RingAll", label: "Ring all" },
    { value: "LongestWaiting", label: "Ring calls according to longest waiting" },
    { value: "NextAgent", label: "Round Robin" }
];

export const FULL_ACCESS = ['agent', 'orgAdmin', 'owner'];
export const ADMIN_ACCESS = ['orgAdmin', 'owner'];
export const OWNER_ACCESS = ['owner'];

export const SYSTEM_ROLES = [
    { value: 'owner', label: 'Owner' },
    { value: 'orgAdmin', label: 'Organization Admin' },
    { value: 'agent', label: 'Agent' }
];