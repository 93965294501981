import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getSMSTemplateSuccess,
    getSMSTemplateError,
} from "./reducer";
import AxiosInstance from "../../config/axiosInterceptors";


// export const getTemplates = createAsyncThunk('user/getTemplate', async (_, { dispatch, rejectWithValue }) => {
//     console.log("****************************************************");
//     try {
//       const response = await AxiosInstance.get(`v1/sms-template`);
//       console.log(response);
//     //   dispatch(getSMSTemplateSuccess(response.data));
//       console.log(response.data);
//       return response.data;
//     } catch (error) {
//     //   dispatch(getSMSTemplateError(error.response.data.message));
//       return rejectWithValue(error.response.data.message);
//     }
//   }
//   );

export const getSMSTemplate = createAsyncThunk('voice/getSMSTemplate', async (_, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get('v1/sms-template');
        // console.log("response",response);
        dispatch(getSMSTemplateSuccess(response.data))
        return response.data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const createSMSTemplate = createAsyncThunk('sms/create', async (formData, { dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.post('v1/sms-template', formData);
        console.log(response);
        dispatch(getSMSTemplate())
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data.message);
      }
  });

  export const updateSMSTemplate = createAsyncThunk('sms/updateSMSTemplate',async ({ id, data }, { dispatch, rejectWithValue }) => {
      try {
        const response = await AxiosInstance.put(`v1/sms-template/${id}`, data);
        console.log(response);
        dispatch(getSMSTemplate())
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const deleteSMSTemplate = createAsyncThunk('sms/deleteSMSTemplate',async (id, { dispatch, rejectWithValue }) => {
      try {
        const response = await AxiosInstance.delete(`v1/sms-template/${id}`);
        console.log(response);
        dispatch(getSMSTemplate())
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );