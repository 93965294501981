import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AuthPageRootLayout from '@layouts/AuthPageRootLayout';

import ConfigVerticalStepper from '@components/ConfigVerticalStepper';
import ConnectionState from '@components/ConnectionState';
import UCButton from '@components/theme/UCButton';

import usePreventBack from '@hooks/usePreventBack';

import { FIRST_LOOK_TABS } from '@config/constants';

import { getOrgProfile } from '@modules/auth/actions';

import ConfigTabContainer from '../widgets/ConfigTabContainer';

const FirstLook = ({ fromDashboard = false }) => {
    const [changedToRouteUI, setChangedToRouteUI] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile.profile);
    const connectionStatus = profile?.IsRegistered ?? false;
    usePreventBack();
    
    useEffect(() => {
        dispatch(getOrgProfile()).then((data) => {
            if (data?.payload?.numbers) {
                setSelectedNumber(data.payload.numbers.number);
            }
        });
    }, [dispatch]);


    console.log("profile",profile.firstLookStatus);
    // useEffect(() => {
    //     dispatch(getOrgProfile().then())
    // })

    const handleChangedToRouteUI = useCallback(() => {
        setEditMode(prev => !prev);
        setChangedToRouteUI(prev => !prev);

        // Fetch profile after routing option changes
        dispatch(getOrgProfile()).then((data) => {
            if (data?.payload?.numbers) {
                setSelectedNumber(data.payload.numbers.number);
            }
        });

    }, [dispatch]);

    useEffect(() => {
        if (profile?.firstLookStatus) {
            setSelectedTab(profile.firstLookStatus);
        }
    }, [profile]);

    // Ensure UI updates when the selected tab changes
    // useEffect(() => {
    //     if (selectedTab !== profile?.firstLookStatus) {
    //         setSelectedTab(profile?.firstLookStatus || 0);
    //     }
    // }, [selectedTab, profile]);

    // useEffect(() => {
    //     if (profile?.firstLookStatus) {
    //         setSelectedTab(profile.firstLookStatus);
    //     }
    // }, [profile]);

    const handleSelectedTab = (tabIndex) => {
         setSelectedTab(tabIndex);
    }

    const renderStatusElement = useMemo(() => (
        <ConnectionState
            phoneNumber={selectedNumber}
            connectionStatus={connectionStatus}
            uiType={selectedTab === 0 ? "user" : "team"}
            title={FIRST_LOOK_TABS[selectedTab].title}
            description={FIRST_LOOK_TABS[selectedTab].description}
        />
    ), [selectedNumber, selectedTab]);

    const renderContent = () => {
        return (
            <ConfigTabContainer
                onChangeRouteUI={handleChangedToRouteUI}
                editable={editMode}
                selectedTab={selectedTab}
                handleSelectedTab={handleSelectedTab}
            />
        )
    }

    return (
        <AuthPageRootLayout
            containerClassName={fromDashboard ? "mw-80" : "mw-80 py-4"}
            fromDashboard={fromDashboard} title="Change Routing Options">
            <div className="row g-2">
                {!fromDashboard ? <div className="col-12 col-lg-3 order-lg-last">
                    <div className="card">
                        <div className="card-body ps-6">
                            <ConfigVerticalStepper connectionStatus={connectionStatus} />
                        </div>
                    </div>
                </div> : null}
                <div className={`${!fromDashboard ? "col-lg-9" : ""} col-12`}>
                    <div className={changedToRouteUI ? "" : "card"}>
                        <div className={changedToRouteUI ? "" : "card-body"}>
                            {changedToRouteUI ? (
                                <>
                                    {!editMode && renderStatusElement}
                                    {renderContent()}
                                </>
                            ) : (
                                <>
                                    {renderStatusElement}
                                    {renderContent()}
                                </>
                            )}
                            {!editMode && (
                                <div className="d-flex justify-content-end">
                                    <UCButton className="mt-4" onClick={handleChangedToRouteUI}>
                                        Other Routing Options
                                    </UCButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </AuthPageRootLayout>
    );
};

export default FirstLook;
