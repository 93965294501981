import React, { useState, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";


import IvrPlaceholder from '@components/IvrPlaceholder';
import { showToast } from "@components/reducers/toast";

import useApi from '@hooks/useApi';

import AxiosInstance from '@config/axiosInterceptors';

import { getIvrConfig } from '@modules/firstlook/actions'

import MobileIVRMenu from './MobileIVRMenu';

import IVRForm from '../forms/IVRForm';

const IVRConfig = ({ editable, onChangeRouteUI }) => {
    const organization = useSelector(state => state.auth.organization);
    console.log("organization",organization);
    const { t } = useTranslation();
    const { postRequest, putRequest, loading: apiLoading } = useApi();
    const methods = useForm({
        shouldUnregister: true,
        mode: 'onSubmit',
    });
    const dispatch = useDispatch();

    const [submitLoading, setSubmitLoading] = useState(false);
    const [loadingPrev, setPrevLoading] = useState(true);
    const [ivrData, setIvrData] = useState(null);
    const profile = useSelector((state) => state.profile.profile);


    console.log("ivrData",ivrData);

    useEffect(() => {
        // if (organization?.defaultIVRId) {
            dispatch(getIvrConfig())
                .then((data) => {
                    console.log("data",data)
                    setIvrData(data.payload[0]);
                    setPrevLoading(false);
                })
                .catch(() => setPrevLoading(false));
        // } else {
        //     setPrevLoading(false);
        // }
    }, []);

    console.log("ivrData",ivrData);


    const refreshIvrData = async () => {
        // if (organization?.defaultIVRId) {
            setPrevLoading(true);
            try {
                const updatedData = await dispatch(getIvrConfig());
                console.log("updatedData",updatedData);
                setIvrData(updatedData.payload[0]);
            } catch (error) {
                console.error('Error refreshing IVR data:', error);
            } finally {
                setPrevLoading(false);
            }
      //  }
    };



    const handleSubmit = async (data) => {
        setSubmitLoading(true);

        try {
            const { ivrName, ivrWelcomePrompt } = data;
            let menuData = methods.getValues("menuData");

            const createPayload = (number) => ({
                number,
                name: profile.code +"_"+ivrName,
                prompt: ivrWelcomePrompt,
                destinations: menuData.destinations,
                timeout: menuData.timeout,
                timeoutForwardType: menuData.timeoutForwardType,
                timeoutForwardDN: menuData.timeoutForwardDN,
                invalidKeyForwardDN: menuData.invalidKeyForwardDN,
            });

            if (ivrData && ivrData.Id) {
                const payload = {
                    id: ivrData.Id.toString(),
                    ...createPayload(ivrData?.Number),
                }

                await putRequest({
                    url: 'v1/organizations/first-look-status',
                    payload: { firstLookStatus: 2 },
                });

                await putRequest({
                    url: 'v1/3cx/ivr',
                    payload,
                    notifySuccessMessage: 'IVR update successful',
                    notifyErrorMessage: 'Failed to update IVR',
                });

                await refreshIvrData();
                onChangeRouteUI?.();
            } else {
                const response = await AxiosInstance.get(`v1/3cx/firstAvailableIVRNumber`);
                if (!response?.data) {
                    dispatch(showToast({ message: "Failed to retrieve the first available IVR number.", type: "error", autoClose: 4000 }));
                    return;
                }

                menuData.number = response.data.Number;

                await putRequest({
                    url: 'v1/organizations/first-look-status',
                    payload: { firstLookStatus: 2 },
                });

                await postRequest({
                    url: 'v1/3cx/ivr',
                    payload: createPayload(menuData.number),
                    notifySuccessMessage: 'IVR configuration successful',
                    notifyErrorMessage: 'Failed to configure IVR',
                });

                await refreshIvrData();
                onChangeRouteUI?.();
            }
        } catch (error) {
            console.error("Error during IVR configuration:", error);
            dispatch(showToast({ message: "An unexpected error occurred.", type: "error", autoClose: 4000 }));
        } finally {
            setSubmitLoading(false);
        }
    };


    const renderMobileNumberPadLayout = () => {
        return (
            <div className='mb-3'>
                <h3 className='mb-4'>{t("form_headers.menu_options")}</h3>
                <MobileIVRMenu ivrData={ivrData} editable={editable} />
            </div>
        );
    };

    if ((loadingPrev  || (organization.firstLookStatus === 2 && !ivrData ))) {
        // display loading state till selected ivr load
        return (
            <IvrPlaceholder />
        );
    }
    
    

    return (
        <div className='my-4'>
            {
                editable ? <div>
                    <div className='mb-4'>
                        <h3 className='mb-0'>{t("form_headers.connect_to_team")}</h3>
                        <p className="text-secondary mb-0">
                            {t("form_helpers.connect_to_team_description")}
                        </p>
                        <p className="text-secondary mb-0">
                            {t("form_helpers.connect_to_team_sub_description")}
                        </p>
                    </div>
                    <FormProvider {...methods}>
                        <IVRForm
                            handleDataSubmit={handleSubmit}
                            loading={submitLoading || apiLoading}
                            optionElement={renderMobileNumberPadLayout()}
                            ivrData={ivrData}
                            editable={editable}
                        />
                    </FormProvider>
                </div> : <div>
                    <FormProvider {...methods}>
                        <IVRForm
                            handleDataSubmit={handleSubmit}
                            loading={submitLoading || apiLoading}
                            optionElement={renderMobileNumberPadLayout()}
                            ivrData={ivrData}
                            editable={editable}
                        />
                    </FormProvider>
                </div>
            }

        </div>
    );
};

export default IVRConfig;
