import React from 'react'

import { QRCodeSVG } from 'qrcode.react';

import UCButton from './theme/UCButton';

const QRInvite = ({
    qrLink,
    enableMobileLink,
    enableWelcomeMessage,
}) => {
    const hasAnyLink = enableMobileLink || enableWelcomeMessage;
    return (
        <div className={`${hasAnyLink ? 'g-4' : ''} row`}>
            <div className={`${hasAnyLink ? 'col-md-6' : ''} col-12 d-flex justify-content-center align-items-center flex-column`}>
                <QRCodeSVG
                    value={qrLink}
                    size={128}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                />
                <p className={`${hasAnyLink ? 'w-50':'w-75'} text-secondary mb-0 mt-6 text-center uc-stepper-desc`}>
                    <strong>private your QR</strong> ,Sharing your QR code grants others
                    access to receive your calls and initiate calls on your behalf
                </p>
            </div>
            {
                hasAnyLink ? (
                    <div className='col-12 col-md-6 d-flex justify-content-center'>
                        <div className='w-75'>
                            {
                                enableMobileLink ?
                                    <UCButton className="btn-light-blue mb-2">Try Mobile App</UCButton> :
                                    null
                            }
                            {
                                enableWelcomeMessage ?
                                    <UCButton className="btn-light-blue">Resend Welcome Email</UCButton> :
                                    null
                            }
                        </div>
                    </div>
                ) :
                    null
            }
        </div>
    )
}

export default QRInvite