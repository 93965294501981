import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSMSTemplate, updateSMSTemplate } from '../action';
import ButtonWithLoader from '../../../components/ButtonWithLoader';
import { getCustomFieldList } from '../../customFields/actions';
import { Loader } from "../../../components/Loader";
import { toast } from "react-toastify";


const SMSForm = ({ template = null, onSubmit, onCancel }) => {
    const [templateName, setTemplateName] = useState('');
    const [content, setContent] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomFieldList());
    }, [dispatch]);

    useEffect(() => {
        if (template) {
            setTemplateName(template.templateName || '');
            setContent(template.content || '');
        } else {
            setTemplateName('');
            setContent('');
        }
    }, [template]);

    const customFieldList = useSelector(
        (state) => state.customFields.customFieldList
    );
 console.log(customFieldList);
    if (!customFieldList) {
        return (
          <div>
            <Loader />
          </div>
        );
      }
    const customFields =customFieldList.rows
    if (!customFields) {
        return (
          <div>
            <Loader />
          </div>
        );
      }

    const handleKeywordClick = (keyword) => {
        setContent((prevContent) => prevContent + `{${keyword}}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            templateName,
            content
        };

        if (template) {
            console.log(template)
            dispatch(updateSMSTemplate({ id: template.id, data: formData }))
                .then(() => {
                    onSubmit();
                    toast.success('SMS Template updated successfully');
                });
        } else {
            dispatch(createSMSTemplate(formData))
                .then(() => {
                    onSubmit();
                    toast.success('SMS Template created successfully');
                });
        }
    };

    const handleCancel = () => {
        setTemplateName('');
        setContent('');
        onCancel();
    };

    return (
        <Card className="mb-3">
            <CardBody>
                <h3 className="card-title">{template ? 'Edit SMS Template' : 'Create SMS Template'}</h3>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="templateName" className="form-label">Template Name</label>
                        <input
                            type="text"
                            id="templateName"
                            required
                            className="form-control"
                            placeholder="Enter template name"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="templateContent" className="form-label">Template Content</label>
                        <textarea
                            id="templateContent"
                            className="form-control"
                            rows={4}
                            required
                            placeholder="Enter template content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>

                    <div>
                        <h5>Keywords:</h5>
                        <ul>
                            {customFields && customFields.map((customField) => (
                                 <span className="badge badge-outline text-primary" key={customField.id} onClick={() => handleKeywordClick(customField.name)}>{customField.label}{""}</span>
                                  
                            ))}
                        </ul>
                    </div>


                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" className="me-2" onClick={handleCancel}>Cancel</Button>
                        <ButtonWithLoader variant="primary" type="submit">{template ? 'Update' : 'Create'}</ButtonWithLoader>
                    </div>
                </form>
            </CardBody>
        </Card>
    );
};

export default SMSForm;
