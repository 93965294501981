import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    updating:false,
    loading: false,
    error: null,
    profile: null,
    email: null,
  },
  reducers: {
    getProfile:(state) => {
      state.loading = false;
      state.error = null;
    },
    getProfileSuccess: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.email = action.payload.email;
    },
    getProfileError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearProfile: (state)=>{
      state.loading = false;
      state.profile = null;
    },
    updateProfile:(state)=>{
      state.updating = true;
      state.error = null;
    },
    updateProfileSuccess:(state, action)=>{
      state.updating = false;
      state.profile = {
        ...state.profile,
        ...action.payload,
        organization: state.profile.organization,
      };
    },
    updateProfileError: (state, action) => {
      state.updating = false;
      state.error = action.payload;
    },
  },
});

export const {
  getProfile,
  getProfileSuccess,
  getProfileError,
  updateProfile,
  updateProfileSuccess,
  updateProfileError,
  clearProfile,

} = profileSlice.actions;
export default profileSlice.reducer;
