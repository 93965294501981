import React from 'react';

import { IconCheck, IconX } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


import useApi from '@hooks/useApi';

const ConfigVerticalStepper = ({ connectionStatus }) => {
    const navigate = useNavigate();
    const { putRequest } = useApi();
    const { t } = useTranslation();

    const handleSkipRoute = () => {
        putRequest({
            url: 'v1/organizations/update-first-look',
            payload: { firstLook: false },
            successCallback: navigate('/', { replace: true }),
            notifyErrorMessage: 'Something went wrong! Please try again.'
        })
    };

    const steps = [
        { 
            label: 'Sign Up', 
            isCompleted: true, 
            description: t('form_helpers.vertical_stepper_signup_description')
        },
        { 
            label: 'Organization Approval', 
            isCompleted: true, 
            description: t('form_helpers.vertical_stepper_organizationapprove_description')
        },
        { 
            label: 'Call Routing', 
            isCompleted: connectionStatus, 
            description: t('form_helpers.vertical_stepper_call_routing_description'), 
            action: handleSkipRoute 
        },
    ];

    return (
        <div className="uc-stepper">
            <ul className="uc-stepper-list">
                {steps.map((step, index) => (
                    <li
                        key={index}
                        className={`uc-stepper-item ${step.isCompleted ? 'completed' : ''} ${index === steps.length - 1 ? 'last' : ''}`}
                    >
                        <div className={`uc-stepper-icon ${step.isCompleted ? 'completed' : ''}`}>
                            {step.isCompleted ?
                                <IconCheck color="#fff" size={16} stroke={3} /> :
                                <IconX color="#fff" size={16} stroke={3} />}
                        </div>
                        <div className='uc-stepper-content'>
                            <h4 className="uc-stepper-label mb-1">{step.label}</h4>
                            <span className="uc-stepper-desc">{step.description}</span>
                            {
                                step.action && (
                                    <span
                                        className="uc-stepper-action pointing"
                                        onClick={step.action}
                                    >
                                        {connectionStatus ? 'Go to the Dashboard' : 'Skip It'}
                                    </span>
                                )
                            }
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ConfigVerticalStepper;
