import React from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { hideModal } from '@components/reducers/modal';

import EditUserForm from '@modules/shared/forms/EditProfileForm';

const UpdateUserModal = ({
    data: { user, currentPage },
}) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideModal());
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Edit Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditUserForm onTriggerFn={handleClose} initState={user} loading={false} page={currentPage} />
            </Modal.Body>
        </>
    );
};

export default UpdateUserModal;
