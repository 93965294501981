import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// import { API_URL } from "@config/config";

import AxiosInstance from "@config/axiosInterceptors";

import {
  requestStarted,
  requestFailed,
  reportDataSuccess,
  agentReportSuccess,
  distributionReportSuccess,
  userListSuccess
} from "./reducer";

// import AxiosInstance from "../../config/axiosInterceptors";

export const getCallDetailData = createAsyncThunk(
  "reports/getCallDetails",
  async (formdata, { dispatch, rejectWithValue }) => {

    dispatch(requestStarted());
    try {
      const {
        callsType,
        destinationFilter,
        destinationType,
        periodFrom,
        periodTo,
        sourceFilter,
        sourceType
      } = formdata;

      let params = new URLSearchParams({
        periodFrom,
        periodTo,
        callsType,
        sourceType,
        destinationType
      });

      if (sourceType !== "0") {
        params.append('sourceFilter', sourceFilter);
      }

      if (destinationType !== "0") {
        params.append('destinationFilter', destinationFilter);
      }

      const response = await AxiosInstance.get(`v1/3cx/call-report?${params.toString()}`);
      dispatch(reportDataSuccess(response.data.value));
      return response;
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);


export const getCallDIstributionData = createAsyncThunk(
  "reports/getCallDistributionDetails",
  async (formdata, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const {
        date,
        intervalType
      } = formdata;

      let params = new URLSearchParams({
        date,
        intervalType
      });
      const response = await AxiosInstance.get(`3cxdb/distribution-report?${params.toString()}`)
      dispatch(distributionReportSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const getAgentPerformanceData = createAsyncThunk(
  "reports/getAgentPerformanceDetails",
  async (formdata, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const {
        groupNumber,
        periodFrom,
        periodTo,
        callArea
      } = formdata;

      let params = new URLSearchParams({
        groupNumber,
        periodFrom,
        periodTo,
        callArea
      });
      const response = await AxiosInstance.get(`v1/3cx/extension-statistics?${params.toString()}`);
      dispatch(agentReportSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "reports/getUsersList",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.get(`v1/users?page=1&pageSize=100`);
      dispatch(userListSuccess(response.data.rows))
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);