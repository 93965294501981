import React from 'react';

import CreatableSelect from 'react-select/creatable';

import Select from 'react-select';

const customStyles = (lngSwitch, error) => ({
    container: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '4px',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: lngSwitch ? '#006eff0d' : 'var(--tblr-bg-forms)',
        minHeight: '40px',
        boxShadow: 'none',
        border: lngSwitch ? 'none' : error
            ? `var(--tblr-border-width) solid var(--tblr-form-invalid-border-color)`
            : `var(--tblr-border-width) solid var(--tblr-border-color);`,
        ...(lngSwitch
            ? {}
            : {
                '&:hover': {
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                },
            }),
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: 'var(--tblr-bg-forms)',
        boxShadow: '0 1px 5px rgba(0,0,0,.1)',
        borderRadius: '4px',
        marginTop: '4px',
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected
            ? 'rgba(var(--tblr-secondary-rgb),.08)'
            : 'var(--tblr-card-bg)',
        color: 'var(--tblr-body-color)',
        ':hover': { backgroundColor: 'rgba(var(--tblr-secondary-rgb),.08)' },
    }),
    input: (baseStyles) => ({ ...baseStyles, color: 'var(--tblr-body-color)' }),
    singleValue: (baseStyles) => ({ ...baseStyles, color: 'var(--tblr-body-color)', fontSize: lngSwitch ? 14 : undefined, fontWeight: lngSwitch ? 'bold' : undefined }),
    indicatorSeparator: (baseStyles) => ({ ...baseStyles, display: lngSwitch ? 'none' : 'block' }),
    dropdownIndicator: (baseStyles) => ({ ...baseStyles, color: 'var(--tblr-body-color)' }),
});

const UCSelect = ({
    options,
    isSearchable = true,
    placeholder,
    error,
    className,
    value,
    onChangeFn,
    lngSwitch,
    disabled,
    onCreatableFn,
}) => {
    const commonProps = {
        classNames: {
            container: (base) => `${base} ${className}`,
        },
        isSearchable,
        placeholder,
        isDisabled: disabled,
        styles: customStyles(lngSwitch, error),
        options,
        value,
        onChange: (option) => onChangeFn?.(option),
        formatOptionLabel: (value) => lngSwitch ? value?.label?.substr(0, 3)?.toUpperCase() || '' : value?.label,
    };

    return onCreatableFn ? (
        <CreatableSelect
            {...commonProps}
            onCreateOption={onCreatableFn}
        />
    ) : (
        <Select {...commonProps} />
    );
};

export default UCSelect;