import React from 'react'

const UCPlaceholder = ({
    wrapperContainerClasses,
    wrapperClasses,
    innerClasses,
    width,
    height }) => {
    const style = (width || height) ? { width: width || '100%', height: height || '100%' } : {};
    return (
        <div className={wrapperContainerClasses}>
            <div className={`card placeholder-glow ${wrapperClasses}`}>
                <div className={`ratio card-img-top placeholder ${innerClasses}`} style={style} />
            </div>
        </div>
    )
}

export default UCPlaceholder