import React from 'react';

import { formatInterval } from '@utils/utils';

import { IconDownload } from '@tabler/icons-react';
import { CSVLink } from 'react-csv';

const CallDistributionReport = ({ data, intervalType  }) => {

    const calTotal = (outbound, inbound) => {
        const inboundCount = inbound ? inbound : 0;
        const outboundCOunt = outbound ? outbound : 0;
        const total = inboundCount + outboundCOunt;
        return total;
    }

    return (
        <div className='card'>
            {/* <div className="text-end mb-3">
                <CSVLink data={data} filename={"call_detail_report.csv"} className="btn btn-link" style={{ textDecoration: 'none' }}>
                    <IconDownload /><span style={{ fontWeight: 'bold' }}>Download</span>
                </CSVLink>
            </div> */}
            <div className="table-responsive">
                {/* {JSON.stringify(interval, null, 2)} */}
                <table className="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Interval</th>
                            <th className="text-center">
                                <div>Inbound</div>
                                <div className="d-flex flex-row text-center">
                                    <div className="w-100">Answered</div>
                                    <div className="w-100">Unanswered</div>
                                </div>
                            </th>
                            <th className="text-center">
                                <div>Outbound</div>
                                <div className="d-flex flex-row text-center">
                                    <div className="w-100">Answered</div>
                                    <div className="w-100">Unanswered</div>
                                </div>
                            </th>
                            <th className="text-center">
                                <div>Total</div>
                                <div className="d-flex flex-row text-center">
                                    <div className="w-100">Answered</div>
                                    <div className="w-100">Unanswered</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {JSON.stringify(data, null, 2)} */}
                        {data?.map((calldata) => (
                            <tr key={calldata.start_time}>
                                <td>{formatInterval(calldata.start_time, intervalType)}</td>
                                <td className="text-center">
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">{calldata.inbound_answered ? calldata.inbound_answered : 0}</div>
                                        <div className="w-100">{calldata.inbound_unanswered ? calldata.inbound_unanswered : 0}</div>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">{calldata.outbound_answered ? calldata.outbound_answered : 0}</div>
                                        <div className="w-100">{calldata.outbound_unanswered ? calldata.outbound_unanswered : 0}</div>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">{calTotal(calldata.inbound_answered, calldata.outbound_answered)}</div>
                                        <div className="w-100">{calTotal(calldata.inbound_unanswered, calldata.outbound_unanswered)}</div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CallDistributionReport;
