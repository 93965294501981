import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import ButtonWithLoader from '@components/ButtonWithLoader';
import useApi from '@hooks/useApi';

const UserConfigTransfer = ({ className, onChangeRouteUI }) => {
    const { t } = useTranslation();
    const { postRequest, loading, putRequest, getRequest } = useApi();
    const { handleSubmit, register, formState: { errors } } = useForm();
    const [latestUser, setLatestUser] = useState(null); 

    console.log(latestUser);
    const onSendEmail = (data) => {
        const emailPayload = { email: data.email, role: 'orgAdmin' };

        putRequest({
            url: 'v1/organizations/first-look-status',
            payload: { firstLookStatus: 0 },
        }).then(() => {
            return postRequest({
                url: 'v1/users/invite',
                payload: { invitations: [emailPayload], did: true },
                notifySuccessMessage: 'Invitation sent successfully',
                notifyErrorMessage: 'Failed to send invitation',
            });
        }).then(() => {
            return putRequest({
                url: 'v1/organizations/routing-option',
                payload: { routingOption: 'admin' },
            });
         })
        .then(() => {
            return getRequest({
                url: 'v1/users/latest-user',
            });
        }).then((response) => {
            console.log("response",response[0]);
            setLatestUser(response[0]); 
            onChangeRouteUI?.();

        }).catch((error) => {
            console.error('Error in the process:', error);
        });
    };

    useEffect(() => {
        const fetchLatestUser = async () => {
            try {
                const res = await getRequest({ url: 'v1/users/latest-user' });
                console.log("res",res)
                setLatestUser(res[0]); 
            } catch (error) {
                console.error('Error fetching the latest user:', error);
            }
        };
    
        fetchLatestUser();
    }, []); 
    
    return (
        <div className={className}>
            <div className='mb-4'>
                <h4 className='mb-0'>{t("form_helpers.user_routing_config")}</h4>
                <p className="text-secondary mb-0">
                    {t("form_helpers.user_routing_config_description")}
                </p>
            </div>
            <div>
                <form onSubmit={handleSubmit(onSendEmail)}>
                    <div className="row gy-2">
                        <div className="col-9">
                            <input
                                type="text"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                placeholder="Enter Email"
                                name={'email'}
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email.message}</div>
                            )}
                        </div>
                        <div className="col-3">
                            <ButtonWithLoader
                                className="w-100"
                                loading={loading}
                                type="submit"
                            >
                                Submit
                            </ButtonWithLoader>
                        </div>
                    </div>
                </form>
            </div>
            <br/>
            {latestUser && (

                <div className="mt-4">
                    <p> DID assign to:</p>
                    <div key={latestUser.id} className='mb-2 d-flex align-items-center'>
                        <div className="avatar avatar-rounded placeholder me-2 avatar-placeholder"></div>
                        <div>
                            <span className='text-reset d-block text-truncate'>
                                {latestUser.isVerified ? `${latestUser.firstName} ${latestUser.lastName}` : latestUser.email}
                            </span>                            <div className='d-flex align-items-center'>
                                <div className={`text-secondary text-truncate mt-n1 me-2 ${latestUser.isVerified ? 'text-lime' : 'text-yellow'}`}>
                                    {/* {getStatus(isVerified) */}
                                    {latestUser.isVerified ? "Invite accepted" : "Invitation pending"}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserConfigTransfer;
