import React from 'react'

import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import InviteUserModal from '@layouts/modal/InviteUserModal'
import LanguageModal from '@layouts/modal/LanguageModal'
import TermAndConditionModal from '@layouts/modal/TermAndConditionModal'
import UpdateUserModal from '@layouts/modal/UpdateUserModal'

import { hideModal } from '../reducers/modal'

const UCModal = () => {
    const dispatch = useDispatch()
    const { isVisible, modalContentKey, data } = useSelector(state => state.modal)

    const handleClose = () => {
        dispatch(hideModal())
    }

    const getModalContentViaKey = () => {
        switch (modalContentKey) {
            case 'lang-modal':
                return <LanguageModal />;
            case 'terms-modal':
                return <TermAndConditionModal />;
            case 'invite-user-modal':
                return <InviteUserModal />;
            case 'update-user-modal':
                return <UpdateUserModal data={data}/>;
            default:
                return null
        }
    }

    return (
        <Modal
            show={isVisible}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modal modal-blur fade"
            centered
        >
            {getModalContentViaKey()}
        </Modal>
    )
}

export default UCModal