import React, { useMemo } from 'react'

import { IconUser, IconUsersGroup, IconSubtask } from '@tabler/icons-react'

import { isMobileScreen } from '@utils/utils'

const FirstLookTabPill = ({
    index,
    tabSelected,
    setTabSelected,
    title,
    description,
    collapsedContent
}) => {

    const getIcon = useMemo(() => {
        switch (index) {
            case 0:
                return <IconUser size={28} />
            case 1:
                return <IconUsersGroup size={28} />
            case 2:
                return <IconSubtask size={28} />
            default:
                return null
        }
    }, [index])

    return (
        <div className='col-md-4' key={index}>
            <div className={`${!isMobileScreen() && "h-100"} card pointing`} onClick={setTabSelected}>
                <div className={`${!isMobileScreen() && "d-flex align-items-center"} card-body py-4 py-md-2`}>
                    <div className='d-flex align-items-center'>
                        <div>
                            <input
                                type="radio"
                                className="form-check-input"
                                checked={tabSelected}
                                onChange={setTabSelected}
                            />
                        </div>
                        <div className='ms-2'>
                            {getIcon}
                        </div>
                        <div className='ms-2'>
                            <h4 className='mb-0'>{title}</h4>
                            <p className="text-secondary mb-0">{description}</p>
                        </div>
                    </div>
                    {collapsedContent}
                </div>
            </div>
        </div>
    )
}

export default FirstLookTabPill