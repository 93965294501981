import React from "react";
import Page from "../../../layouts/Page";
import ImportDropzone from "../widgets/ImportDropzone";

const ImportContact = () => {
  

  return (
    <Page title={"Import Contacts"}>
      <ImportDropzone/>
    </Page>
  );
};

export default ImportContact;
