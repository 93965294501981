import { Card, CardBody, CardTitle, Button } from "react-bootstrap";
import axios from "axios";

import Page from "@layouts/Page";
import { useState } from "react";

export default function WhatsappTemplate() {
const [accessTokenn, setAccessTokenn] = useState(''); // [1]

  // 336493562870208
  function launchWhatsAppSignup() {
    // Launch Facebook login
    window.FB.login(function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.code;
        console.log("accessToken", accessToken);
        setAccessTokenn(accessToken);
        //Use this token to call the debug_token API and get the shared WABA's ID
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '920967429655053', // configuration ID obtained in the previous step goes here
      response_type: 'code',     // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        setup: {
          //   ... // Prefilled data can go here
        }
      }
    });
  }

  function checkLoginStatus(){
    window.FB.getLoginStatus(function(response) {
      console.log(response);
      if (response.status === 'connected') {
        // the user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token 
        // and signed request each expire
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
        console.log('accessToken', accessToken);
        console.log('uid', uid);
      } else if (response.status === 'not_authorized') {
        // the user is logged in to Facebook, 
        // but has not authenticated your app
      } else {
        // the user isn't logged in to Facebook.
      }
     });
  }

  const debugToken = async () => {
    console.log(accessTokenn);
    try {
      const response = await axios.get(`https://graph.facebook.com/v19.0/debug_token?input_token=${accessTokenn}`);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = async (recipientPhoneNumber, messageText) => {
    const wabaId = '336493562870208';

    try {
      const response = await axios.post(
        `https://graph.facebook.com/v19.0/${wabaId}/messages`,
        {
          messaging_product: 'whatsapp',
          to: recipientPhoneNumber,
          type: 'text',
          text: {
            body: messageText,
          },
        },
        {
          headers: {
            Authorization: `Bearer 357538173805425|A2ijOfVfBlhTR1piiyVdFu-8jsE`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getWABAId = async () => {
    console.log(accessTokenn);

    try {
      const response = await axios.get(`https://graph.facebook.com/me/messenger_codes?access_token=${accessTokenn}`);
      console.log('WABA response:', response);
      const wabaId = response.data.data.find(code => code.renderingType === 'whatsapp').id;
      console.log('WABA ID:', wabaId);
    } catch (error) {
      console.error(error);
    }
  };
  

  return <Page title={"WhatsApp Templates"}>

    <Card className="mb-3">
      <CardBody>
        <CardTitle><h3>WhatsApp Template Page</h3></CardTitle>
        <Button variant="green" onClick={() => launchWhatsAppSignup()}>Login with Facebook</Button>
        <Button variant="green" onClick={() => checkLoginStatus()}>Check</Button>
        <Button variant="green" onClick={() => debugToken()}>debug</Button>
        <Button variant="green" onClick={() => getWABAId()}>getWABAId</Button>
        <Button variant="green" onClick={() => sendMessage('+94789307370', 'Hello')}>Send</Button>
      </CardBody>
    </Card>

  </Page>
}