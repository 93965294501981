import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "sms",
    initialState: {
        loading: false,
        error: null,
        resetting: false,
        smsTemplate:null,
    },
    reducers: {
        getSMSTemplateSuccess: (state, action) => {
            state.loading = false;
            state.smsTemplate = action.payload;
        },
        getSMSTemplateError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    getSMSTemplateSuccess,
    getSMSTemplateError,
} = userSlice.actions;
export default userSlice.reducer;
