import React from 'react';

import EditUserForm from '@modules/shared/forms/EditProfileForm';

function UpdateAgent({ user, currentPage, onFinish }) {
    const page = currentPage;

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Edit Profile</h5>
                <EditUserForm onFinish={onFinish} initState={user} loading={false} page={page}/>
            </div>
        </div>
    );
}

export default UpdateAgent;
