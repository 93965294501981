import React from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import InviteAgentFormComponent from '@components/forms/InviteAgentFormComponent';
import { hideModal } from '@components/reducers/modal';

const InviteUserModal = () => {

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideModal());
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Invite Agent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InviteAgentFormComponent onSubmitTriggerFn={handleClose}/>
            </Modal.Body>
        </>
    );
};

export default InviteUserModal;
