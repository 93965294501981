import React, { useState, useEffect } from "react";

import {
    IconPhoneCall,
    IconPhoneIncoming,
    IconPhoneOutgoing
} from "@tabler/icons-react";

import StatCardPlaceholder from "@components/StatCardPlaceholder";
import StatCard from "./StatCard";

export default function DailyStats({ data }) {
    const [statsData, setStatsData] = useState({
        totalCalls: 0,
        InboundAnswered: 0,
        InboundUnanswered: 0,
        OutboundAnswered: 0,
        OutboundUnanswered: 0
    });

    const [isDataAvailable, setIsDataAvailable] = useState(false);

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const aggregateStats = data.reduce((acc, curr) => {
                acc.InboundAnswered += curr.inbound_answered || 0;
                acc.InboundUnanswered += curr.inbound_unanswered || 0;
                acc.OutboundAnswered += curr.outbound_answered || 0;
                acc.OutboundUnanswered += curr.outbound_unanswered || 0;
                return acc;
            }, {
                InboundAnswered: 0,
                InboundUnanswered: 0,
                OutboundAnswered: 0,
                OutboundUnanswered: 0
            });

            const totalCalls = aggregateStats.InboundAnswered +
                aggregateStats.InboundUnanswered +
                aggregateStats.OutboundAnswered +
                aggregateStats.OutboundUnanswered;

            setStatsData({
                totalCalls,
                InboundAnswered: aggregateStats.InboundAnswered,
                InboundUnanswered: aggregateStats.InboundUnanswered,
                OutboundAnswered: aggregateStats.OutboundAnswered,
                OutboundUnanswered: aggregateStats.OutboundUnanswered
            });

            setIsDataAvailable(true);
        } else {
            setIsDataAvailable(false);
        }
    }, [data]);

    const placeholderCard = (
        <div className="card">
            <div className="card-body text-end placeholder-glow">
                <div className="placeholder col-9 mb-3"></div>
                <div className="placeholder placeholder-xs col-10"></div>
                <div className="placeholder placeholder-xs col-12"></div>
                <div className="placeholder placeholder-xs col-11"></div>
                <div className="placeholder placeholder-xs col-8"></div>
                <div className="placeholder placeholder-xs col-10"></div>
            </div>
        </div>
    );

    return (
        <div className="d-flex flex-column gap-2">
            <div className="row g-2">
                <div className="col-md-4">
                    {isDataAvailable ? (
                        <StatCard
                            icon={
                                <IconPhoneCall
                                    size={32}
                                    strokeWidth={1}
                                    color={'#4060bf'}
                                />
                            }
                            total={statsData.totalCalls}
                            title={'Total Calls'}
                            answered={statsData.InboundAnswered + statsData.OutboundAnswered}
                            unanswered={statsData.InboundUnanswered + statsData.OutboundUnanswered}
                        />
                    ) : (
                        <StatCardPlaceholder/>
                    )}
                </div>
                <div className="col-md-4">
                    {isDataAvailable ? (
                        <StatCard
                            icon={
                                <IconPhoneIncoming
                                    size={32}
                                    strokeWidth={1}
                                    color={'#4060bf'}
                                />
                            }
                            total={statsData.InboundAnswered + statsData.InboundUnanswered}
                            title={'Inbound Calls'}
                            answered={statsData.InboundAnswered}
                            unanswered={statsData.InboundUnanswered}
                        />
                    ) : (
                        <StatCardPlaceholder/>
                    )}
                </div>
                <div className="col-md-4">
                    {isDataAvailable ? (
                        <StatCard
                            icon={
                                <IconPhoneOutgoing
                                    size={32}
                                    strokeWidth={1}
                                    color={'#4060bf'}
                                />
                            }
                            total={statsData.OutboundAnswered + statsData.OutboundUnanswered}
                            title={'Outbound Calls'}
                            answered={statsData.OutboundAnswered}
                            unanswered={statsData.OutboundUnanswered}
                        />
                    ) : (
                        <StatCardPlaceholder/>
                    )}
                </div>
            </div>
        </div>
    );
}
