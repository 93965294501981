
import { Routes, Route } from "react-router-dom";

import DashboardRootLayout from "@layouts/DashboardRootLayout";

import useAuthRedirect from "@hooks/useAuthRedirect";

import Dashboard from "@modules/dashboard/views";

import AppRoutes from "@routes/AppRoutes";
import AuthRoutes from "@routes/AuthRoutes";


const App = () => {
  // This is a custom hook that redirects the user to the login page if they are not authenticated.
  const isLoading = useAuthRedirect();

  // const ws = new WebSocket("http://13.212.170.35:3002");

  // ws.onopen = () => {
  //   console.log("connected");
  // };
  // ws.onmessage = (message) => {
  //   console.log(message.data);
  // };
  // ws.onclose = () => {
  //   console.log("disconnected");
  // };

  if (isLoading) {
    return null;
  }

  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/" element={<DashboardRootLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/*" element={<AppRoutes />} />
      </Route>
    </Routes>
  );
}

export default App;
