import { Link } from "react-router-dom";
import { IconEdit, IconPlayerPause, IconReport, IconTrash, IconX } from "@tabler/icons-react";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import CampaignGraph from "../widgets/CampaignGraph";

export default function CampaignView({ campaign, onClose }) {
    return <Card>
        <Card.Body>
            <div className="d-flex justify-content-between align-items-start mb-3">
                <h3 className="card-title mb-0">{campaign.name}</h3>
                <div className="ms-auto d-md-none">
                    {/* <Link to="." onClick={() => { }} className="icon"><IconReport /></Link>
                    <Link to="." onClick={() => { }} className="icon"><IconPlayerPause /></Link>
                    <Link to="." onClick={() => { }} className="icon"><IconEdit /></Link> */}
                    <Link to="." onClick={() => { onClose() }} className="icon"><IconX /></Link>
                </div>
            </div>
            <Row>
                <Col>
                    <div className="datagrid">
                        <div className="datagrid-item">
                            <div className="datagrid-title">Channel</div>
                            <div className="datagrid-content">Voice</div>
                        </div>
                        <div className="datagrid-item">
                            <div className="datagrid-title">Status</div>
                            <div className="datagrid-content"><Badge bg="success" text="success-fg">Running</Badge></div>
                        </div>
                        <div className="datagrid-item">
                            <div className="datagrid-title">Start Date and Time</div>
                            <div className="datagrid-content">{campaign.start_date}</div>
                        </div>
                        <div className="datagrid-item">
                            <div className="datagrid-title">End Date and Time</div>
                            <div className="datagrid-content">-</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Card.Body>
        <CampaignGraph />
        <table className="table card-table table-vcenter">
            <thead>
                <tr>
                    <th>Status</th>
                    <th colspan="2">Contacts</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Success</td>
                    <td>3,550</td>
                    <td className="w-50">
                        <div className="progress progress-xs">
                            <div className="progress-bar bg-success" style={{ "width": "71.0%" }}></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>In Progress</td>
                    <td>1,798</td>
                    <td className="w-50">
                        <div className="progress progress-xs">
                            <div className="progress-bar bg-warning" style={{ "width": "35.96%" }}></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Failed</td>
                    <td>1,245</td>
                    <td className="w-50">
                        <div className="progress progress-xs">
                            <div className="progress-bar bg-danger" style={{ "width": "24.9%" }}></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div className="card-footer bg-transparent mt-auto">
            <Row>
                <Col><Button variant="outline-primary" className="w-100"><IconEdit/> <span className="d-none d-md-inline ms-2">Edit</span></Button></Col>
                <Col><Button variant="outline-primary" className="w-100"><IconPlayerPause/> <span className="d-none d-md-inline ms-2">Pause</span></Button></Col>
                <Col><Button variant="outline-primary" className="w-100"><IconReport/><span className="d-none d-md-inline ms-2"> View Report</span></Button></Col>
                <Col><Button variant="outline-danger" className="w-100"><IconTrash/> <span className="d-none d-md-inline ms-2">Delete</span></Button></Col>
            </Row>
        </div>
    </Card>
}