import { createSlice } from "@reduxjs/toolkit"

const loadingSlice = createSlice({
    name: "loading",
    initialState: {
        loading: false,
    },
    reducers: {
        startLoader: (state) => {
            state.loading = true;
        },
        endLoader: (state, action) => {
            state.loading = false;
        },
    },
});

export const {
    startLoader,
    endLoader,
} = loadingSlice.actions;
export default loadingSlice.reducer;