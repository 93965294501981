import { useState } from "react";

import { useDispatch } from "react-redux";

import { showToast } from "@components/reducers/toast";

import AxiosInstance from "@config/axiosInterceptors";

const useApi = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const autoCloseTime = 4000;

    const handleRequest = async (method,
        {
            url,
            payload = null,
            headers = {},
            notifySuccessMessage = null,
            notifyErrorMessage = null,
            successCallback = null,
            errorCallback = null,
            completeCallback = null
        }
    ) => {
        setLoading(true);

        try {
            const response = await AxiosInstance({
                method,
                url,
                data: payload,
                headers
            });
    
            const responseData = response?.data;
            setData(responseData);

            if (responseData && notifySuccessMessage) {
                dispatch(showToast({ message: notifySuccessMessage, type: "success", autoClose: autoCloseTime }));
                successCallback?.()
            }
            return responseData;
        } catch (error) {
            setError(error);
            console.log("axios error",error);
            const errorMessage = notifyErrorMessage ?? error?.response?.data?.message;
            if (errorMessage) {
                dispatch(showToast({ message: errorMessage, type: "error", autoClose: autoCloseTime }));
                errorCallback?.()
            }
        } finally {
            setLoading(false);
            completeCallback?.();
        }
    };

    const getRequest = (params) => handleRequest("get", params);
    const postRequest = (params) => handleRequest("post", params);
    const putRequest = (params) => handleRequest("put", params);
    const patchRequest = (params) => handleRequest("patch", params);


    return { loading, error, data, getRequest, postRequest, putRequest, patchRequest };
};

export default useApi;
