import { useEffect, useState } from 'react';

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from '@components/ButtonWithLoader';

const UpdateUserForm = ({ handleUpdate, loading }) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, trigger } = useForm();

    const onSubmit = (data) => {
        const { confirmPassword, ...restObj } = data;
        handleUpdate?.(restObj);
    };

    const handleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <form method="post"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.first_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.firstName && 'is-invalid'}`}
                    {...register('firstName', { required: true })}
                    placeholder={t("form_fields.first_name.placeholder")}
                    autoComplete="off"
                />
                {errors.firstName && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.last_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.lastName && 'is-invalid'}`}
                    {...register('lastName', { required: true })}
                    placeholder={t("form_fields.last_name.placeholder")}
                    autoComplete="off"
                />
                {errors.lastName && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.phone.label")}
                </label>
                <input
                    type="tel"
                    className={`form-control ${errors.phone && 'is-invalid'}`}
                    {...register('phone', { required: true, pattern: /^[+]?[\d]+$/ })}
                    placeholder={t("form_fields.phone.placeholder")}
                    autoComplete="off"
                />
                {errors.phone && <div className="invalid-feedback">
                    {t("form_validations.invalid_phone")}
                </div>}
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.password && 'is-invalid'}`}
                        {...register('password', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: t("form_validations.strong_password")
                            }
                        })}
                        placeholder={t("form_fields.password.placeholder")}
                        autoComplete="off"
                    />
                        <span className="input-group-text text-center">
                            <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                                {showPassword ? <IconEyeOff /> : <IconEye />}
                            </span>
                        </span>
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                </div>
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.confirm_password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                        {...register('confirmPassword', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            validate: value =>
                                value === watch('password') || t("form_validations.password_mismatch")
                        })}
                        placeholder={t("form_fields.confirm_password.placeholder")}
                        autoComplete="off"
                    />
                        <span className="input-group-text text-center">
                            <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                                {showPassword ? <IconEyeOff /> : <IconEye />}
                            </span>
                        </span>
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                </div>
            </div>

            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    {loading ? t('form_actions.update_loading') : t('form_actions.update')}
                </ButtonWithLoader>
            </div>
        </form>
    );
};

export default UpdateUserForm;