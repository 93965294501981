import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { showModal } from "@components/reducers/modal";
import UCFormCommonError from "@components/theme/UCFormCommonError";

import { userSignup } from "../actions";
import SignUpForm from "../forms/SignUpForm";
import { requestFailed } from "../reducer";

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { error, loading } = useSelector(state => state.auth);
  const [agreed, setAgreed] = useState(false);

  const handleAgreedChange = () => {
    setAgreed(prevAgreed => !prevAgreed);
  };

  const handleSignUp = (data) => {
    dispatch(userSignup(data))?.then((response) => {
      if (response.payload.userId) {
        navigate("/auth/verify-email", { state: { email: data.email } });
      }
    });
  };

  const showLangModal = () => {
    dispatch(showModal({
      modalContentKey: 'terms-modal'
    }))
  }

  return (
    <AuthPageRootLayout containerClassName="container-tight">
      <div className="card card-md">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">
            {t("form_headers.signup")}
          </h2>
          {error && <UCFormCommonError error={error}
            clearFn={() => dispatch(requestFailed(null))} />}
          <SignUpForm
            handleSignUp={handleSignUp}
            loading={loading}
            handleAgreedChange={handleAgreedChange}
            agreed={agreed}
            termsOnPress={showLangModal}
          />
          <div className="text-center text-muted mt-3">
            {t("form_helpers.login_link_prefix")}{" "}
            <Link to="/auth/login">{t("form_helpers.login_link")}</Link>
          </div>
        </div>
      </div>
    </AuthPageRootLayout>
  );
}
export default SignUp;
