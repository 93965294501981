import React from "react";

const StatCardPlaceholder = () => {
    return (
        <div className="card">
            <div className="card-body text-end placeholder-glow">
                <div className="placeholder col-9 mb-3"></div>
                <div className="placeholder placeholder-xs col-10"></div>
                <div className="placeholder placeholder-xs col-12"></div>
                <div className="placeholder placeholder-xs col-11"></div>
                <div className="placeholder placeholder-xs col-8"></div>
                <div className="placeholder placeholder-xs col-10"></div>
            </div>
        </div>
    );
};

export default StatCardPlaceholder;
