import { IconPlayerPlayFilled, IconTrash, IconUpload } from "@tabler/icons-react";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { promptUpload, deletePrompt } from "../../actions";
import Select from 'react-select';

export default function VoicePrompt(props) {

    const dispatch = useDispatch();
    const { data: promptList, onSelectPrompt, promptFilename } = props;
    const [selectedPrompt, setSelectedPrompt] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [delPrompt, setDeletePrompt] = useState(null);
    const [show, setShow] = useState(false);
   
    

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setSelectedPrompt("");
        if (file) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            try {
                await dispatch(promptUpload(formData))
            } catch (error) {
                console.error("failed:", error.message);
            }
        }
    };

    const handleUploadButtonClick = () => {
        document.getElementById('file-upload').click();
    };

    let transformedOptions = [];
    if (Array.isArray(promptList)) {
        transformedOptions = promptList.map(option => ({
            value: option.Filename,
            label: option.Filename,
            CanBeDeleted: option.CanBeDeleted
        }));

    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
        }),
    };

    const handleShow = (event, option) => {
        setDeletePrompt(option);
        event.stopPropagation();
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = async (event) => {
        setShow(false);
        try {
            dispatch(deletePrompt(delPrompt.value));
        } catch (error) {

        }
    }
    const handleSelect = (data) => {
        if (data) {
            const selectedIndex = data;
            setSelectedOption(selectedIndex)
            onSelectPrompt(data.value);
        } else {
            setSelectedOption(null)
        }
    };

    const deleteModel = (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation required</Modal.Title>
                </Modal.Header>
                <Modal.Body>Confirm deletion of prompt?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleDelete}>
                        OK
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );

    return (
        <div>
            <Select
                options={transformedOptions}
                styles={customStyles}
                isSearchable={true}
                isClearable={true}
                defaultValue={transformedOptions.find(option => option.value === promptFilename)}
                getOptionLabel={option => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <span style={{ marginLeft: '8px' }}>{option.label}</span>
                        {(!selectedOption || (selectedOption && option.value !== selectedOption.value)) && option.CanBeDeleted ? (
                            <span style={{ flex: '1', textAlign: 'right' }}>
                                <button onClick={(event) => { handleShow(event, option) }} style={{ borderColor: 'white', borderStyle: 'solid', backgroundColor: 'transparent' }}><IconTrash stroke={3} size={20} color="gray" /></button>
                            </span>
                        ) : null}
                    </div>
                )}
                onChange={handleSelect}
            />
            <Button onClick={handleUploadButtonClick}>
                {selectedPrompt === "" ?
                    <span>Upload WAV File <IconUpload stroke={3} size={20} /></span> :
                    <IconPlayerPlayFilled size={20} />}
            </Button>
            <input id="file-upload" type="file" accept=".wav" style={{ display: 'none' }} onChange={handleFileChange} />
            {deleteModel}
        </div>

    );
}