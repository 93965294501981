import React from 'react';

import { Controller } from 'react-hook-form';

import UCSelect from './UCSelect';

const UCFormSelect = ({
    options,
    isSearchable = true,
    placeholder,
    name,
    control,
    rules,
    className,
    onChangeAdditionalFn,
    forcedValue,
    disabled,
    onCreatableFn
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <UCSelect
                    options={options}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    error={error}
                    className={className}
                    value={forcedValue ?? value}
                    disabled={disabled}
                    onCreatableFn={onCreatableFn}
                    onChangeFn={(option) => {
                        onChange(option);
                        onChangeAdditionalFn?.(option);
                    }}
                />
            )}
        />
    );
};

export default UCFormSelect;