import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

import NavigationDrawer from "@components/NavigationDrawer";
import NavigationHeader from "@components/NavigationHeader";
import { endLoader } from "@components/reducers/loading";

import usePreventBack from "@hooks/usePreventBack";

import { userLogout } from "@modules/auth/actions";

function DashboardRootLayout() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [isMenuVisible, toggleMenu] = useState(false);
  const userProfile = useSelector((state) => state.profile.profile);
  usePreventBack();
  
  useEffect(() => {
    if (!token) {
      return <Navigate to="/auth/login" />;
    }
    dispatch(endLoader());
  }, []);

  const handleLogout = () => {
    dispatch(userLogout());
    <Navigate to="/auth/login" />;
  };

  return (
    <div className={`home page ${isMenuVisible ? "show-menu" : ""}`}>
      <NavigationHeader
        fromEntryPage={false}
        fromDashboard={true}
        toggleMenu={toggleMenu}
        handleLogout={handleLogout}
        isMenuVisible={isMenuVisible}
        userProfile={userProfile} />
      <NavigationDrawer toggleMenu={toggleMenu} handleLogout={handleLogout} />
      <Outlet />
    </div>
  );
}

export default DashboardRootLayout;
