import React, { useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { showToast } from "@components/reducers/toast";

import { resetPassword } from "@modules/users/actions";

import ForgetPasswordForm from "../forms/ForgetPasswordForm";

function ForgotPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.users.loading);
  const [email] = useState(location.state?.email || "");
  const { t } = useTranslation()

  const handleSendOTP = (data) => {
    dispatch(resetPassword(data)).then((response) => {
      if (response.payload.code === 200) {
        navigate("/auth/verify-email", { state: { email: data.email, verifyMode: 1 } });
      } else {
        dispatch(showToast({ message: t('alert_messages.otp_send_failed'), type: "error", autoClose: 4000 }))
      }
    });
  };

  return (
    <AuthPageRootLayout containerClassName="container-tight py-4 px-3">
      <div className="card card-md">
        <div className="card-body">
          <ForgetPasswordForm
            handleForgetPassword={handleSendOTP}
            loading={loading}
            formData={{email: email}} />
        </div>
      </div>
      <div className="text-center text-muted mt-3">
              {t("form_helpers.remember_password")}{" "}
              <Link to="/auth/login">{t("form_helpers.login_link")}</Link>
            </div>
    </AuthPageRootLayout>
  );
}
export default ForgotPassword;
