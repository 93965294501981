import { IconSearch } from "@tabler/icons-react";
import { Col, Row } from "react-bootstrap";

function Page({ children, title, subtitle, buttons, onSearch }) {
    const handleSearch = (e) => {
        const query = e.target.value;
        onSearch(query);
      };
    return <div className="page-wrapper">
        <div className="sticky-top bg-body">
            <div className="page-header px-3">
                <Row className="align-items-center">
                    <Col sm="12" lg="6">
                        <h2 className="page-title">
                            {title}
                        </h2>
                        <div className="text-secondary mt-1">{subtitle}</div>
                    </Col>
                    {buttons || typeof onSearch === "function" ? <Col sm="12" lg="6">
                        <div className="d-flex flex-column flex-lg-row justify-content-end">
                            {typeof onSearch === "function" ? <div className="mt-3 mt-md-auto me-md-3">
                                <div className="input-icon">
                                    <input type="text" className="form-control" placeholder="Search…" onChange={handleSearch}/>
                                    <span className="input-icon-addon">
                                        <IconSearch />
                                    </span>
                                </div>
                            </div> : ""}
                            <div className="btn-list my-3 my-md-auto d-grid gap-2 d-md-block">
                                {buttons}
                            </div>
                        </div>
                    </Col> : ""}
                </Row>
            </div>
        </div>
        <div className="page-body mt-0 mt-md-3">
            <div className="container-fluid">
                {children}
            </div>
        </div>
    </div>
}

export default Page;