import { createAsyncThunk } from '@reduxjs/toolkit';
import { customFields, getCustomFieldsSuccess, getCustomFieldsError, createCustomFieldSuccess, createCustomFieldError, updateCustomFieldSuccess, updateCustomFieldError, deleteCustomFieldSuccess, deleteCustomFieldError } from './reducer';
import AxiosInstance from '../../config/axiosInterceptors';

export const getCustomFieldList = createAsyncThunk('customFields/getCustomFieldList', async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch (customFields());
      const response = await AxiosInstance.get('v1/custom-fields');
      dispatch(getCustomFieldsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getCustomFieldsError(error.message));
      return rejectWithValue(error.message);
    }
  });

  export const createCustomField = createAsyncThunk('customFields/createCustomField', async (CutomFieldData, { dispatch, rejectWithValue }) => {
    try {
      dispatch (customFields());
      const response = await AxiosInstance.post('v1/custom-fields', CutomFieldData);
      dispatch(createCustomFieldSuccess(response.data));
      dispatch(getCustomFieldList());
      return response.data;
    } catch (error) {
      dispatch(createCustomFieldError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

  export const updateCustomField = createAsyncThunk('customFields/updateCustomField', async ({ id, editCustomFieldData }, { dispatch, rejectWithValue }) => {
    try {
      dispatch (customFields());
      const response = await AxiosInstance.put(`v1/custom-fields/${id}`, editCustomFieldData);
      dispatch(updateCustomFieldSuccess(response.data));
      dispatch(getCustomFieldList());
      return response.data;
    } catch (error) {
      dispatch(updateCustomFieldError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

  export const deleteCustomField = createAsyncThunk('customFields/deleteCustomField', async ( id, { dispatch, rejectWithValue }) => {
    try {
      dispatch (customFields());
      const response = await AxiosInstance.delete(`v1/custom-fields/${id}`, id);
      dispatch(deleteCustomFieldSuccess(response.data));
      await dispatch(getCustomFieldList());
      return response.data;
    } catch (error) {
      dispatch(deleteCustomFieldError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });