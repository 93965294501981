import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Avatar from '../../../components/Avatar';
import QR from "../../../assets/images/QR.png";
import { changeUserRole } from "../actions";
import Select from "react-select";
import {toast} from 'react-toastify';


export default function Profile({ user }) {
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState(null); 
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const fetchedRoles = [
            { value: "orgAdmin", label: "Admin" },
            { value: "agent", label: "Agent" }
        ];
        setRoles(fetchedRoles);

        setSelectedRole(fetchedRoles.find(role => role.value === user.role));
    }, [user]); 
    const handleRoleChange  = async (selectedOption) => {
        const newRole = selectedOption.value;
        const id = user.id;
        console.log(newRole)
        setSelectedRole(selectedOption); 
        const res=  await dispatch(changeUserRole({id, newRole}));
        if(res.payload){
            toast.success("Role Updated")
        }else{
            toast.error("Role Update Failed!")
        }
         
        console.log(res);
    };

    return (
        <div className="card flex justify-content-center">
            <div className="row align-items-center">
                <div>
                    <div className="text-center mt-3">
                        <br /> <br /><br /> <br />
                        <Avatar name={user.firstName + " " + user.lastName} status={"available"} style={{ width: '100px', height: '100px' }} />
                        <br /> <br />
                        <div className="text-reset">{user.firstName} {user.lastName}</div>
                        <div className="text-muted">{user.email}</div>
                        <div className="text-muted">1000</div>

                        <br/>
                        <div className="text-muted">
                            {user.role === "owner" ? (
                                <span className="badge bg-green-lt">Owner</span>
                            ) : user.role === "orgAdmin" ? (
                                <span className="badge bg-orange-lt">Admin</span>
                            ) : user.role==="agent"?(
                                <span className="badge bg-purple-lt">Agent</span>
                            ) : null
                        }                        </div>
                        {user.role !== "owner" && (
                            <div className="text-center mt-3">
                                <div className="row justify-content-center">
                                    <div className="row justify-content-center">
                                        <Select className="col-sm-1 col-md-5" options={roles} value={selectedRole} onChange={handleRoleChange} />
                                    </div>
                                </div>
                            </div>
                        )}
                        <br /> <br />
                        <img src={QR} alt="QR Code" style={{ width: '100px', height: '100px' }} />
                        <br /> <br />
                        <div className="text-reset mt-3">
                            <p>Sharing your QR code <br />grants others access to receive your calls and  <br />initiate calls on your behalf.</p>
                        </div>
                        <br /> <br /> <br /> <br />
                        <a href="#" className="btn btn-outline-primary w-100 mt-3">
                            Try Mobile App
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
