import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { API_URL } from "@config/config";

import {
    getQueueListSuccess,
    getQueueListError,
    deleteSuccess
} from "./reducer";

import AxiosInstance from "../../config/axiosInterceptors";


export const getQueueList = createAsyncThunk('user/getQueueList', async (_, { dispatch, rejectWithValue }) => {
    try {
        console.log("action");
      const response = await AxiosInstance.get(`v1/3cx/queue`);
      console.log(response.data);
      dispatch(getQueueListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getQueueListError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
  );


  export const deleteQueue = createAsyncThunk(
    "users/deleteUser",
    async ({ id }, { dispatch, rejectWithValue }) => {
      try {
        const response = await AxiosInstance.delete(`v1/3cx/queue`,{data :{id}});
        dispatch(deleteSuccess(response.data));
        await dispatch(getQueueList());
        return response;
      } catch (error) {
        // dispatch(requestFailed(error.message));
        return rejectWithValue(error.message);
      }
    }
  );