import { useDispatch } from "react-redux";

import useLanguage from "@hooks/useLanguage";

import { showModal } from "./reducers/modal";
import UCSelect from "./theme/UCSelect";

const LanguageSwitch = () => {
  const dispatch = useDispatch();
  const { language, lngs, handleLanguageChange } = useLanguage();

  const showLangModal = () => {
    dispatch(showModal({
      modalContentKey: 'lang-modal'
    }))
  }

  return (
    <>
      <UCSelect
        isSearchable={false}
        value={language}
        onChangeFn={handleLanguageChange}
        options={lngs}
        lngSwitch
        className="multilang-desktop" />
      <h3 className="mb-0">
        <span className="badge bg-blue-lt multilang-mobile py-2" onClick={showLangModal}>
          {language?.label?.substring(0,3)?.toUpperCase()}
        </span>
      </h3>
    </>
  )
}

export default LanguageSwitch;