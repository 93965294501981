import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";

const getButtonClasses = (props) => {
    const classes = ['btn'];
    const variantClass = props.outline ? `btn-outline-${props.variant}` : props.ghost ? `btn-ghost-${props.variant}` : `btn-${props.variant}`;

    const buttonStyles = {
        square: 'btn-square',
        pill: 'btn-pill',
        icon: 'btn-icon',
        active: 'active',
        disabled: 'disabled',
        social: `btn-${props.variant}`
    };

    Object.keys(buttonStyles).forEach(key => {
        if (props[key]) {
            classes.push(buttonStyles[key]);
        }
    });

    classes.push(variantClass);

    return classes.join(' ');
};

const UCButton = ({
    children,
    variant = 'primary',
    outline = false,
    ghost = false,
    square = false,
    pill = false,
    social = false,
    icon,
    disabled = false,
    active = false,
    onClick,
    className,
    loading,
    type = 'button',
    link = false,
    ...rest
}) => {
    const buttonClasses = useMemo(() => getButtonClasses({
        variant,
        outline,
        ghost,
        square,
        pill,
        social,
        icon,
        disabled,
        active
    }), [variant, outline, ghost, square, pill, social, icon, disabled, active]);

    return (
        <Button
            variant={link ? 'link' : ''}
            className={link ? `${className}` : `${buttonClasses} ${className}`}
            onClick={onClick}
            disabled={disabled}
            type={type}
            {...rest}
        >
            {
                loading ? <span className="spinner-border spinner-border-sm" role="status"></span>
                    : <>
                        {icon && <span>{icon}</span>}
                        {/* {icon && <span className={`icon icon-${variant}`}>{icon}</span>} */}
                        {children}
                    </>
            }
        </Button>
    );
};

UCButton.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
    outline: PropTypes.bool,
    ghost: PropTypes.bool,
    square: PropTypes.bool,
    pill: PropTypes.bool,
    social: PropTypes.bool,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

export default UCButton;