import { createAsyncThunk } from '@reduxjs/toolkit';
import { numberList, reserve } from "./reducer";
import AxiosInstance from "../../config/axiosInterceptors";
import { getUserProfile } from '../profile/actions';

export const getNumberList = createAsyncThunk('number/getNumberList', async (_,{ dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.get('v1/numbers');
        dispatch(numberList(response.data.rows));
        return response.data.rows;
    } catch (error) {
        dispatch(numberList(error.message));
        return rejectWithValue(error.message);
    }
});

export const reserveNumber = createAsyncThunk('number/reserveNumber', async (id,{ dispatch, rejectWithValue }) => {
    try {
        const response = await AxiosInstance.put(`v1/numbers/reserve`,id );
        dispatch(reserve(response.data));
        await dispatch(getUserProfile());
        return response.data;
    } catch (error) {
        dispatch(reserve(error.message));
        return rejectWithValue(error.message);
    }
});