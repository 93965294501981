import React, { useState, useEffect } from 'react';

const UCFormCommonError = ({ error, clearFn }) => {
  const [isolateError, setIsolateError] = useState(error);

  useEffect(() => {
    setIsolateError(error);
    return () => {
      clearFn?.();
    };
  }, []);

  return (
    <div className="alert alert-danger" role="alert">
      {isolateError}
    </div>
  );
};

export default UCFormCommonError;
