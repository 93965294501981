import React, { useState } from 'react'

import { IconEye, IconEyeOff, IconMail } from '@tabler/icons-react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from '@components/ButtonWithLoader';

const ResetPasswordForm = ({ handleResetPassword, loading }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <form
            onSubmit={handleSubmit(handleResetPassword)}
            method="post"
            autoComplete="off"
            noValidate>
            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.password && 'is-invalid'}`}
                        {...register('password', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: t("form_validations.strong_password")
                            }
                        })}
                        placeholder={t("form_fields.password.placeholder")}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                            {showPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                </div>
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.confirm_password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                        {...register('confirmPassword', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            validate: value =>
                                value === watch('password') || t("form_validations.password_mismatch")
                        })}
                        placeholder={t("form_fields.confirm_password.placeholder")}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                            {showPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                </div>
            </div>

            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    <IconMail className="me-2" />{" "}
                    {loading ? t('form_actions.resetting_pwd') : t('form_actions.reset_pwd')}
                </ButtonWithLoader>
            </div>
        </form >
    )
}

export default ResetPasswordForm