import React, { useEffect, useMemo, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getPhoneNumberOnly, getCountryCodeByCountry, getCountryList } from '@utils/utils';

import { ORGANIZATION_SETUP_STEPS, ORGANIZATION_TYPES } from '@config/constants';

import UCButton from './theme/UCButton';
import UCStepper from './theme/UCStepper'

const OrgSetupFormWizard = ({
    forms,
    onSubmit,
    hideStepperIndex,
    approvalIndex,
    activeStep,
    initFormValues,
    documentId,
    fromDashboard,
    onPendingApproval
}) => {
    const { t } = useTranslation();
    const [formActiveStep, setFormActiveStep] = useState(0);
    const [formValues, setFormValues] = useState({});
    const isFinalStep = formActiveStep >= approvalIndex - 1;

    const countryOptions = useMemo(() => getCountryList(), []);

    useEffect(() => {
        if (initFormValues) {
            setFormValues({
                ...initFormValues,
                organizationName: initFormValues?.name,
                smsEnable: initFormValues?.numbers?.isSMSEnabled,
                phoneNumber: initFormValues?.phone,
                agreeTerms: true,
                trunkId: initFormValues?.numbers?.trunkId,
                number: initFormValues?.numbers?.number,
                smsMask: initFormValues?.numbers?.mask,
                phone: getPhoneNumberOnly(initFormValues?.phone, getCountryCodeByCountry(initFormValues?.country)),
                country: countryOptions?.find((country) => country.value === initFormValues?.country),
                type: ORGANIZATION_TYPES?.find((type) => type.value === initFormValues?.type),
            });
        }
    }, [initFormValues]);

    const methods = useForm({
        shouldUnregister: true,
        mode: 'onSubmit',
        defaultValues: formValues
    });

    const {
        handleSubmit,
        formState: { isValid },
        trigger
    } = methods;

    const onSubmitForm = (data) => {
        if (isValid) {
            onSubmit(data);
        }
    }

    useEffect(() => {
        if (activeStep) {
            setFormActiveStep(activeStep);
        }
    }, [activeStep]);

    const handleBack = () => {
        setFormActiveStep((prevActiveStep) => {
            const newStep = prevActiveStep - 1;
            for (const field in formValues) {
                if (field === 'brDoc') {
                    methods.setValue(field, formValues[field], { shouldValidate: true });
                } else {
                    methods.setValue(field, formValues[field]);
                }
            }
            return newStep;
        });
    }

    const handleNext = async (data) => {
        const isStepValid = await trigger();
        if (!isStepValid) return;
        setFormValues((prevValues) => ({
            ...prevValues,
            ...data,
            ...(data.brDoc ? { brDoc: data.brDoc } : {}),
        }));
        setFormActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    useEffect(() => {
        for (const field in formValues) {
            methods.setValue(field, formValues[field]);
        }
    }, [formValues, methods.setValue]);

    const navigateViaIndex = (index) => {
        setFormActiveStep(index);
    }

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
            <FormProvider {...methods}>
                <div className="page page-center">
                    <div className={`${!fromDashboard && "py-4"} container`}>
                        {hideStepperIndex !== formActiveStep && !fromDashboard ? <div className='card mb-2'>
                            <div className="card-body">
                                <UCStepper steps={ORGANIZATION_SETUP_STEPS} activeStep={formActiveStep} className={"my-1"} />
                            </div>
                        </div> : null}
                        <div className="card">
                            <div className="card-body">
                                {
                                    !isFinalStep && !fromDashboard ?
                                        forms[formActiveStep] :
                                        React.cloneElement(forms[formActiveStep], {
                                            formValues, navigateViaIndex,
                                            fromDashboard,
                                            documentId,
                                        })
                                }
                                {isFinalStep && onPendingApproval ? ""
                                    
                                    : <div className='d-flex mt-4 justify-content-end'>
                                    <div className="d-flex mt-4 w-md-50 w-100-xs justify-content-end">
                                        {
                                            ((formActiveStep !== approvalIndex) && !fromDashboard) && (
                                                <UCButton
                                                    className="flex-grow-1 me-3"
                                                    disabled={formActiveStep === 0}
                                                    variant='secondary'
                                                    onClick={handleBack}
                                                >
                                                    {t('app_content.back')}
                                                </UCButton>
                                            )
                                        }
                                        {((formActiveStep < approvalIndex - 1) && !fromDashboard) && (
                                            <UCButton
                                                type='submit'
                                                className="flex-grow-1"
                                                onClick={handleSubmit(handleNext)}
                                            >
                                                {t('app_content.next')}
                                            </UCButton>
                                        )}
                                        {((formActiveStep === approvalIndex - 1) || fromDashboard) && (
                                            <UCButton
                                                type='submit'
                                                className={`${fromDashboard ? "w-50" : "flex-grow-1"}`}
                                            >
                                                {initFormValues ?? documentId ?? fromDashboard ? t('app_content.update') : t('app_content.submit')}
                                            </UCButton>
                                        )}
                                        {((formActiveStep === approvalIndex) &&
                                            !fromDashboard && !onPendingApproval) && (
                                                <UCButton
                                                    className="w-50"
                                                    onClick={() => setFormActiveStep(0)}
                                                >
                                                    {t('app_content.edit')}
                                                </UCButton>
                                            )}
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </FormProvider>
        </form>
    )
}

export default OrgSetupFormWizard