export default function statCard({ total, title, icon, iconClassName, answered, unanswered }) {
    return (
        <div className="card card-sm">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center" >
                    <div className="row mt-2">
                        <div className="font-weight-medium">
                            <p style={{ fontSize: '16px', color: 'dark-grey', marginBottom: '0' }}>{title}</p>
                            <p style={{ fontSize: '32px', marginBottom: '0' }}><b>{total}</b></p>
                        </div>
                    </div>
                    <div className="text-right align-self-start">
                        {icon}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md">
                        <div className="font-weight-medium" style={{ color: 'grey', fontSize:12 }}>Answered</div>
                        <div style={{ color: 'green', fontWeight: "bold" }}>{answered}</div>
                    </div>
                    <div className="col-md-auto text-right">
                        <div className="font-weight-medium" style={{ color: 'grey', fontSize:12 }}>Unanswered</div>
                        <div style={{ color: 'red', fontWeight: "bold", textAlign:'end' }}>{unanswered}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
