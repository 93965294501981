import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ButtonWithLoader from '@components/ButtonWithLoader';
import UCFormSelect from '@components/theme/UCFormSelect';
import useApi from '@hooks/useApi';
import { POLLING_STRATEGIES } from '@config/constants';

const PollingStrategyForm = ({ onSubmitEmails, queueExtensions, selectedExtensions, invitation, removedExtensions }) => {
    const { control, handleSubmit, formState: { errors }, setValue } = useForm();
    const { loading, postRequest, putRequest, getRequest, patchRequest } = useApi();
    const organization = useSelector(state => state.auth.organization);
    const userList = useSelector((state) => state.users.userList);


    const [queueName, setQueueName] = useState('');
    const [queue, setQueue] = useState('');

    const [combinedExtensions, setCombinedExtensions] = useState([]);

    console.log("extension", queueExtensions, selectedExtensions);
    const fetchQueueDetails = useCallback(() => {
        if (organization?.defaultQueueId) {
            getRequest({
                url: `v1/3cx/default-queue`,
            })
                .then(response => {
                    const queueData = response;
                    if (queueData?.PollingStrategy) {
                        const selectedStrategy = POLLING_STRATEGIES.find(strategy => strategy.value === queueData.PollingStrategy);
                        setValue('pollingStrategy', selectedStrategy);
                    }

                    if (queueData?.Name) {
                        const splitName = queueData.Name.split('_');
                        const displayQueueName = splitName.length > 1 ? splitName[1] : splitName[0];
                        setQueueName(displayQueueName);
                        setQueue(queueData);
                    }
                })
                .catch(error => {
                    console.error("Error fetching queue details:", error);
                });
        }
    }, [organization?.defaultQueueId, getRequest, setValue]);

    useEffect(() => {
        fetchQueueDetails();
    }, []);

    const onSubmit = async (data) => {
        onSubmitEmails?.();

        let existAgents = [];
        let inviteList = []

        // const invitations = invitation.map(email => ({ email, role: 'agent' }));
        console.log("userList", userList);
        invitation.forEach(invitationItem => {
            const email = typeof invitationItem === 'string' ? invitationItem : invitationItem.email;
            const foundUser = userList?.rows?.find(user => user.email === email);
            
            if (foundUser) {
                // Existing user, add to existAgents
                existAgents.push({ Number: String(foundUser.extension), SkillGroup: '1' });
            } else {
                // New user, add to inviteList
                inviteList.push({ email: email, role: "agent" });
            }
        });
        


        console.log("invitation",invitation);
        console.log("inviteList",inviteList);
        console.log("existAgents",existAgents);

        if (inviteList.length > 0) {

            const inviteResponse = await postRequest({
                url: 'v1/users/invite',
                payload: { invitations: inviteList, did: false },
            });

            console.log("Invite API response: ", inviteResponse);
        const invitedExtensions = inviteResponse ? inviteResponse.map(invited => invited.extension?.toString()):null;

        const allExtensions = [...queueExtensions, ...selectedExtensions, ...invitedExtensions];
        console.log("allExtensions", allExtensions)
        const uniqueExtensions = [...new Set(
            allExtensions
                .filter(ext => ext !== null && ext !== undefined)
                .filter(ext => !removedExtensions.includes(ext))
        )];
        console.log("uniqueExtensions", uniqueExtensions)
        setCombinedExtensions(uniqueExtensions);


        console.log("combinedExtensions", combinedExtensions)
        const agents = uniqueExtensions.map(number => ({
            Number: number,
            SkillGroup: '1'
        }));

        if (organization?.defaultQueueId) {
            putRequest({
                url: `v1/3cx/queue`,
                payload: {
                    id: organization.defaultQueueId,
                    name: 'default',
                    pollingStrategy: data.pollingStrategy.value,
                    agents: agents,
                    virtualExtension: queue.Number,
                    groupId: organization.departmentId,

                },
                notifySuccessMessage: 'Queue updated successfully',
                notifyErrorMessage: 'Failed to update queue',
            });
        } else {
            postRequest({
                url: 'v1/3cx/queue-create',
                payload: {
                    pollingStrategy: data.pollingStrategy.value,
                    name: 'default',
                    agents: agents,
                },
                notifySuccessMessage: 'Queue created successfully',
                notifyErrorMessage: 'Failed to create queue',
            });
        }
            
        }
        
    };

    return (
        <>
            {queueName && (
                <div className="mb-3">
                    <h4 className='mb-2'>Queue Name</h4>
                    <input type="text" value={queueName} readOnly className="form-control-plaintext" />
                </div>
            )}
            <h4 className='mb-2'>Polling Strategy</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row gy-2'>
                    <div className='col-md-9 col-12'>
                        <UCFormSelect
                            name={`pollingStrategy`}
                            placeholder={'Select'}
                            className={errors.pollingStrategy ? 'is-invalid' : ''}
                            options={POLLING_STRATEGIES}
                            control={control}
                            rules={{ required: 'Strategy is required' }}
                        />
                        {errors.pollingStrategy && (
                            <div className="invalid-feedback">{errors.pollingStrategy.message}</div>
                        )}
                    </div>
                    <div className="col-md-3 col-12">
                        <ButtonWithLoader
                            className="w-100"
                            loading={loading}
                            type="submit"
                        >
                            Submit
                        </ButtonWithLoader>
                    </div>
                </div>
            </form>
        </>
    );
};

export default PollingStrategyForm;
