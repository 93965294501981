import { useState } from 'react';

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonWithLoader from '@components/ButtonWithLoader';

const ChangePasswordForm = ({ handleChangePw, loading }) => {
    const { t } = useTranslation();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, clearErrors } = useForm();

    const onSubmit = (data) => {
        const { confirmPassword, agreeToTerms, ...restObj } = data;
        handleChangePw?.(restObj);
    };

    const handleShowOldPassword = () => {
        setShowOldPassword(prevShowPassword => !prevShowPassword);
    };

    const handleShowNewPassword = () => {
        setShowNewPassword(prevShowPassword => !prevShowPassword);
    };

    return (
        <form method="post"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label className="form-label">
                    {t('form_fields.old_password.label')}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showOldPassword ? "text" : "password"}
                        className={`form-control ${errors.oldPassword && 'is-invalid'}`}
                        {...register('oldPassword', {
                            required: { value: true, message: t('form_validations.required') },
                        })}
                        placeholder={t('form_fields.old_password.placeholder')}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowOldPassword}>
                            {showOldPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.oldPassword && <div className="invalid-feedback">{errors.oldPassword.message}</div>}
                </div>
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.new_password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showNewPassword ? "text" : "password"}
                        className={`form-control ${errors.newPassword && 'is-invalid'}`}
                        {...register('newPassword', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: t("form_validations.strong_password")
                            }
                        })}
                        placeholder={t("form_fields.new_password.placeholder")}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowNewPassword}>
                            {showNewPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.newPassword && <div className="invalid-feedback">{errors.newPassword.message}</div>}
                </div>
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.confirm_password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showNewPassword ? "text" : "password"}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                        {...register('confirmPassword', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            validate: value =>
                                value === watch('newPassword') || t("form_validations.password_mismatch")
                        })}
                        placeholder={t("form_fields.confirm_password.placeholder")}
                        autoComplete="off"
                    />
                    <span className="input-group-text text-center">
                        <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowNewPassword}>
                            {showNewPassword ? <IconEyeOff /> : <IconEye />}
                        </span>
                    </span>
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                </div>
            </div>

            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    {loading ? t('form_actions.change_pw_loading') : t('form_actions.change_pw')}
                </ButtonWithLoader>
            </div>
        </form>
    );
};

export default ChangePasswordForm;