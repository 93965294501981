import React from "react";

import moment from 'moment';

import PlaceholderRow from "@components/PlaceholderRow";

const AgentPerformance = ({ data }) => {
    const calculateTotalTalkTime = (outbound, inbound) => {
        const outboundDuration = moment.duration(outbound);
        const inboundDuration = moment.duration(inbound);
        const totalDuration = outboundDuration.add(inboundDuration);
        return totalDuration.format('HH:mm:ss', { trim: false });
    };

    const calTotal = (outbound, inbound) => {
        const inboundCount = inbound ? inbound : 0;
        const outboundCount = outbound ? outbound : 0;
        return inboundCount + outboundCount;
    };

    const getInitials = (name) => {
        return name
            .split(' ')
            .map(word => word.charAt(0))
            .join('')
            .toUpperCase();
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <h3>Agent Performance</h3>
                <div className="table-responsive">
                    <table className="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Agent Name & Extension</th>
                                <th className="text-center">
                                    <div>
                                        Inbound
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div>
                                        Outbound
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div>
                                        Total
                                    </div>
                                    <div className="d-flex flex-row text-center">
                                        <div className="w-100">
                                            Answered
                                        </div>
                                        <div className="w-100">
                                            Unanswered
                                        </div>
                                    </div>
                                </th>
                                <th className="text-center">Total Talk Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!data ? (
                                <PlaceholderRow/>
                            ) : (
                                data.map((agentData, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex py-1 align-items-center">
                                                <span className="avatar avatar-sm me-2 rounded-circle" 
                                                // style={{backgroundImage: `url(${agentData.image})`,}}
                                                >
                                                    {/* <span className="badge bg-success"></span> */}
                                                    {getInitials(agentData.DisplayName)}
                                                </span>
                                                <div className="flex-fill">
                                                    <div className="font-weight-medium">{agentData.DisplayName}</div>
                                                    <div className="text-secondary">
                                                            {agentData.Dn}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row text-center">
                                                <div className="w-100">
                                                    {agentData.InboundAnsweredCount ? agentData.InboundAnsweredCount : 0}
                                                </div>
                                                <div className="w-100">
                                                    {agentData.InboundUnansweredCount ? agentData.InboundUnansweredCount : 0}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row text-center">
                                                <div className="w-100">
                                                    {agentData.OutboundAnsweredCount ? agentData.OutboundAnsweredCount : 0}
                                                </div>
                                                <div className="w-100">
                                                    {agentData.OutboundUnansweredCount ? agentData.OutboundUnansweredCount : 0}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row text-center">
                                                <div className="w-100">
                                                    {calTotal(
                                                        agentData.InboundAnsweredCount,
                                                        agentData.OutboundAnsweredCount)}
                                                </div>
                                                <div className="w-100">
                                                    {calTotal(
                                                        agentData.InboundUnansweredCount,
                                                        agentData.OutboundAnsweredCount)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {calculateTotalTalkTime(
                                                agentData.OutboundAnsweredTalkingDur,
                                                agentData.OutboundUnansweredCount)}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AgentPerformance;
