import React from 'react';

const PlaceholderRow = ({ count = 5 }) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
        placeholders.push(
            <tr key={`placeholder-${i}`} className="placeholder-glow">
                <td>
                    <div className="d-flex py-1 align-items-center">
                        <span className="avatar avatar-sm me-2 rounded-circle placeholder"></span>
                        <div className="flex-fill">
                            <div className="placeholder placeholder-xs col-9"></div>
                            <div className="placeholder placeholder-xs col-7"></div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="d-flex flex-row text-center">
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                        <div className="w-100">
                            <div className="placeholder placeholder-xs col-8"></div>
                        </div>
                    </div>
                </td>
                <td className="text-center">
                    <div className="placeholder placeholder-xs col-8"></div>
                </td>
            </tr>
        );
    }
    return placeholders;
};

export default PlaceholderRow;
