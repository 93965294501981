import { createSlice } from "@reduxjs/toolkit";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    data: null,
    campaign: null,
    loading: false,
    error: null,
  },
  reducers: {
    startFetchingData: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    startCreatingCampaign: (state) => {
      state.loading = true;
      state.error = null;
    },
    createCampaignSuccess: (state, action) => {
      state.loading = false;
      state.campaign = action.payload;
    },
    createCampaignFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  startCreatingCampaign,
  createCampaignSuccess,
  createCampaignFailure,
} = campaignSlice.actions;

export default campaignSlice.reducer;
