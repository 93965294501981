import { createSlice } from "@reduxjs/toolkit";

const customFieldsSlice = createSlice({
  name: "customFields",
  initialState: {
    loading: false,
    error: null,
    customField: null,
    customFieldList: null,
    deleteResult: null,
  },
  reducers: {
    customFields: (state) => {
      state.loading = true;
      state.error = null;
    },
    getCustomFieldsSuccess: (state, action) => {
      state.loading = false;
      state.customFieldList = action.payload;
    },
    getCustomFieldsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createCustomFieldSuccess: (state, action) => {
      state.loading = false;
      state.customField = action.payload;
    },
    createCustomFieldError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCustomFieldSuccess: (state, action) => {
      state.loading = false;
      state.customField = action.payload;
    },
    updateCustomFieldError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCustomFieldSuccess: (state, action) => {
      state.loading = false;
      state.deleteResult = action.payload;
    },
    deleteCustomFieldError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  customFields,
  getCustomFieldsSuccess,
  getCustomFieldsError,
  createCustomFieldSuccess,
  createCustomFieldError,
  updateCustomFieldSuccess,
  updateCustomFieldError,
  deleteCustomFieldSuccess,
  deleteCustomFieldError
} = customFieldsSlice.actions;
export default customFieldsSlice.reducer;
