import { createSlice } from "@reduxjs/toolkit"

const toastSlice = createSlice({
    name: "toast",
    initialState: {
        message: null,
        autoClose: false,
        type: 'error' || 'success' || 'info' || 'warning',
    },
    reducers: {
        showToast: (state, payload) => {
            return {
                ...state,
                message: payload?.payload?.message,
                autoClose: payload?.payload?.autoClose,
                type: payload?.payload?.type,
            }
        },
        hideToast: (state) => {
            return {
                ...state,
                message: null,
                autoClose: false,
                type: 'error',
            }
        },
    },
});

export const {
    showToast,
    hideToast,
} = toastSlice.actions;
export default toastSlice.reducer;