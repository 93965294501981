import React from 'react'

import { useSelector } from 'react-redux';

const AuthorizedSections = ({ children, grantedMinimumLevels }) => {
    const profile = useSelector((state) => state.profile.profile);
    const role = profile?.role || 'agent';
    const hasPermission = grantedMinimumLevels.includes(role);
    return (
        <>
            {
                hasPermission ? children : null
            }
        </>
    )
}

export default AuthorizedSections