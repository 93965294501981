import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IconFileImport, IconFile } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import CsvWorker from "../views/CsvWorker";
import ButtonWithLoader from "../../../components/ButtonWithLoader";


import {
  contactMap,
  contactMapping,
  contactMappingSuccess,
  inProgress,
  progressComplete,
} from "../reducer";
import { bulkUpload } from "../actions";

const ImportDropzone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentPage = 1, pageSize = 20 } = location.state || {};
  const [csvWorker, setCsvWorker] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvHeaderMappings, setCsvHeaderMappings] = useState({});
  const [failedBatches, setFailedBatches] = useState([]);

  useEffect(() => {
    dispatch(contactMap());
    dispatch(progressComplete());
  }, [dispatch]);

  useEffect(() => {
    const worker = new Worker(URL.createObjectURL(new Blob([CsvWorker])));
    setCsvWorker(worker);

    return () => {
      worker.terminate();
    };
  }, []);

  const mapping = useSelector((state) => state.contacts.mapping);
  const customFieldList = useSelector(
    (state) => state.customFields.customFieldList
  );
  const progress = useSelector((state) => state.contacts.progress);
  const filteredCustomFieldList = customFieldList.rows.filter(
    (customField) => customField.contactFormView === true
  );

  useEffect(() => {
    if (csvWorker) {
      csvWorker.onmessage = function (e) {
        const filteredData = e.data;
        setCsvData(filteredData);
      };
    }
  }, [csvWorker]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      setSelectedFile(file.name);
      setCsvData(null);
      setCsvHeaderMappings({});
      dispatch(contactMap());

      if (csvWorker) {
        const filteredData = await CsvWorker(file);
        setCsvData(filteredData);
      }
    },
    [csvWorker, dispatch]
  );

  const handleCsvHeaderChange = (customFieldName, csvHeader) => {
    setCsvHeaderMappings((prevMappings) => ({
      ...prevMappings,
      [customFieldName]: csvHeader,
    }));
  };

  const generateContacts = async () => {
    if (!csvData || csvData.length === 0) {
      toast.error("CSV data is empty.");
      return;
    }

    if (!csvHeaderMappings["name"] || !csvHeaderMappings["phone"]) {
      toast.error("Name and Phone must be mapped.");
      return;
    }

    dispatch(contactMapping());
    const batchSize = 1000; //define bacth size
    let start = 0;

    const processNextBatch = async () => {
      const end = Math.min(start + batchSize, csvData.length);
      const batch = csvData.slice(start, end);

      const batchPromises = batch.map(async (csvRow) => {
        const contact = {
          // Initialize contact object with name and phone
          name: csvRow[csvHeaderMappings["name"]],
          phone: csvRow[csvHeaderMappings["phone"]],
        };
        let isValidContactNumber = true;

        filteredCustomFieldList.forEach((customField) => {
          const csvHeader = csvHeaderMappings[customField.name];

          if (csvHeader) {
            const fieldValue = csvRow[csvHeader];

            if (customField.name === "phone" && !/^\d+$/.test(fieldValue)) {
              isValidContactNumber = false;
            }

            contact[customField.name] = fieldValue;
          } else {
            contact[customField.name] = "";
          }
        });

        if (isValidContactNumber) {
          return contact;
        } else {
          return null; // Skip invalid contacts
        }
      });

      const batchResults = await Promise.all(batchPromises);
      const validContacts = batchResults.filter((result) => result !== null);

      if (validContacts.length > 0) {
        //progress
        dispatch(inProgress((start / csvData.length) * 100));
        //retry failed batches
        if (failedBatches.length > 0) {
          start = failedBatches.pop();
          setTimeout(processNextBatch, 0);
          return;
        } else {
          start = end;
        }
        // bulkUpload current batch
        const bulkUploadResult = await dispatch(
          bulkUpload({ contacts: validContacts, currentPage, pageSize })
        );

        if (bulkUploadResult.payload && bulkUploadResult.payload.success) {
          // toast.success(`Batch ${start / batchSize + 1} Import Successful!`);
        } else {
          toast.error(bulkUploadResult.payload);
          setFailedBatches((prev) => [...prev, start]);
        }
      }

      if (end < csvData.length) {
        start = end;
        setTimeout(processNextBatch, 0);
      } else {
        dispatch(contactMappingSuccess());
        dispatch(inProgress(100));

        setTimeout(() => {
          dispatch(progressComplete());
        }, 2000);
        toast.success("Contact Import Successful!");
        setCsvHeaderMappings({});
        setSelectedFile(null);
        navigate("/crm");
      }
    };

    await processNextBatch();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const dropzoneStyle = {
    border: "3px dashed #cccccc",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  const paragraphStyle = {
    margin: 0,
  };

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />

        {selectedFile ? (
          <p style={paragraphStyle}>
            <IconFile />
            <strong> {selectedFile}</strong>
          </p>
        ) : (
          <p style={paragraphStyle}>
            <IconFileImport />
            <strong> Select or drop CSV here</strong>
          </p>
        )}
      </div>
      {csvData && (
        <div>
          <div className="table-responsive mb-3">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>Custom Field</th>
                  <th>CSV Header</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    <select
                      disabled={mapping}
                      className="form-select"
                      value={csvHeaderMappings["name"] || ""}
                      onChange={(e) =>
                        handleCsvHeaderChange("name", e.target.value)
                      }
                    >
                      <option value="">Select CSV Header</option>
                      {csvData &&
                        csvData[0] &&
                        Object.keys(csvData[0]).map((csvHeader) => (
                          <option key={csvHeader} value={csvHeader}>
                            {csvHeader}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    <select
                      disabled={mapping}
                      className="form-select"
                      value={csvHeaderMappings["phone"] || ""}
                      onChange={(e) =>
                        handleCsvHeaderChange("phone", e.target.value)
                      }
                    >
                      <option value="">Select CSV Header</option>
                      {csvData &&
                        csvData[0] &&
                        Object.keys(csvData[0]).map((csvHeader) => (
                          <option key={csvHeader} value={csvHeader}>
                            {csvHeader}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
                {filteredCustomFieldList.map((customField) => (
                  <tr key={customField.id}>
                    <td>{customField.label}</td>
                    <td>
                      <select
                        disabled={mapping}
                        className="form-select"
                        value={csvHeaderMappings[customField.name] || ""}
                        onChange={(e) =>
                          handleCsvHeaderChange(
                            customField.name,
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select CSV Header</option>
                        {csvData &&
                          csvData[0] &&
                          Object.keys(csvData[0]).map((csvHeader) => (
                            <option key={csvHeader} value={csvHeader}>
                              {csvHeader}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-3">
            <ButtonWithLoader
              variant="primary"
              loading={mapping}
              onClick={generateContacts}
            >
              Import Contacts
            </ButtonWithLoader>
          </div>
          {progress > 0 && progress < 100 && (
            <div className="progress mb-3" style={{ height: "20px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${progress}%`,
                  height: "20px",
                }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                {`${progress.toFixed(2)}%`}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImportDropzone;
