
export const REDIRECT_TO_LOGIN = '/auth/login';
export const REDIRECT_TO_VERIFY = '/auth/verify-email';
export const REDIRECT_TO_SETUP = '/auth/setup-organization';
export const REDIRECT_TO_FIRST_LOOK = '/auth/first-look';
export const REDIRECT_TO_RESET_PASSWORD = '/auth/reset-password';

export const AuthPaths = {
    LOGIN: 'login',
    SIGN_UP: 'sign-up',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    VERIFY_EMAIL: 'verify-email',
    RESEND_VERIFICATION: 'resend-verification',
    SETUP_ORGANIZATION: 'setup-organization',
    ACCEPT_INVITE: 'accept-invite',
    FIRST_LOOK: 'first-look',
    UPDATE_USER: 'update-user',
    INVITE: 'invite'
};

export const AppPaths = {
    HOME: '/',
    CAMPAIGNS: 'campaigns',
    CAMPAIGN_NEW: 'campaigns/new',
    CRM: 'crm',
    INBOX: 'inbox',
    CONFIG_CHANNELS: 'config/channels',
    CONFIG_CUSTOM_FIELDS: 'config/custom-fields',
    TEMPLATES_VOICE: 'templates/voice',
    TEMPLATES_ADD_VOICE: 'templates/voice/new',
    TEMPLATES_WHATSAPP: 'templates/whatsapp',
    TEMPLATES_SMS: 'templates/sms',
    CALL_DETAIL_REPORT: 'reports/call-details',
    AGENT_PERFORMANCE_REPORT: 'reports/agent-performance',
    CALL_DISTRIBUTION_REPORT: 'reports/call-distribution',
    USERS: 'users',
    USER_LIST: 'users/list',
    USER_PROFILE: 'users/profile',
    UPDATE_USER_PROFILE: 'users/update-details',
    CHANGE_PASSWORD: 'profile/change-password',
    CONFIG_DNC: 'config/dnc',
    PROFILE: 'profile',
    CRM_IMPORT: 'crm/import',
    ROUTING_OPTIONS: 'routing',
    ORGANIZATION_UPDATE: 'organization/update',
    QUEUE_MANAGEMENT: 'queues'
};

export const SharedPaths = {
    CHANGE_PASSWORD: '/change-password',
    PROFILE: '/edit-profile',
}
