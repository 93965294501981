import CryptoJS from 'crypto-js';

// do not persist tokens and sensitive data in local storage.

export const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPTION_SECRET).toString();
}

export const decrypt = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENCRYPTION_SECRET);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}