import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  IconBrandWhatsapp,
  IconEye,
  IconMessageCircle2Filled,
  IconPhoneFilled,
  IconPlus,
} from "@tabler/icons-react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  ProgressBar,
  Row,
  Col,
} from "react-bootstrap";
import Page from "../../../layouts/Page";
import CampaignView from "./view";
import { Loader } from "../../../components/Loader";
// import { getCampaigns } from "../actions";

function Campaigns() {
  const campaignList = useSelector((state) => state.campaigns.campaigns);
  const loading = useSelector((state) => state.campaigns.loading);
  const navigate = useNavigate();
  const [selectedCampaign, selectCampaign] = useState(null);
  const page = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (page.current) {
      page.current.scrollTop = 0;
    }
  }, [selectedCampaign]);

  // useEffect(() => {
  //   dispatch(getCampaigns());
  // }, []);

  const campaigns = campaignList && campaignList.rows
    ? campaignList.rows.map((campaign) => ({
        name: campaign.campaignName,
        start_date: campaign.startDateTime,
        channel: campaign.channel,
        total_leads: "1000",
        status: "running",
      }))
    : [];

  const createButton = (
    <Button
      color="primary"
      onClick={() => {
        navigate("/campaigns/new");
      }}
    >
      <IconPlus /> Create Campaign
    </Button>
  );

  const renderChannelBadge = (channel) => {
    switch (channel) {
      case "voice":
        return (
          <Badge bg="blue" text="blue-fg">
            <IconPhoneFilled size={12} />
          </Badge>
        );
      case "sms":
        return (
          <Badge bg="lime" text="lime-fg">
            <IconMessageCircle2Filled size={12} />
          </Badge>
        );
      case "whatsapp":
        return (
          <Badge bg="green" text="green-fg">
            <IconBrandWhatsapp stroke={3} size={12} />
          </Badge>
        );
      default:
        return "";
    }
  };

  const renderStatusBadge = (status) => {
    switch (status) {
      case "running":
        return (
          <Badge className="text-capitalize" bg="success" text="success-fg">
            {status}
          </Badge>
        );
      case "paused":
        return (
          <Badge className="text-capitalize" bg="warning" text="warning-fg">
            {status}
          </Badge>
        );
      case "completed":
        return (
          <Badge className="text-capitalize" bg="dark" text="dark-fg">
            {status}
          </Badge>
        );
      case "upcoming":
        return (
          <Badge className="text-capitalize" bg="secondary" text="secondary-fg">
            {status}
          </Badge>
        );
      default:
        return "";
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (campaignList && campaignList.rows && campaignList.rows.length === 0) {
    return (
      <Page
        title={"Campaigns"}
        subtitle={"No campaigns to display"}
        buttons={[createButton]}
        onSearch={() => {}}
      ></Page>
    );
  }

  return (
    <Page
      title={"Campaigns"}
      subtitle={campaigns.length + " campaigns are running"}
      buttons={[createButton]}
      onSearch={() => {}}
    >
      {/* {JSON.stringify(campaignList, null, 2)} */}
      <Row>
        <Col
          md="6"
          className={`d-flex flex-column ${
            selectedCampaign ? "d-none d-md-block page-container-body-withscroll" : ""
          }`}
        >
          {campaigns.map((c) => (
            <Card key={c.id} className="mb-2">
              <CardBody>
                <div className="d-flex align-items-top">
                  <div>
                    <h3 className="card-title mb-0">{c.name}</h3>
                    <small className="muted">{c.start_date}</small>
                  </div>
                  <div className="ms-auto gap-2 d-flex align-items-start">
                    <Link
                      to="."
                      onClick={() => {
                        selectCampaign(c);
                      }}
                      className="icon"
                    >
                      <IconEye />
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-end">
                  <div className="d-flex flex-column">
                    <div className="d-flex gap-1">
                      {renderChannelBadge(c.channel)}
                      {renderStatusBadge(c.status)}
                    </div>
                  </div>
                  <div className="ms-auto">
                    <h1 className="mb-0">{c.total_leads}</h1>
                    <p className="text-end  mb-0">Leads</p>
                  </div>
                </div>
              </CardBody>
              {c.status !== "upcoming" ? (
                <ProgressBar>
                  <ProgressBar
                    variant="success"
                    now={Math.random() * 35}
                    key={1}
                  />
                  <ProgressBar
                    variant="warning"
                    now={Math.random() * 35}
                    key={2}
                  />
                  <ProgressBar
                    variant="danger"
                    now={Math.random() * 35}
                    key={3}
                  />
                </ProgressBar>
              ) : (
                <ProgressBar />
              )}
            </Card>
          ))}
        </Col>
        {selectedCampaign ? (
          <Col className="page-container-body-withscroll" md="6">
            <CampaignView
              onClose={() => selectCampaign(null)}
              campaign={selectedCampaign}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Page>
  );
}

export default Campaigns;
