import React, { Suspense } from "react";

import { createRoot } from "react-dom/client";

import { PersistGate } from "redux-persist/integration/react";

import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import UCLoader from "@components/theme/UCLoader";
import UCModal from "@components/theme/UCModal";
import UCToastMessage from "@components/theme/UCToastMessage";

import ConfirmationModal from "@modules/modals/confirmationModel";

import "@styles/styles.scss";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { ThemeProvider } from "@context/ThemeContext";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
      <ThemeProvider>
        <PrimeReactProvider>
          <Suspense fallback="...is loading">
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </PrimeReactProvider>
        <UCModal />
        <UCToastMessage />
        <ConfirmationModal />
        <UCLoader />
      </ThemeProvider>
    {/* </PersistGate> */}
  </Provider>
);

reportWebVitals(console.log);
