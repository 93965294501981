import { useEffect, useState } from "react";

import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import Page from "@layouts/Page";

import { endLoader, startLoader } from "@components/reducers/loading";

import { getOrgProfile } from "@modules/auth/actions";
import { getAgentPerformanceData, getCallDIstributionData } from "@modules/reports/actions";

import AgentStats from "./widgets/AgentStats";
import DailyGraph from "./widgets/DailyGraph";
import DailyStats from "./widgets/DailyStats";
import ProfileWithStatus from "./widgets/OngoingCampaigns";

function Dashboard() {
  const [profileLoaded, setProfileLoaded] = useState(false);
  const groupNumber = useSelector(state => state.auth.organization?.departmentNumber);
  const periodFrom = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const periodTo = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const callArea = 0;
  const date = moment().startOf('day').format('MMMM DD, YYYY');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialData = async () => {
      dispatch(startLoader());
      try {
        await dispatch(getOrgProfile());
        setProfileLoaded(true); 
      } finally {
        dispatch(endLoader());
      }
    };

    fetchInitialData();
  }, [dispatch]);

  useEffect(() => {
    if (!profileLoaded || !groupNumber) return;

    const fetchAgentPerformanceData = () => {
      dispatch(getAgentPerformanceData({
        groupNumber,
        periodFrom,
        periodTo,
        callArea
      }));
    };

    const fetchCallDistributionData = () => {
      dispatch(getCallDIstributionData({
        date,
        intervalType: "h"
      }));
    };

    fetchAgentPerformanceData();
    fetchCallDistributionData();

    // Set interval 5 seconds without loader
    const intervalId1 = setInterval(fetchAgentPerformanceData, 5000);
    const intervalId2 = setInterval(fetchCallDistributionData, 5000);

    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    };
  }, [dispatch, profileLoaded, groupNumber, periodFrom, periodTo, callArea, date]);

  const agentreportdata = useSelector(state => state.reports.agentreportdata);
  const distributionreportdata = useSelector(state => state.reports.distributionreportdata);

  return (
    <Page className="dashboard" title={"Dashboard"}>
      <div style={{ width: "100%" }}>
        <div style={{ marginTop: "1rem" }}> {/* Add margin bottom here */}
          <DailyStats data={distributionreportdata?.data} />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AgentStats data={agentreportdata?.value} />
        </div>
        <div style={{ marginTop: "1rem" }}>
          <DailyGraph data={distributionreportdata?.data} />
        </div>
      </div>
    </Page>
  );
}

export default Dashboard;
