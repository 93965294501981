import React, { useState } from 'react';

import { IconPlus, IconX } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';

import UCButton from '@components/theme/UCButton';

import useApi from '@hooks/useApi';

const InviteAgentFormComponent = ({ onSubmitTriggerFn, onFinish }) => {

    const { handleSubmit, register, formState: { errors }, setValue, watch } = useForm()
    const [emailList, setEmailList] = useState([]);
    const { postRequest } = useApi();

    const onSubmit = () => {
        const email = watch('email');

        let invitations = emailList;
    
        if (email && !errors.email && !emailList.includes(email)) {
            invitations = [...emailList, email];
        }
    
        const finalInvitations = invitations.map(email => ({ email, role: 'agent' }));
    
        postRequest({
            url: 'v1/users/invite',
            payload: { invitations: finalInvitations, did: false },
            notifySuccessMessage: 'Invitations sent successfully',
            notifyErrorMessage: 'Invitations sending failed'
        }).finally(() => {
            onSubmitTriggerFn?.();
        });
        onFinish();
        setValue('email', '');
    };

    const addEmail = () => {
        const email = watch('email');
        if (email && !errors.email) {
            setEmailList([...emailList, email]);
            setValue('email', '');
        }
    }

    const removeEmail = (index) => {
        setEmailList(emailList.filter((_, i) => i !== index));
    }

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gy-2">
                        <div className="col-9">
                            <input
                                type="text"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                placeholder="Enter Email"
                                name={`email`}
                                {...register(`email`, {
                                    required: emailList.length === 0,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email.message}</div>
                            )}
                        </div>
                        <div className="col-3">
                            <UCButton outline className="w-100" onClick={addEmail}>
                                <IconPlus size={20} />
                            </UCButton>
                        </div>
                    </div>
                    <div className="mt-3">
                        {emailList.map((email, index) => (
                            <div className="row gy-2 mb-2" key={index}>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className={'form-control'}
                                        disabled
                                        value={email} />
                                </div>
                                <div className="col-3">
                                    <UCButton outline variant='danger' className="w-100" onClick={() => removeEmail(index)}>
                                        <IconX size={20} />
                                    </UCButton>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-3">
                        <div className='row'>
                            {onFinish ? <div className="col-6">
                                <UCButton variant='secondary' outline className="w-100" onClick={onFinish}>
                                    Cancel
                                </UCButton>
                            </div> : null}
                            <div className={onFinish ? "col-6" : "col-12"}>
                                <UCButton type='submit' className="w-100">
                                    Invite
                                </UCButton>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default InviteAgentFormComponent