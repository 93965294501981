import React, { useCallback, useEffect, useState, useContext } from 'react'


import { IconPlus, IconX } from '@tabler/icons-react';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import ButtonWithLoader from '@components/ButtonWithLoader';
import UCButton from '@components/theme/UCButton';
import UCFormSelect from '@components/theme/UCFormSelect';

import useApi from '@hooks/useApi';
import QueueUserList from '@modules/queues/views/QueueUserList';
import { POLLING_STRATEGIES } from '@config/constants';

import { getuserList } from '@modules/users/actions'
import {getQueueList} from '@modules/queues/action';
import { ThemeContext } from '@context/ThemeContext';

const CreateForm = ({  onQueueCreationSuccess, queue  }) => {
    console.log("selected queue",queue);

    const { register, formState: { errors }, setValue, watch, control, reset  } = useForm();
    const [formattedUserList, setFormattedUserList] = useState([]);
    const { isDarkMode } = useContext(ThemeContext);
    const userList = useSelector((state) => state.users.userList);
    const { loading, postRequest,putRequest,getRequest } = useApi();
    const [emailList, setEmailList] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getuserList(1))
    }, [])

    useEffect(() => {
        if (userList && queue) {
            const queueAgentExtensions = queue.Agents.map(agent => agent.Number.toString());
            // setQueueExtensions(queueAgentExtensions);

            const filteredList = userList?.rows?.filter(user => {
                const isNotInQueue = !queueAgentExtensions.includes(user.extension?.toString());
                console.log(`User ${user.extension} is ${isNotInQueue ? 'not' : ''} in the queue`);
                return user.role !== 'owner' && isNotInQueue;
            }).map(user => ({
                value: user.email,
                label: `${user.email} (${user.extension})`,
                extra: user.role,
                firstName: user.firstName,
                lastName: user.lastName,
                extension: user.extension
            }));

            console.log("Filtered user list:", filteredList);
            setFormattedUserList(filteredList);
        }
        else{
            const list = userList?.rows?.reduce((acc, cur) => {
                if (cur.role !== 'owner') {
                    acc.push({ value: cur.email, label: `${cur.email} (${cur.extension})`, extra: cur.role});
                }
                return acc;
            }, []);
            setFormattedUserList(list);
        }
    }, [userList, queue]);


    const addEmail = () => {
        const email = watch('email');
        if (email && !errors.email) {
            const emailValue = email?.value ?? email;
            setEmailList(prev => [...prev, emailValue]);
            setFormattedUserList(prevList => prevList.filter(item => item.value !== emailValue));
            setValue('email', '');
        }
    };

    const removeEmail = (index) => {
        const removedEmail = emailList[index];
        setEmailList(emailList.filter((_, i) => i !== index));
        setFormattedUserList(prevList => [...prevList, { value: removedEmail, label: removedEmail }]);
    };

    const createNewEmail = (email) => {
        setEmailList(prev => [...prev, email]);
    }

    const renderUserSelectField = useCallback(() => {
        return (
            <UCFormSelect
                onCreatableFn={createNewEmail}
                options={formattedUserList}
                control={control}
                placeholder="Enter Email"
                isSearchable={true}
                name={`email`}
                onChangeAdditionalFn={(selectedOption) => {
                    const emailValue = selectedOption?.value ?? selectedOption;
                    if (emailValue) {
                       
                        setEmailList((prev) => [...prev, emailValue]);
                       
                        setFormattedUserList((prevList) =>
                            prevList.filter((item) => item.value !== emailValue)
                        );
                    setValue('email', null);
                    }
                }}
                {...register(`email`, {
                    required: 'Email is required',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                    },
                })}
            />
        );
    }, [formattedUserList, control, setEmailList]);
 

    useEffect(() => {
        const fetchQueueDetails = async (queue) => {
            try {
                // const payload = {data:{id : queueId}};
                // const response = await getRequest({
                //     url: `v1/3cx/queue-by-id?id=${queueId}`, 
                //     notifyErrorMessage: 'Failed to fetch queue details',
                // });
                
                if (queue) {
                    setValue('name', queue.Name.split('_').slice(1).join('_')); 
                    setValue('pollingStrategy', { value: queue.PollingStrategy, label: queue.PollingStrategy });
                    setValue('agents',  queue.Agents);

                }
            } catch (error) {
                console.error('Error fetching queue details:', error);
            }
        };
    
        if (queue) {
            fetchQueueDetails(queue);
        }
    }, [queue, setValue]);
    
    // const handleRemoveUserExtension = (extension) => {
    //     setRemovedExtensions(prev => {
    //         const updatedExtensions = [...prev, extension];
    //         console.log("Updated removed extensions in parent:", updatedExtensions);
    //         return updatedExtensions;
    //     });
    // };


    const onSubmit = async () => {
        const name = watch('name');
        const pollingStrategy = watch('pollingStrategy')?.value;
        let agents = [];
        let inviteList = [];

        // Process email list
        emailList.forEach(email => {
            const foundUser = userList?.rows?.find(user => user.email === email);
            if (foundUser) {
                agents.push({ Number: String(foundUser.extension), SkillGroup: '1' });
            } else {
                inviteList.push({ email: email, role: "agent" });
            }
        });

        try {
            if (inviteList.length > 0) {
                const responseData = await postRequest({
                    url: 'v1/users/invite',
                    payload: { invitations: inviteList, did: false },
                    notifyErrorMessage: 'Failed to invite users'
                });

                const successfulInvites = responseData?.filter(item => item.status === 'success');
                successfulInvites.forEach(invitedUser => {
                    if (invitedUser.extension) {
                        agents.push({ Number: invitedUser.extension, SkillGroup: '1' });
                    }
                });
            }

            const payload = { name, pollingStrategy, agents, did: false ,    ...(queue && { id: queue.Id.toString() }) };

            if (queue) {
                await putRequest({
                    url: `v1/3cx/queue`,
                    payload,
                    notifySuccessMessage: 'Queue updated successfully!',
                    notifyErrorMessage: 'Failed to update queue',
                    successCallback: () => {
                        onQueueCreationSuccess();
                        setEmailList([]);
                        setValue('name', '');
                        setValue('pollingStrategy', null);

                    }
                });
            } else {
                await postRequest({
                    url: 'v1/3cx/queue-create',
                    payload,
                    notifySuccessMessage: 'Queue created successfully!',
                    notifyErrorMessage: 'Failed to create queue',
                    successCallback: () => {
                        setEmailList([]);
                        setValue('name', '');
                        setValue('pollingStrategy', null);
                        dispatch(getuserList(1));
                        onQueueCreationSuccess();  
                        // dispatch(getQueueList());  // Fetch updated queue list

                    }
                });
            }
        } catch (error) {
            console.error('Error in queue operation:', error);
        }

    }

    console.log("Queue ID being passed: ", queue?.Id);

    return (
        <div>
            <div className="mb-3">
                <label className="form-label">
                    Team Name
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.ivrName && 'is-invalid'}`}
                    {...register('name', { required: true })}
                    placeholder="Enter team name"
                    autoComplete="off"
                />
            </div>
            <h4 className='mb-2'>Invite team members</h4>
            <div className='d-flex align-items-center mb-2'>
                <div className='flex-grow-1'>
                    {renderUserSelectField()}
                    {errors.email && (
                        <div className="invalid-feedback">{errors.email.message}</div>
                    )}
                </div>
                {/* <div className='d-flex flex-wrap ms-3'>
                    <div className={`avatar ${isDarkMode ? 'text-light' : 'text-dark'} bg-muted-lt`}>
                        <UCButton outline icon className="w-100" >
                            <IconPlus size={18} />
                        </UCButton>
                    </div>
                </div> */}
            </div>
            {emailList.map((email, index) => (
                <div className='d-flex align-items-center mb-2 mt-2' key={index}>
                    <div className="flex-grow-1">
                        <input
                            type="text"
                            className={'form-control'}
                            disabled
                            value={email} />
                    </div>
                    <div className="d-flex flex-wrap ms-3">
                        <UCButton outline icon variant='danger' className="w-100" onClick={() => removeEmail(index)}>
                            <IconX size={18} />
                        </UCButton>
                    </div>
                </div>
            ))}


            <div>
            {queue?.Id ? <QueueUserList selectedQueueId={queue?.Id} /> : ""}

                <h4 className='mb-2 mt-4'>Polling Strategy</h4>
                <div className='row gy-2'>
                    <div className='col-md-9 col-12'>
                        <UCFormSelect
                            name={`pollingStrategy`}
                            placeholder={'Select'}
                            className={errors.pollingStrategy ? 'is-invalid' : ''}
                            options={POLLING_STRATEGIES}
                            control={control}
                            rules={{ required: 'Strategy is required' }}
                        />
                        {errors.pollingStrategy && (
                            <div className="invalid-feedback">{errors.pollingStrategy.message}</div>
                        )}
                    </div>
                    <div className="col-md-3 col-12">
                        <ButtonWithLoader
                            className="w-100"
                            loading={loading}
                            onClick={onSubmit}
                        >
                        { queue ? "Update" : "Submit"}
                        </ButtonWithLoader>
                    </div>
                </div>
            </div>
        </div>

    )
}



export default CreateForm;