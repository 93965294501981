import { useState } from 'react';

import { IconX, IconPencil } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import QRInvite from '@components/QRInvite';
import UCFormCommonError from "@components/theme/UCFormCommonError";

import { requestFailed } from '@modules/auth/reducer';

import EditUserForm from '../forms/EditProfileForm';


const ProfileView = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    //check this user / auth
    const { error, loading } = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile.profile);
    const hasApprovedLink = profile?.ProvLink;
    const location = useLocation();
    const fromDashboard = location.state?.fromDashboard ?? true;

    const [isEditing, setIsEditing] = useState(false);

    const handleClose = () => {
        navigate(-1);
    };

    const toggleEdit = () => {
        setIsEditing(prevState => !prevState);
    };

    return (
        <AuthPageRootLayout
            containerClassName={`${hasApprovedLink ? fromDashboard ? 'w-80' : 'mw-50' : 'container-tight'}`}
            title="User Profile"
            fromDashboard={fromDashboard}>
            <div className={`${hasApprovedLink && !isEditing ? 'd-flex justify-content-center align-items-center' : ''} ${isEditing ? 'row g-2' : 'd-flex justify-content-center align-items-center'}`}>
                {
                    hasApprovedLink ? <div className='col-12 col-lg-6'>
                        <div className="card card-md minh-100">
                            <div className="card-body">
                            <div className='mb-5'>
                                {!isEditing && (
                                    
                                        <IconPencil className="pointing float-end" onClick={toggleEdit} />
                                    
                                )}
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <span className="avatar avatar-xl rounded-circle" style={{
                                        backgroundImage: `url(${'https://i.pravatar.cc/150?img=4'})`,
                                    }}>
                                        <span class="badge bg-success"></span>
                                    </span>
                                    <h2 className="xxl-normal-font my-2">
                                        {`${profile?.firstName} ${profile?.lastName}`}
                                    </h2>
                                    <h4 className="mb-1 xl-normal-font text-secondary">
                                        {`Agent Extension: ${profile?.extension}`}
                                    </h4>
                                    <h4 className="mb-4 md-light-font">
                                        {`Role: ${profile?.role}`}
                                    </h4>

                                </div>
                                <div className='mt-4'>
                                <QRInvite qrLink={profile?.ProvLink} />
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
                {isEditing ? (
                    <div className={`${hasApprovedLink ? 'col-12 col-lg-6' : 'col-12'}`}>
                        <div className="card card-md">
                            <div className="card-body">
                                <h2 className="card-title mb-4">
                                    {t("form_headers.user_details")}
                                    {
                                        !fromDashboard ? <IconX
                                            className="float-end pointing"
                                            onClick={handleClose} /> : null
                                    }
                                </h2>
                                {error && <UCFormCommonError error={error}
                                    clearFn={() => dispatch(requestFailed(null))} />}
                                <EditUserForm
                                    loading={loading}
                                    initState={{
                                        ...profile,
                                        firstName: profile?.firstName,
                                        lastName: profile?.lastName,
                                        email: profile?.email,
                                        phone: profile?.phone,
                                    }}
                                    onFinish={toggleEdit}
                                    onTriggerFn={toggleEdit}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </AuthPageRootLayout>
    )
}

export default ProfileView