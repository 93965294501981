import ReactApexChart from "react-apexcharts";

export default function CampaignGraph() {
    const generateSeries = (size) => {
        const data = [];

        for (let i = 0; i < 100; i++) {
            data.push([1327359600000 + i * 60, Math.round((Math.random() * (100 - Math.abs(i - 50))) * size)])
        }

        return data;
    }

    const chart = {
        series: [
            {
                name: 'Success',
                data: generateSeries(100)
            },
            {
                name: 'Failed',
                data: generateSeries(10)
            }
        ],
        chart: {
            type: 'bar',
            height: 200,
            stacked: true,
            sparkline: {
                enabled: true
            },
            animations: {
                enabled: false
            },
            events: {
                selection: function (chart, e) {
                    console.log(new Date(e.xaxis.min))
                }
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ['#2fb344', '#d63939'],
        stroke: {
            curve: 'smooth'
        },
        fill: {
            type: "solid",
            opacity: 1
        },
        legend: {
            show: false,
        },
        xaxis: {
            labels: {
                padding: 0,
            },
            tooltip: {
                enabled: false
            },
            axisBorder: {
                show: false,
            },
            type: 'datetime',
        },
        yaxis: {
            labels: {
                padding: 4
            },
        },
    };

    return <div className="pe-2"><ReactApexChart options={chart} series={chart.series} type="bar" height={100} /></div>
}

