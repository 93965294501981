import React from 'react';

import { Modal } from 'react-bootstrap';

const TermAndConditionModal = () => {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nonne merninisti licere mihi ista probare, quae sunt a te dicta? Refert tamen, quo modo. Quod ea non occurrentia fingunt, vincunt Aristonem; Duo Reges: constructio interrete. Quae cum magnifice primo dici viderentur, considerata minus probabantur. Quamquam haec quidem praeposita recte et reiecta dicere licebit. Quamquam haec quidem praeposita recte et reiecta dicere licebit. Quae cum dixisset, finem ille.
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
};

export default TermAndConditionModal;
