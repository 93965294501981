import React, { useEffect, useState } from "react";
import { IconTrash } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDncList } from "../action"; 
import { updateDNC } from "../../contacts/actions";
import { toast } from "react-toastify";

function renderIconLink(onClickHandler, icon, className) {
  return (
    <div className="col-auto mx-1">
      <Link onClick={onClickHandler} className={`icon ${className}`}>
        {icon}
      </Link>
    </div>
  );
}

function DncList({
  handleShow,
}) {
  const dispatch = useDispatch();
  const dncList = useSelector((state) => state.dnc.dncList);
  const loading = useSelector((state) => state.dnc.loading);

  useEffect(() => {
    dispatch(getDncList());
  }, [dispatch]);

  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleDncToggle = (contact) => {
    setSelectedContact(contact);
    setShowModal(true);
  };

  const handleDncUpdate = async () => {
    try {
      const { id, dnc, ...otherProps } = selectedContact;
      const formData = { id, dnc: false, ...otherProps };
      const dncUpdateResult = await dispatch(
        updateDNC({ formData }) 
      );
      if (dncUpdateResult.payload && dncUpdateResult.payload.id) {
        toast.success(`${selectedContact.phone} removed from DNC!`);
      } else {
        toast.error(`${selectedContact.phone} remove failed!`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowModal(false);
    }
  };
  

  return (
    <div className="page page-center">
      <div className="col-12">
        <div className="card">
          <div className="table-responsive">
            {loading ? (
              <p>Loading...</p>
            ) : dncList && dncList.length ? (
              <table className="table table-vcenter card-table table-striped">
                <thead>
                  <tr>
                    <th>Contact Number</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dncList.map((contact) => (
                    <tr key={contact.id}>
                      <td>{contact.phone}</td>
                      <td>{contact.name}</td>
                      <td className="w-1">
                        <div className="d-flex align-items-center">
                          DNC
                          <span className="col-auto">
                            <label className="form-check form-check-single form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={contact.dnc} 
                                onChange={() => handleDncToggle(contact)} 
                              />
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              "No data"
            )}
          </div>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirmation</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                Are you sure you want to disable DNC for this contact?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleDncUpdate}
                >
                  Disable DNC
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DncList;
