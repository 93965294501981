import { useEffect, useState } from 'react';

import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ButtonWithLoader from '@components/ButtonWithLoader';

const SignUpForm = ({ handleSignUp, loading, termsOnPress, handleAgreedChange, agreed }) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register, handleSubmit, formState: { errors }, watch, clearErrors } = useForm();

    useEffect(() => {
        if (agreed) {
            clearErrors(['agreeToTerms']);
        }
    }, [agreed]);

    const onSubmit = (data) => {
        const { confirmPassword, agreeToTerms, ...restObj } = data;
        handleSignUp?.(restObj);
    };

    const handleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword);
    }


    return (
        <form method="post"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.first_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.firstName && 'is-invalid'}`}
                    {...register('firstName', { required: true, pattern: /^[a-zA-Z ]{2,30}$/ })}
                    placeholder={t("form_fields.first_name.placeholder")}
                    autoComplete="off"
                />
                {errors.firstName && <div className="invalid-feedback">
                    {t("form_validations.invalid_name")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.last_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.lastName && 'is-invalid'}`}
                    {...register('lastName', { required: true, pattern: /^[a-zA-Z ]{2,30}$/ })}
                    placeholder={t("form_fields.last_name.placeholder")}
                    autoComplete="off"
                />
                {errors.lastName && <div className="invalid-feedback">
                    {t("form_validations.invalid_name")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.email.label")}
                </label>
                <input
                    type="email"
                    className={`form-control ${errors.email && 'is-invalid'}`}
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    placeholder={t("form_fields.email.placeholder")}
                    autoComplete="off"
                />
                {errors.email && <div className="invalid-feedback">
                    {t("form_validations.invalid_email")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.phone.label")}
                </label>
                <input
                    type="tel"
                    className={`form-control ${errors.phone && 'is-invalid'}`}
                    {...register('phone', { required: true, pattern: /^[+]?[\d]+$/ })}
                    placeholder={t("form_fields.phone.placeholder")}
                    autoComplete="off"
                />
                {errors.phone && <div className="invalid-feedback">
                    {t("form_validations.invalid_phone")}
                </div>}
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${errors.password && 'is-invalid'}`}
                        {...register('password', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message: t("form_validations.strong_password")
                            }
                        })}
                        placeholder={t("form_fields.password.placeholder")}
                        autoComplete="off"
                    />
                        <span className="input-group-text text-center">
                            <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                                {showPassword ? <IconEyeOff /> : <IconEye />}
                            </span>
                        </span>
                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                </div>
            </div>

            <div className='mb-3'>
                <label className="form-label">
                    {t("form_fields.confirm_password.label")}
                </label>
                <div className={"input-group"}>
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                        {...register('confirmPassword', {
                            required: {
                                value: true,
                                message: t("form_validations.required")
                            },
                            validate: value =>
                                value === watch('password') || t("form_validations.password_mismatch")
                        })}
                        placeholder={t("form_fields.confirm_password.placeholder")}
                        autoComplete="off"
                    />
                        <span className="input-group-text text-center">
                            <span className="text text-center" style={{ cursor: 'pointer' }} onClick={handleShowConfirmPassword}>
                                {showConfirmPassword ? <IconEyeOff /> : <IconEye />}
                            </span>
                        </span>
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                </div>
            </div>

            <div className="mb-3">
                <label className={`form-check ${errors.agreeToTerms ? 'is-invalid' : ''}`}>
                    <input
                        type="checkbox"
                        className={`form-check-input ${errors.agreeToTerms ? 'is-invalid' : ''}`}
                        {...register('agreeToTerms', { required: true })}
                        onChange={handleAgreedChange}
                        checked={agreed}
                    />
                    <span className="form-check-label">
                        {t('form_helpers.terms_and_policies_label')}
                        <Link to="#" onClick={termsOnPress} className='ms-2'>
                            {t("form_helpers.terms_and_policies")}
                        </Link>
                    </span>
                </label>
                {errors.agreeToTerms && (
                    <div className="invalid-feedback d-block">
                        {t("form_validations.terms_and_condition")}
                    </div>
                )}
            </div>

            <div className="form-footer">
                <ButtonWithLoader
                    className="btn btn-primary w-100"
                    loading={loading}
                    type="submit"
                >
                    {loading ? t('form_actions.create_loading') : t('form_actions.create')}
                </ButtonWithLoader>
            </div>
        </form>
    );
};

export default SignUpForm;