import { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { endLoader, startLoader } from "@components/reducers/loading";

import { decrypt } from "@config/encryption";

import { acceptInvite } from "@modules/auth/actions";
import { loginSuccess } from "@modules/auth/reducer";
import { getUserProfile } from "@modules/profile/actions";

import {
  REDIRECT_TO_FIRST_LOOK,
  REDIRECT_TO_LOGIN,
  REDIRECT_TO_SETUP,
  REDIRECT_TO_VERIFY,
} from "@routes/paths";

const useAuthRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const profile = useSelector((state) => state.profile.profile);
  const [isLoading, setIsLoading] = useState(true);

  const setToken = () => {
    if (!token) {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth?.token && auth?.refreshToken) {
        const temp = {
          token: decrypt(auth.token),
          refreshToken: decrypt(auth.refreshToken),
        };
        dispatch(loginSuccess(temp));
      } else {
        navigate(REDIRECT_TO_LOGIN, { replace: true });
        dispatch(endLoader());
      }
    }
  };

  useEffect(() => {
    setToken();
  }, []);

  const handleInviteLink = useCallback(async () => {
    const verificationCode = location.search.substring(1);
    const verifyData = { verificationCode: verificationCode };
    const acceptResponse = await dispatch(acceptInvite(verifyData));

    if (acceptResponse?.payload?.token) {
      navigate("/auth/update-user", {
        state: { verifyingData: acceptResponse.payload },
      });
      dispatch(endLoader());
    } else {
      navigate(REDIRECT_TO_LOGIN);
      dispatch(endLoader());
    }
  }, []);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      dispatch(startLoader());
      dispatch(getUserProfile())
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false)
    }
  }, [token]);

  // Redirect the user to the appropriate page based on their profile
  useEffect(() => {
    if (!isLoading) {
      if (!profile) {
        navigate(REDIRECT_TO_LOGIN, { replace: true });
        dispatch(endLoader());
      } else if (!profile.isVerified) {
        navigate(REDIRECT_TO_VERIFY, { replace: true });
        dispatch(endLoader());
      } else if (
        !profile.organizationId || profile.approvalStatus !== "approved") {
        navigate(REDIRECT_TO_SETUP, { replace: true });
      }
      else if (profile.firstLook) {
        navigate(REDIRECT_TO_FIRST_LOOK, { replace: true });
        dispatch(endLoader());
      }
    }
  }, [profile, isLoading]);

  useEffect(() => {
    window.history.replaceState(null, "", location.pathname);
  }, [navigate]);

  // Check if the user is trying to accept an invite
  useEffect(() => {
    const isAcceptLink = location.pathname === "/auth/accept-invite/";
    if (isAcceptLink) {
      handleInviteLink();
    }
  }, []);

  return isLoading;
};

export default useAuthRedirect;
