import React, { useState, useEffect } from "react";

import moment from 'moment';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Page from "../../../layouts/Page";

import ButtonWithLoader from "@components/ButtonWithLoader";

import AgentPerformanceReport from './agentPerformanceReport'

import { getAgentPerformanceData } from "../actions";

export default function Reports() {

    const [data, setData] = useState([]);
    const [periodFrom, setPeriodFrom] = useState(new Date());
    const [periodTo, setPeriodTo] = useState(new Date());
    const [groupNumber, setGroupNumber] = useState(null);
    const [callArea, setCallArea] = useState(0);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.reports.loading);
    const organization = useSelector(state => state.auth.organization);

    useEffect(() => {
        if (organization?.departmentNumber) {
            setGroupNumber(organization.departmentNumber);
        }
    }, [organization]);

    const handleGetAgentPerformanceReport = async () => {
        try {
            const result = await dispatch(getAgentPerformanceData({
                groupNumber,
                periodFrom: moment(periodFrom).format('YYYY-MM-DD HH:mm:ss'),
                periodTo: moment(periodTo).format('YYYY-MM-DD HH:mm:ss'),
                callArea
            }));
            // console.log("result", result)
            setData(result?.payload?.data?.value);
        } catch (error) {
            toast.error(error.message);
        }
    };


    // // CSV data
    // const csvData = Array.isArray(data) ? data.map(agentData => ({
    //     Extension: agentData.extension,
    //     Name: agentData.name,
    //     InboundAnswered: agentData.inbound_answered,
    //     InboundUnanswered: agentData.inbound_unanswered,
    //     OutboundAnswered: agentData.outbound_answered,
    //     OutboundUnanswered: agentData.outbound_unanswered,
    //     TotalAnswered: agentData.total_answered,
    //     TotalTalkTime: agentData.total_talk_time,
    //     TotalUnanswered: agentData.total_unanswered,
    // })) : [];

    return <Page title="Agent Performance">
        <div className="row">
            <div className={"col-3"}>
                <div className="card">
                    <div className="card-body">
                        {/* generate report section */}
                        <div className="mb-3">
                            <label className="form-label">Start Date and Time</label>
                            <DatePicker
                                selected={periodFrom}
                                maxDate={new Date()}
                                showTimeSelect
                                onChange={date => setPeriodFrom(date)}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                className="form-control"
                            />

                        </div>
                        <div className="mb-3">
                            <label className="form-label">End Date and Time</label>
                            <DatePicker
                                selected={periodTo}
                                maxDate={new Date()}
                                showTimeSelect
                                onChange={date => setPeriodTo(date)}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                className="form-control"
                            />
                        </div>
                        <div className="mb-3 text-end">
                            <ButtonWithLoader
                                className="btn btn-primary"
                                loading={loading}
                                onClick={() => handleGetAgentPerformanceReport()}
                            >
                                Generate Report
                            </ButtonWithLoader>

                        </div>
                    </div>
                </div>
            </div>
            {data && data.length > 0 && (
                <div className="col-9">
                    <AgentPerformanceReport data={data} />
                </div>
            )}

        </div>
    </Page>
}