import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconTrash, IconEdit } from "@tabler/icons-react";
import { Card, CardBody, CardTitle, Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getSMSTemplate, deleteSMSTemplate } from '../action';
import { toast } from "react-toastify";


export default function SMSList({ onEdit }) {
    const [error, setError] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false); 
    const [templateToDelete, setTemplateToDelete] = useState(null); 
    const dispatch = useDispatch();
    const smsTemplates = useSelector(state => state.sms.smsTemplate); 
    const templates = smsTemplates ? smsTemplates.rows : [];

    useEffect(() => {
        dispatch(getSMSTemplate())
            .catch(error => {
                setError(error);
            });
    }, [dispatch]);

    const onSelect = (user) => {
    };

    const handleDelete = (templateId) => {
        setTemplateToDelete(templateId);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        dispatch(deleteSMSTemplate(templateToDelete))
            .then(() => {
                setShowDeleteModal(false);
                setTemplateToDelete(null);
                toast.success('Template deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting template:', error);
                setShowDeleteModal(false);
                setTemplateToDelete(null);
                toast.success('Template delete failed');
            });
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setTemplateToDelete(null);
    };

    return (
        <div>
            {error ? (
                <div>Error: {error.message}</div>
            ) : (
                <div>
                    {templates && templates.length > 0 ? (
                        templates.map((template, index) => (
                            <div key={index} className="list-group-item">
                                <Card className="mb-3">
                                    <CardBody>
                                        <div className="row align-items-center">
                                            <div className="col text-truncate" onClick={() => onSelect(template)}>
                                                <div className="text-reset d-block">{template.templateName}</div>
                                                <div className="d-block text-secondary text-truncate mt-n1">{template.content}</div>
                                            </div>
                                            <div className="col-auto">
                                                <Link onClick={() => handleDelete(template.id)} className='text-danger'>
                                                    <IconTrash />
                                                </Link>
                                            </div>
                                            <div className="col-auto">
                                                <IconEdit onClick={() => onEdit(template)} />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))
                    ) : (
                        <div>No templates available</div>
                    )}
                </div>
            )}

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this template?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
