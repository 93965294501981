import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import ComingSoon from '@components/ComingSoon';
import FirstLookTabPill from '@components/FirstLookTabPill';

import { isMobileScreen } from '@utils/utils';

import { FIRST_LOOK_TABS } from '@config/constants';

import IVRConfig from './IVRConfig';
import TeamConfig from './TeamConfig';
import UserConfigQR from './UserConfigQR';
import UserConfigTransfer from './UserConfigTransfer';

const ConfigTabContainer = ({ editable, selectedTab, onChangeRouteUI, handleSelectedTab }) => {
    const [tabSelected, setTabSelected] = useState(selectedTab);
    const profile = useSelector((state) => state.profile.profile);

    useEffect(() => {
        setTabSelected(selectedTab);
        handleSelectedTab(selectedTab);
    }, [selectedTab]);

    const getTabContent = (selectedItem) => {
        // 
        switch (selectedItem) {
            case 0:
                return (
                    editable ? <UserConfigTransfer onChangeRouteUI={onChangeRouteUI} className="mt-4" />
                        :
                        <UserConfigQR
                            profile={profile}
                            enableMobileLink={true}
                            enableWelcomeMessage={true}
                        />
                );
            case 1:
                return (
                    <>
                        <TeamConfig editable={editable} onChangeRouteUI={onChangeRouteUI} />
                    </>
                );
            case 2:
                return <>
                    {/* <ComingSoon> */}
                        <IVRConfig editable={editable} onChangeRouteUI={onChangeRouteUI}/>
                    {/* </ComingSoon>; */}
                </>
            default:
                return null;
        }
    };


    const renderTopTabBar = useMemo(() => {
        return FIRST_LOOK_TABS.map((tab, index) => {
            const collapsedContent = isMobileScreen() && tabSelected === index ? getTabContent(index) : null;
            return (
                <FirstLookTabPill
                    key={index}
                    index={index}
                    tabSelected={index === tabSelected}
                    setTabSelected={() => setTabSelected(index)}
                    title={tab.title}
                    description={tab.description}
                    collapsedContent={collapsedContent}
                />
            );
        });
    }, [tabSelected, getTabContent]);

    return (
        <div className='row g-2 mb-2'>
            {editable ? renderTopTabBar : null}
            {!isMobileScreen() ?
                <div className='col-12'>
                    <div className={editable ? 'card' : ''}>
                        <div className="card-body">
                            {getTabContent(tabSelected)}
                        </div>
                    </div>
                </div>
                : !editable && (selectedTab === 0 || selectedTab === undefined) ? <UserConfigQR
                    profile={profile}
                    enableMobileLink={true}
                    enableWelcomeMessage={true}
                /> : !editable ? <div className='col-12'>
                    <div className={editable ? 'card' : ''}>
                        {getTabContent(tabSelected)}
                    </div>
                </div> : null}
        </div>
    )
}

export default ConfigTabContainer