import React, { useState, useEffect } from 'react';
import { IconPlus, IconTrash, IconEdit } from "@tabler/icons-react";
import Page from "../../../layouts/Page";
import { Link, useNavigate } from "react-router-dom";
import { deleteIVRList, getIVRById, getIVRList } from '../actions';
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';

function VoiceView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const IVRList = useSelector(state => state.voice.IvrData);
    const [show, setShow] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [editingItemId, setEditingItemId] = useState(null);
    const [deletingItemId, setDeletingItemId] = useState(null);
    const handleClose = () => setShow(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (e) => {
        try {
            await dispatch(getIVRList());
        } catch (error) {
            console.error("failed:", error.message);
        }
    }

    const handleShow = (index) => {
        setShow(true);
        setSelectedIndex(index);
    };

    const handleEdit = async (item) => {
        setEditingItemId(item.Id);
        let editData = await dispatch(getIVRById(item.Id));
        navigate("new", { state: { editData: editData.payload } });
    }

    const handleDelete = async () => {
        setShow(false);
        setDeletingItemId(selectedIndex);
        await dispatch(deleteIVRList(selectedIndex));
    };




    const addButton = (
        <Link to="new">
            <button className="btn btn-primary d-none d-sm-inline-block">
                <IconPlus /> Add Digital Receptionist
            </button>
        </Link>
    );


    const deleteModel = (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation required</Modal.Title>
                </Modal.Header>
                <Modal.Body>Confirm deletion of digital receptionist?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleDelete}>
                        OK
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );

    return (
        <Page title={"Call Handling"} buttons={addButton}>

            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Extension</th>
                            <th>Department</th>
                            <th>DIDs</th>
                            <th width="15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(IVRList) && IVRList.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Name}</td>
                                <td>{item.Number}</td>
                                <td>{item.IVRType}</td>
                                <td>-</td>
                                <td>
                                    <Button className="text-primary" variant="link" onClick={() => handleEdit(item)} disabled={editingItemId === item.Id}>
                                        {editingItemId === item.Id ?
                                            <Spinner animation="border" variant="primary" size="m" />
                                            : <IconEdit />}
                                    </Button>
                                    <Button className="text-danger" variant="link" onClick={() => handleShow(item.Id)} disabled={deletingItemId === item.Id}>
                                    {deletingItemId === item.Id ?
                                            <Spinner animation="border" variant="danger" size="m" />
                                            : <IconTrash />}
                                    </Button>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {deleteModel}
        </Page>
    )
}

export default VoiceView;