import React, { useState, useContext, useEffect } from 'react'

import { IconUpload } from "@tabler/icons-react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import ButtonWithLoader from '@components/ButtonWithLoader';
import { showToast } from "@components/reducers/toast";
import UCSelect from '@components/theme/UCSelect';

import AxiosInstance from '@config/axiosInterceptors';


import { ThemeContext } from '@context/ThemeContext';

const IVRForm = ({
    handleDataSubmit,
    loading,
    optionElement,
    ivrData,
    editable
}) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isDarkMode } = useContext(ThemeContext);

    const [previousIVRs, setPreviousIVRs] = useState([]);
    const [ivrsLoaded, setIvrsLoaded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedIVR, setSelectedIVR] = useState(null);
    const profile = useSelector((state) => state.profile.profile);
    console.log("profile",profile);


    useEffect(() => {
        if (ivrData) {
            setValue("ivrName", ivrData.Name);
            setSelectedIVR(ivrData.PromptFilename);
        }
    }, [ivrData, setValue]);


    //profile.code+"_"+
    useEffect(() => {
        if (!ivrsLoaded) {
            fetchIVRs();
        }
    }, [ivrsLoaded]);

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                setIvrsLoaded(false);
                const response = await AxiosInstance.post(`v1/prompts`, formData);
                if (response.status === 200) {
                    setSelectedIVR(file.name);
                    fetchIVRs();
                }
            } catch (error) {
                dispatch(showToast({ message: error?.response?.data?.message, type: "error", autoClose: 4000 }));
                console.error("Failed to upload IVR:", error);
            }
        }
    };

    const fetchIVRs = async () => {
        setIsFetching(true);
        try {
            const response = await AxiosInstance.get(`/v1/3cx/prompts`);
            const ivrOptions = response?.data?.map(ivr => ({
                value: ivr.Filename,
                label: ivr.Filename
            }));
            setPreviousIVRs(ivrOptions);
            setIvrsLoaded(true);
        } catch (error) {
            console.error("Failed to fetch IVRs:", error);
        } finally {
            setIsFetching(false);
        }
    };

    const onSubmit = (data) => {
        handleDataSubmit({ ...data, ivrWelcomePrompt: selectedIVR });
    };

    const selectedOption = previousIVRs.find(option => option.value === selectedIVR) || null;

    return (
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.ivr_name.label")}
                </label>
                <input
                    type="text"
                    className={`form-control ${errors.ivrName && 'is-invalid'}`}
                    {...register('ivrName', { required: true })}
                    placeholder={t("form_fields.ivr_name.placeholder")}
                    autoComplete="off"
                    disabled={!editable}
                />
                {errors.ivrName && <div className="invalid-feedback">
                    {t("form_validations.required")}
                </div>}
            </div>

            <div className="mb-3">
                <label className="form-label">
                    {t("form_fields.ivr_welcome_prompt.label")}
                </label>
                <div className="mb-3 d-flex align-items-center">
                    <div className="flex-grow-1">
                        <UCSelect
                            value={selectedOption}
                            options={isFetching ? [{ value: '', label: 'Loading...' }] : previousIVRs}
                            placeholder={t("form_fields.ivr_welcome_prompt.placeholder")}
                            onChangeFn={editable? (selectedOption) => setSelectedIVR(selectedOption.value):null}
                            isLoading={isFetching}
                            disabled={!editable}
                        />
                    </div>
                    {editable ? <div className="ms-3 d-flex align-items-center">
                        <button
                            type="button"
                            className={`avatar ${isDarkMode ? 'text-light' : 'text-dark'} form-control`}
                            onClick={() => document.getElementById('uploadIVR').click()}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <IconUpload />
                        </button>
                        <input
                            type="file"
                            accept=".wav"
                            id="uploadIVR"
                            className="d-none"
                            onChange={handleFileUpload}
                            disabled={isFetching}
                        />
                    </div> : ""}
                </div>
            </div>

            <div className="mb-3">
                {optionElement}
            </div>
            {editable ?
                <ButtonWithLoader
                    className="w-25 float-end"
                    loading={loading}
                    type="submit"
                >
                    Submit
                </ButtonWithLoader>
                : ""}

        </form>
    );
};

export default IVRForm;

