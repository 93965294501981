import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import AuthPageRootLayout from "@layouts/AuthPageRootLayout";

import { endLoader } from "@components/reducers/loading";
import UCFormCommonError from "@components/theme/UCFormCommonError";

import usePreventBack from "@hooks/usePreventBack";

import cover from "@assets/images/login-cover.jpg";
import logo from "@assets/logo.png";

import { userLogin } from '../actions';
import LoginForm from "../forms/LoginForm";
import { requestFailed } from "../reducer";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formData, setFormData] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const { error, loading } = useSelector(state => state.auth);

  usePreventBack();

  useEffect(() => {
    dispatch(endLoader());
    const rememberedUser = localStorage.getItem('rememberedUser');
    if (rememberedUser) {
      const parsedUser = JSON.parse(rememberedUser);
      setFormData(prevData => ({ ...prevData, email: parsedUser.replace(/['"]+/g, '') }));
      setRememberMe(true);
    }
  }, [dispatch, navigate]);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleLogin = (data) => {
    dispatch(userLogin(data))?.then((response) => {
      if (response.payload && response.payload.token) {
        navigate("/");
      }
    });
  };

  return (
    <AuthPageRootLayout
      hasUniConnectCenterLogo={false}
      hasCommonWrappingLayout={false}>
      <Row className="g-0 flex-fill h-100">
        <Col sm="12" lg="6" xl="8" className="d-none d-lg-block">
          <div className="bg-cover h-100 min-vh-100" style={{ backgroundImage: "url(" + cover + ")" }}></div>
        </Col>
        <Col sm="12" lg="6" xl="4" className="d-flex flex-column justify-content-center">
          <Container className="container-tight my-5 px-5">
            <div className="text-center mb-4">
              <div className="navbar-brand navbar-brand-autodark">
                <img src={logo} height="50" alt="" className="navbar-brand-image form-leading-image" />
              </div>
            </div>
            <h2 className="h3 text-center mb-3">
              {t("form_headers.login")}
            </h2>

            {error && <UCFormCommonError error={error} clearFn={() => dispatch(requestFailed(null))} />}

            <LoginForm
              handleLogin={handleLogin}
              handleRememberMeChange={handleRememberMeChange}
              rememberMe={rememberMe}
              loading={loading}
              formData={formData} />

            <div className="text-center text-muted mt-3">
              {t("form_helpers.sign_up_link_prefix")}{" "}
              <Link to="/auth/sign-up">{t("form_helpers.sign_up_link")}</Link>
            </div>
            <div className="text-center text-muted mt-3">
            </div>
          </Container>
        </Col>
      </Row>
    </AuthPageRootLayout>
  )
}

export default Login;
