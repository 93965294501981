import React, { useCallback, useEffect, useState } from 'react';

import { IconPlus, IconX } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import UCButton from '@components/theme/UCButton';
import UCFormSelect from '@components/theme/UCFormSelect';

import useApi from '@hooks/useApi';

import { getuserList } from '@modules/users/actions';

import InvitedList from './InvitedList';
import UserList from './UserList';
import PollingStrategyForm from '../forms/PollingStrategyForm';

const TeamConfig = ({ editable, onChangeRouteUI }) => {

    const { handleSubmit, register, formState: { errors }, setValue, watch, control } = useForm();
    const [emailList, setEmailList] = useState([]);
    const { postRequest, putRequest } = useApi();
    const { data: queueData, getRequest: getQueueById } = useApi();
    const userList = useSelector((state) => state.users.userList);
    const [formattedUserList, setFormattedUserList] = useState([]);
    const dispatch = useDispatch();
    const organization = useSelector(state => state.auth.organization);
    const [queueExtensions, setQueueExtensions] = useState([]);
    const [removedExtensions, setRemovedExtensions] = useState([]);


    useEffect(() => {
        dispatch(getuserList(1));
    }, []);

    // useEffect(() => {
    //     if (organization?.defaultQueueId && !queueData) {
    //         getQueueById({ url: `v1/3cx/default-queue` });
    //     }
    // }, [organization?.defaultQueueId, getQueueById, queueData]);

    useEffect(() => {
        if (!queueData) {
            getQueueById({ url: `v1/3cx/default-queue` });
        }
    }, [queueData]);


    useEffect(() => {
        if (userList && queueData) {
            const queueAgentExtensions = queueData.Agents.map(agent => agent.Number.toString());
            setQueueExtensions(queueAgentExtensions);

            const filteredList = userList?.rows?.filter(user => {
                const isNotInQueue = !queueAgentExtensions.includes(user.extension?.toString());
                console.log(`User ${user.extension} is ${isNotInQueue ? 'not' : ''} in the queue`);
                return user.role !== 'owner' && isNotInQueue;
            }).map(user => ({
                value: user.email,
                label: `${user.email} (${user.extension})`,
                extra: user.role,
                firstName: user.firstName,
                lastName: user.lastName,
                extension: user.extension
            }));

            console.log("Filtered user list:", filteredList);
            setFormattedUserList(filteredList);
        }
    }, [userList, queueData]);


    console.log("removedExtension", removedExtensions);

    const onSubmit = () => {
        putRequest({
            url: 'v1/organizations/first-look-status',
            payload: { firstLookStatus: 1 },
        })
            .then(() => {
                return putRequest({
                    url: 'v1/organizations/routing-option',
                    payload: { routingOption: 'queue' },
                });
            })
            .then(() => {
                onChangeRouteUI?.();
            })
            .catch(error => {
                console.error("Error during API calls:", error);
            });
    };

    const addEmail = () => {
        const email = watch('email');
        if (email && !errors.email) {
            const emailValue = email?.value ?? email;
            const selectedUser = formattedUserList.find(user => user.value === emailValue);

            if (selectedUser) {
                const userWithExtension = {
                    email: emailValue,
                    extension: selectedUser.extension.toString()
                };
                setEmailList(prev => [...prev, userWithExtension]);
            } else {
                setEmailList(prev => [...prev, { email: emailValue, extension: null }]);
            }
            setValue('email', '');
        }
    };


    const handleRemoveUserExtension = (extension) => {
        setRemovedExtensions(prev => {
            const updatedExtensions = [...prev, extension];
            console.log("Updated removed extensions in parent:", updatedExtensions);
            return updatedExtensions;
        });
    };

    const createNewEmail = (email) => {
        setEmailList(prev => [...prev, email]);
    };

    const removeEmail = (index) => {
        setEmailList(emailList.filter((_, i) => i !== index));
    };

    const renderHeaderMessage = () => (
        <>
            <h4 className='mb-0'>Invite Team members</h4>
            <p className="text-secondary mb-0">
                You can invite your team members to get and answer calls
                according to the assigned numbers of your organization.
            </p>
        </>
    );
    console.log("control", control);
    const renderUserSelectField = useCallback(() => {
        return (
            <UCFormSelect
                onCreatableFn={createNewEmail} // This handles the creatable option
                options={formattedUserList}
                control={control}
                placeholder="Enter Email"
                isSearchable={true}
                formstCreateLabel={(inputValue) => `Invite "${inputValue}"`}
                name={`email`}
                onChangeAdditionalFn={addEmail} // Call AddEmail on change
                {...register(`email`, {
                    required: 'Email is required',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                    },
                })}
            />
        );
    }, [formattedUserList, control, register, addEmail]); // Make sure AddEmail is in the dependency array



    const extensionsArray = emailList.map(item => item.extension);

    return (
        <div>
            <div className='mb-4 mt-4'>
                {editable ? (
                    <div className='col-12'>
                        {renderHeaderMessage()}
                        <div className='mt-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row gy-2">
                                    <div className="col-10 col-md-11">
                                        {renderUserSelectField()}
                                        {errors.email && (
                                            <div className="invalid-feedback">{errors.email.message}</div>
                                        )}
                                    </div>
                                    {/* <div className="col-2 col-md-1">
                                        <UCButton outline icon className="w-100" onClick={addEmail}>
                                            <IconPlus size={18} />
                                        </UCButton>
                                    </div> */}
                                </div>
                                <div className="mt-3">
                                    {emailList.map((user, index) => (
                                        <div className="row gy-2 mb-2" key={index}>
                                            <div className="col-10 col-md-11">
                                                <input
                                                    type="text"
                                                    className={'form-control'}
                                                    disabled
                                                    value={user.extension ? `${user.email} (${user.extension})` : user} />
                                            </div>
                                            <div className="col-2 col-md-1">
                                                <UCButton outline icon variant='danger' className="w-100" onClick={() => removeEmail(index)}>
                                                    <IconX size={18} />
                                                </UCButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='mt-4'>
                                    <InvitedList onRemoveUserExtension={handleRemoveUserExtension} />
                                </div>
                            </form>
                        </div>
                        <div className='mt-4'> 
                            <PollingStrategyForm
                                onSubmitEmails={onSubmit}
                                queueExtensions={queueExtensions}
                                selectedExtensions={extensionsArray}
                                removedExtensions={removedExtensions}
                                invitation={emailList}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='col-12'>
                        {renderHeaderMessage()}
                        <div className='mt-4'>
                            <UserList editable={editable} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TeamConfig;