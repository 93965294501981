import { Route, Routes } from "react-router-dom";

import ForgotPassword from "@modules/auth/views/ForgotPassword";
import Login from "@modules/auth/views/Login";
import OrganizationSetup from "@modules/auth/views/OrganizationSetup";
import ResendVerificationEmail from "@modules/auth/views/ResendVerificationEmail";
import ResetPassword from "@modules/auth/views/ResetPassword";
import SignUp from "@modules/auth/views/SignUp";
import UpdateUser from "@modules/auth/views/UpdateUser";
import VerifyEmail from "@modules/auth/views/VerifyEmail";
import FirstLook from "@modules/firstlook/views";
import ChangePassword from "@modules/shared/views/ChangePassword";
import ProfileView from "@modules/shared/views/ProfileView";
import InviteIndex from "@modules/users/views/InviteRoot";

import { AuthPaths, SharedPaths } from "./paths";

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path={AuthPaths.LOGIN} element={<Login />} />
            <Route path={AuthPaths.SIGN_UP} element={<SignUp />} />
            <Route path={AuthPaths.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={AuthPaths.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={AuthPaths.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={AuthPaths.RESEND_VERIFICATION} element={<ResendVerificationEmail />} />
            <Route path={AuthPaths.SETUP_ORGANIZATION} element={<OrganizationSetup />} />
            <Route path={AuthPaths.FIRST_LOOK} element={<FirstLook />} />
            <Route path={AuthPaths.UPDATE_USER} element={<UpdateUser />} />
            <Route path={AuthPaths.INVITE} element={<InviteIndex />} />
            <Route path={SharedPaths.CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={SharedPaths.PROFILE} element={<ProfileView />} />
        </Routes>
    );
};

export default AuthRoutes;