import React, { useState, useEffect } from "react";
import Page from "../../../layouts/Page";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate  } from "react-router-dom";
import ButtonWithLoader from "../../../components/ButtonWithLoader";
import { updateUserProfile } from "../actions";
import { toast } from "react-toastify";

export default function UserProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.profile.updating);
  const [isEdit, setIsEdit] = useState(false);
  const [editedUser, setEditedUser] = useState({});

  useEffect(() => {
    setEditedUser({
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, [user]);

  const handleEditToggle = () => {
    setIsEdit(!isEdit);
    if (!isEdit) {
      setEditedUser({
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  };
  const passChangeNavigate = () => {
    navigate("change-password");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleUpdateProfile = async () => {
    const updatedUser = {
      id: user.id,
      firstName: editedUser.firstName,
      lastName: editedUser.lastName,
    };
    try {
      const updateProfileResult = await dispatch(updateUserProfile(updatedUser));
      if (updateProfileResult.payload && updateProfileResult.payload.id) {
        toast.success("Profile Updated successfully!");
      } else {
        toast.error(updateProfileResult.payload);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsEdit(false);
  };
  

  return (
    <Page title={"User Profile"}>
      <div className="container  float-start">
        <div className="card card-md">
        <div className="card-body">
        <h2 className="page-title mb-3">Personal Information</h2>
        <div className="row">
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                name="firstName"
                value={editedUser.firstName}
                disabled={!isEdit}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                name="lastName"
                value={editedUser.lastName}
                disabled={!isEdit}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="mb-5">
              <label className="form-label">Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                value={user.email}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <h2 className="page-title mb-3">Organization Details</h2>
        <div className="row">
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">Organization Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Organization Name"
                name="name"
                value={user.organization ? user.organization.name : ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">BR Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="BR Number"
                name="brNumber"
                value={user.organization ? user.organization.brNumber: ''}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                name="address"
                value={user.organization ?user.organization.address: ''}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="mb-3">
              <label className="form-label">Contact Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Contact Number"
                name="phone"
                value={user.organization ?user.organization.phone: ''}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="d-flex g-2 mb-3 justify-content-end">
            {isEdit ? (
              <>
                <button className="btn btn-secondary mx-2" onClick={handleEditToggle}>
                  Cancel
                </button>
                <ButtonWithLoader className="btn btn-primary" loading={loading } onClick={handleUpdateProfile}>
                  Save
                </ButtonWithLoader>
              </>
            ) : (
              <>
                <button className="btn btn-primary mx-2" onClick={handleEditToggle}>
                  Edit profile
                </button>
                <button className="btn btn-primary" onClick={passChangeNavigate}>
                  Update password
                </button>
              </>
            )}
          </div>
        </div>

        {/* {JSON.stringify(user, null, 2)} */}
        </div>
        </div>
      </div>
    </Page>
  );
}