import React, { useState, useEffect } from "react";

import moment from 'moment';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Page from "../../../layouts/Page";

import ButtonWithLoader from "@components/ButtonWithLoader";
import { endLoader, startLoader } from '@components/reducers/loading';
import UCSelect from "@components/theme/UCSelect";

import { RECORDING_URL } from "@config/config";

import CallDetailReport from './callDetailReport';

import { getCallDetailData, getUsers } from "../actions";
import "react-datepicker/dist/react-datepicker.css";

const ENDPOINT_TYPES = [
    { value: "0", label: "Any" },
    { value: "4", label: "User" },
    { value: "6", label: "Number contains" }
];

const CALL_TYPES = [
    { value: "0", label: "All" },
    { value: "1", label: "Only Answered" },
    { value: "2", label: "Only Unanswered" }
];

export default function Reports() {
    const [data, setData] = useState([]);
    const [periodFrom, setStartDate] = useState(new Date());
    const [periodTo, setEndDate] = useState(new Date());
    const [sourceType, setSourceType] = useState(ENDPOINT_TYPES[0].value);
    const [destinationType, setDestinationType] = useState(ENDPOINT_TYPES[0].value);
    const [callsType, setCallsType] = useState(CALL_TYPES[0].value);
    const [sourceFilter, setSourceFilter] = useState(null);
    const [destinationFilter, setDestinationFilter] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getUsers())
            .finally(() => {
                dispatch(endLoader());
            });
    }, [dispatch]);

    const loading = useSelector(state => state.reports.loading);
    const UsersList = useSelector(state => state.reports.users);

    const handleGetCallDetailReport = async () => {
        try {
            const result = await dispatch(getCallDetailData({
                sourceType,
                destinationType,
                callsType,
                sourceFilter,
                destinationFilter,
                periodFrom: moment(periodFrom).format('YYYY-MM-DD HH:mm:ss'),
                periodTo: moment(periodTo).format('YYYY-MM-DD HH:mm:ss')
            }));
            setData(result?.payload?.data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    // sourceTypes
    const handleSourceTypeChange = (selectedSourceType) => {
        setSourceType(selectedSourceType.value);
        setSourceFilter(null);
    };

    const handleSourceUserChange = (selectedSource) => {
        setSourceFilter(selectedSource.value);
    };

    const handleSourceNumberChange = (e) => {
        setSourceFilter(e.target.value);
    };

    //destinationType
    const handleDestinationTypeChange = (selectedDestinationType) => {
        setDestinationType(selectedDestinationType.value);
        setDestinationFilter(null);
    };

    const handleDestinationUserChange = (selectedDestination) => {
        setDestinationFilter(selectedDestination.value);
    };

    const handleDestinationNumberChange = (e) => {
        setDestinationFilter(e.target.value);
    };

    //calltype
    const handleCallsTypeChange = (selectedCallsType) => {
        setCallsType(selectedCallsType.value);
    };


    return (
        <Page title="Call Details Report">
            <div className="row">
                <div className={"col-3"}>
                    <div className="card">
                        <div className="card-body">
                            {/* generate report section */}
                            <div className="mb-3">
                                <label className="form-label">Start Date and Time</label>
                                <DatePicker
                                    selected={periodFrom}
                                    maxDate={new Date()}
                                    showTimeSelect
                                    onChange={date => setStartDate(date)}
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">End Date and Time</label>
                                <DatePicker
                                    selected={periodTo}
                                    maxDate={new Date()}
                                    showTimeSelect
                                    onChange={date => setEndDate(date)}
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">From</label>
                                <UCSelect
                                    options={ENDPOINT_TYPES}
                                    value={ENDPOINT_TYPES.find(option => option.value === sourceType)}
                                    onChangeFn={handleSourceTypeChange}
                                />
                            </div>
                            {sourceType === "4" && (
                                <div className="mb-3">
                                    <label className="form-label">Select User</label>
                                    <UCSelect
                                        options={UsersList.map(agent => ({
                                            value: agent.extension,
                                            label: `${agent.firstName} ${agent.lastName} (${agent.extension})`
                                        }))}
                                        value={UsersList.map(agent => ({
                                            value: agent.extension,
                                            label: `${agent.firstName} ${agent.lastName} (${agent.extension})`
                                        })).find(agent => agent.value === sourceFilter)}
                                        onChangeFn={handleSourceUserChange}
                                    />
                                </div>
                            )}
                            {sourceType === "6" && (
                                <div className="mb-3">
                                    <label className="form-label">Enter the number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sourceFilter || ""}
                                        onChange={handleSourceNumberChange}
                                    />
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">To</label>
                                <UCSelect
                                    options={ENDPOINT_TYPES}
                                    value={ENDPOINT_TYPES.find(option => option.value === destinationType)}
                                    onChangeFn={handleDestinationTypeChange}
                                />
                            </div>
                            {destinationType === "4" && (
                                <div className="mb-3">
                                    <label className="form-label">Select User</label>
                                    <UCSelect
                                        options={UsersList.map(agent => ({
                                            value: agent.extension,
                                            label: `${agent.firstName} ${agent.lastName} (${agent.extension})`
                                        }))}
                                        value={UsersList.map(agent => ({
                                            value: agent.extension,
                                            label: `${agent.firstName} ${agent.lastName} (${agent.extension})`
                                        })).find(agent => agent.value === destinationFilter)}
                                        onChangeFn={handleDestinationUserChange}
                                    />
                                </div>
                            )}
                            {destinationType === "6" && (
                                <div className="mb-3">
                                    <label className="form-label">Enter the number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={destinationFilter || ""}
                                        onChange={handleDestinationNumberChange}
                                    />
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">Call Type</label>
                                <UCSelect
                                    options={CALL_TYPES}
                                    value={CALL_TYPES.find(option => option.value === callsType)}
                                    onChangeFn={handleCallsTypeChange}
                                />
                            </div>
                            <div className="mb-3 text-end">
                                <ButtonWithLoader
                                    className="btn btn-primary"
                                    loading={loading}
                                    onClick={() => handleGetCallDetailReport()}
                                >
                                    Generate Report
                                </ButtonWithLoader>
                            </div>
                        </div>
                    </div>
                </div>
                {!loading && !data.length && (
                <div className="col-9">
                    <div className="alert alert-warning" role="alert">
                      No data found for selected period.
                    </div>
                </div>
            )}
                {data && data.length > 0 && (
                    <div className="col-9">
                        <CallDetailReport data={data} baseUrl={RECORDING_URL} />
                    </div>
                )}
            </div>
        </Page>
    );
}
