import { countries, getCountryCode, getCountryData } from "countries-list";
import { PhoneNumberUtil } from 'google-libphonenumber';

export const getPhoneNumberLengthByCountryCode = (countryCode) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
      // convert country code to region code
      const regionCode = phoneUtil.getRegionCodeForCountryCode(countryCode);
      if (!regionCode) {
          throw new Error(`Region code not found: ${countryCode}`);
      }
      // get example phone number for region
      const exampleNumber = phoneUtil.getExampleNumber(regionCode);
      if (!exampleNumber) {
          throw new Error(`No example number found: ${regionCode}`);
      }

      // get length of national number
      const nationalNumber = phoneUtil.getNationalSignificantNumber(exampleNumber);
      return nationalNumber.length;
  } catch (error) {
      console.error('Error getting phone number length:', error);
      return null; 
  }
};

export const getPhoneNumberOnly = (phoneNumber, countryCode) => {
    return phoneNumber?.replace?.(`+${countryCode}`, '');
}


export const getCountryCodeByCountry = (country) => {
    return getCountryData(getCountryCode(country))?.phone;
}

export const getCountryList = () => {
    return Object.values(countries).map((country) => ({
        value: country.name,
        label: country.name,
    }));
}

export const formatInterval = (interval, intervalType) => {
    const date = new Date(interval);
    
    if (intervalType === 'h') {
      return date.toISOString().split('T')[1].split('.')[0];
    } else if (intervalType === 'd') {
      return date.toISOString().split('T')[0];
    } else if (intervalType === 'm') {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      return `${year}-${month}`;
    } else {
      return interval;
    }
  };

export const isMobileScreen = () => window.innerWidth <= 768;

window.addEventListener('resize', () => {
  isMobileScreen();
});