import React from 'react'

import { useSelector } from "react-redux";

import logo from "@assets/logo.png";

const UCLoader = () => {
  const {loading}  = useSelector((state) => state.loading);

  if (!loading) return null;

  return (
    <div className="page page-center loading-screen">
      <div className="container container-slim py-4">
        <div className="text-center">
          <div className="mb-3">
            <div to="." className="navbar-brand navbar-brand-autodark">
              <img src={logo} height="50" alt="" className="navbar-brand-image form-leading-image" />
            </div>
          </div>
          <div className="text-secondary mb-3">Please wait...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UCLoader