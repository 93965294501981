import { useState, useEffect } from "react";
import VoicePrompt from "./prompt";
import AxiosInstance from "../../../../config/axiosInterceptors";
import { IVR_OPTIONS } from "../../../../config/constants";


export default function IVRAction(props) {

    const { data: promptList, onSelectPrompt, index, editData } = props;
    const [action, setAction] = useState(editData && editData.Input ? index + 1 : "");
    const [destinationData, setDestinationData] = useState([]);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     if (action !="") {
    //         callAPI(); // Call API when action is "6" (default action)
    //     }
    // }, []);

    const callAPI = async () => {
        try {
            let typeArr = [];

            if (action == "3") {
                typeArr.push('Extension');
            } else if (action == "4") {
                typeArr.push('RingGroup');
            } else if (action == "5") {
                typeArr.push('IVR');
            } else if (action == "6") {
                typeArr.push('Extension');
                typeArr.push('RingGroup');
            }
            setLoading(true);
            const response = await AxiosInstance.post('v1/3cx/peers', { type: typeArr });
            setDestinationData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getDestination = (action) => {
        // console.log("action", action)
        switch (action) {
            case "3":
            case "4":
            case "5":
            case "6":
                return (
                    <select className="form-select" onClick={destinationData.length === 0 ? callAPI : null} onChange={handleSelectChange}>
                        {loading ? <option value="">Loading...</option> : <option value=""></option>}
                        {Array.isArray(destinationData) && destinationData.map((item, index) => (
                            <option key={index} value={item.Id}>{item.Number + " " + item.Name}</option>
                        ))}
                    </select>
                );
            case "8":
                return <VoicePrompt data={promptList} onSelectPrompt={handleSelectPrompt} />;
            default:
                return "";
        }
    }

    const handleSelectChange = (event) => {

        const includeDestination = [1, 2, 7, 9];
        if (includeDestination.includes(Number(event.target.value))) {
            if (event.target.value !== 1) {
                const ivrOpt = IVR_OPTIONS.find(option => option.value === event.target.value);
                onSelectPrompt({
                    Type: ivrOpt.name
                }, index);
            } else {
                onSelectPrompt({
                    Type: "Nothing"
                }, index);
            }
        } else {
            let selectedValue = destinationData.filter(item => item.Id == event.target.value);
            if (action === 6) {
                selectedValue[0].Type = "VoiceMail"
            }
            onSelectPrompt(selectedValue[0], index);
        }

    };

    const handleSelectPrompt = (promptData) => {
        onSelectPrompt(promptData, index);
    };

    return <tr>
        <td>
            <td>{index}</td>
        </td>
        <td>
            <select
                onChange={(e) => {
                    setAction(e.target.value);
                    setDestinationData([]);
                    handleSelectChange(e);
                }}
                value={action} className="form-select">
                {IVR_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </td>
        <td>
            {getDestination(action)}
        </td>
    </tr>
}