import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    refreshToken: null,
    loading: false,
    error: null,
    organization: null,
    organizationData: null,
    email: null,
  },
  reducers: {
    requestStarted: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.loading = false;
    },
    clearLogin: (state) => {
      state.token = null;
      state.organization = null;
      state.loading = false;
    },
    signupSuccess: (state, action) => {
      state.email = action.payload.email;
      state.loading = false;
    },
    verifyUserSuccess: (state, action) => {
      state.userId = action.payload.usrId;
      state.token = action.payload.token;
      state.loading = false;
    },
    organizationSetupSuccess: (state, action) => {
      state.organization = action.payload;
      state.loading = false;
    },
    updateUserSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    getOrganizationDataSuccess: (state, action) => {
      state.organizationData = action.payload;
      state.loading = false;
    }
  },
});

export const {
  requestStarted,
  requestFailed,
  loginSuccess,
  clearLogin,
  signupSuccess,
  verifyUserSuccess,
  organizationSetupSuccess,
  updateUserSuccess,
  getOrganizationDataSuccess,
} = authSlice.actions;
export default authSlice.reducer;