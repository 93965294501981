import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { API_URL } from "@config/config";

import {
  sendOTP,
  sendOTPSuccess,
  sendOTPError,
  newPassword,
  newPasswordSuccess,
  newPasswordError,
  sendInviteError,
  getUserListSuccess,
  getUserListError,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordError,
  reInviteSuccess,
  requestStarted,
  requestFailed,
  deleteSuccess,

} from "./reducer";

import AxiosInstance from "../../config/axiosInterceptors";

export const resetPassword = createAsyncThunk(
  "users/reset-password",
  async (email, { dispatch, rejectWithValue }) => {
    try {
      dispatch(sendOTP());
      const response = await AxiosInstance.post(
        "v1/users/reset-password",
        email
      );
      dispatch(sendOTPSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(sendOTPError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const setNewPassword = createAsyncThunk(
  "users/new-password",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(newPassword());
      const response = await axios.post(
        `${API_URL}v1/users/new-password`,
        {
          password: data?.password
        },
        {
          headers: {
            'Authorization': 'Bearer ' + data.token
          }
        }
      );
      dispatch(newPasswordSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(newPasswordError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  "users/resendVerificationCode",
  async (resendData, { dispatch, rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post(
        "v1/users/resend-otp",
        resendData
      );
      return response.data;
    } catch (error) {
      dispatch(newPasswordError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const inviteUser = createAsyncThunk(
  "users/invite",
  async (formDataArray, { dispatch, rejectWithValue }) => {
    try {
      const response = await AxiosInstance.post("v1/users/invite", { invitations: formDataArray });
      return response;
    } catch (error) {
      dispatch(sendInviteError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

export const invite = createAsyncThunk('users/invite', async (formData, { dispatch, rejectWithValue }) => {
  try {
    const response = await AxiosInstance.post('v1/users/re-invite', formData);
    return response;
  } catch (error) {
    dispatch(sendInviteError(error.response.data.message));
    return rejectWithValue(error.response.data.message);
  }
});

export const getuserList = createAsyncThunk('user/getuserList', async (page, { dispatch, rejectWithValue }) => {
  try {
    const response = await AxiosInstance.get(`v1/users?page=${page}&pageSize=100`);
    dispatch(getUserListSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(getUserListError(error.response.data.message));
    return rejectWithValue(error.response.data.message);
  }
}
);


export const getUserById = createAsyncThunk(
  "user/getuserbyId",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`v1/users/${id}}`, id);
      dispatch(getUserListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getUserListError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const changePassword = createAsyncThunk('users/changePassword', async (passwordData, { dispatch, rejectWithValue }) => {
  try {
    dispatch(updatePassword());
    const response = await AxiosInstance.put("v1/users/change-password", passwordData);
    dispatch(updatePasswordSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(updatePasswordError(error.response.data.message));
    return rejectWithValue(error.response.data.message);
  }
});



export const changeUserRole = createAsyncThunk('user/getuserbyId', async ({ id, newRole }, { dispatch, rejectWithValue }) => {
  try {
    const response = await AxiosInstance.put(`v1/users/change-role/${id}?role=${newRole}`, id);
    return response;
  } catch (error) {
    dispatch(getUserListError(error.response.data.message));
    return rejectWithValue(error.response.data.message);
  }
});


export const updateDetails = createAsyncThunk('user/updateDetails', async ({ data, page }, { dispatch, rejectWithValue }) => {
  try {
    const response = await AxiosInstance.put(`v1/users/update-details/${data.id}`, data);
    await dispatch(getuserList(page));
    return response;
  } catch (error) {
    dispatch(getUserListError(error.message));
    return rejectWithValue(error.message);
  }
});


export const reInvite = createAsyncThunk(
  "users/reInvite",
  async (reInviteData, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.post(`v1/users/re-invite`, reInviteData);
      dispatch(reInviteSuccess(response.data));
      return { success: true, result: response.data };
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ id, page }, { dispatch, rejectWithValue }) => {
    dispatch(requestStarted());
    try {
      const response = await AxiosInstance.delete(`v1/users/${id}`);
      dispatch(deleteSuccess(response.data));
      await dispatch(getuserList(page));
      return response;
    } catch (error) {
      dispatch(requestFailed(error.message));
      return rejectWithValue(error.message);
    }
  }
);