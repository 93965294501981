import { createSlice } from "@reduxjs/toolkit"

const modalSlice = createSlice({
    name: "modal",
    initialState: {
        isVisible: false,
        modalContentKey: null,
        data: null,
    },
    reducers: {
        showModal: (state, payload) => {
            state.isVisible = true;
            state.modalContentKey = payload?.payload?.modalContentKey;
            state.data = payload?.payload?.data;
        },
        hideModal: (state) => {
            state.isVisible = false;
            state.modalContentKey = null;
            state.data = null;
        },
    },
});

export const {
    showModal,
    hideModal,
} = modalSlice.actions;
export default modalSlice.reducer;