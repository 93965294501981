import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDNCListError, getDNCListSuccess } from "./reducer";
import AxiosInstance from "../../config/axiosInterceptors";

export const getDncList = createAsyncThunk('user/dncList', async (_,{ dispatch, rejectWithValue }) => {
    try {
      const response = await AxiosInstance.get(`v1/contacts/dnc-list`);
      dispatch(getDNCListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(getDNCListError(error.response.data.message));
      return rejectWithValue(error.response.data.message);
    }
  });

  